import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import Marquee from 'react-fast-marquee';

const Clients5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);    
    return () => {
      cleanp1();
    };
  }, []);


  return (
    <div className="">
      <div className="lg:pt-16 md:pt-16 pt-12 lg:pb-8 md:pb-6 pb-4 lg:px-12 md:px-8 px-5">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
          <h2
            id="8883068081"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 text-center font-semibold text-primary cust-textColor mb-2"
          >
            {data ? data['8883068081'] : 'We are Partner with'}
          </h2>
          <p
            id="8651294436"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-center textColor-light"
            dangerouslySetInnerHTML={{
              __html: data ? data['8651294436'] : 'Lorem Ipsum',
            }}
          ></p>
        </div>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
      >
        <div id="6750773839" className="dynamicStyle">
        <Marquee loop={0} autoFill={true}>
            {data && data['6750773839']
              ? data['6750773839'].map(e => {
                  return (
                    <div className='mx-2'>
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                        className="rounded-md img-ratio lg:h-40 md:h-32 h-24"
                      />
                    </div>
                  );
                })
              : 'no-data'}
            
          </Marquee>
        
        </div>
      </div>
    </div>
  );
};

export default Clients5;
