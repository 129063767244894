import React, { useEffect, useRef, useState } from "react";
import { Image, Button, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { ArrowRight } from "react-feather";
import { handleScroll } from "../../components/fadeeffect/FadeEffect";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const CaseStudyHeroSection = ({ pageName }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  console.log(data, "DATDATA");
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  const selectedcaseStudyData = useSelector(
    (state) => state.caseStudy.SingleCaseStudy
  );

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <section className={colorMode === "light" ? "bg-secondary theme-bg" : ""}>
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div
          ref={elementRef}
          className={`fade-left lg:w-3/5 md:w-3/5 w-full lg:px-20 md:px-12 px-5 lg:py-0 md:py-8 py-6 ${
            isVisible ? "visibleleft" : ""
          } `}
        >
          <div className="flex items-center gap-4">
            {/* {selectedcaseStudyData?.industry
              ? selectedcaseStudyData?.industry.map((item) => {
                  return (
                    <p className="fsize14 md-fsize12 sm-fsize12  clr-0E1317 textColor-light">
                      {item}
                    </p>
                  );
                })
              : ""} */}
            {/* <p className="fsize16 md-fsize16 sm-fsize14 text-black textColor-light">
            {selectedcaseStudyData?.projectname}
            </p>
            <span className="block h-2 w-2 bg-cd3197 rounded-full cust-bgColor">
              {" "}
            </span>
            <p className="fsize16 md-fsize16 sm-fsize14 text-black textColor-light">
              CONNECT
            </p>
            <span className="block h-2 w-2 bg-ffc000 rounded-full cust-bgColor">
              {" "}
            </span>
            <p className="fsize16 md-fsize16 sm-fsize14 text-black textColor-light">
              MOTIVATE
            </p> */}
          </div>

          <div className=" lg:mt-4 md:mt-3 mt-2">
            <h2 className="lg:text-4xl text-end text-black theme-textColor cust-textColor md:text-2xl text-xl text-white  font-semibold">
              {selectedcaseStudyData?.projectname}
            </h2>
            <p className="text-black  theme-textColor textColor-light lg:mt-4 md:mt-3 mt-2 sm-fsize14 lg:leading-7 md:leading-7 leading-6">
              {selectedcaseStudyData?.shortdescription}
            </p>
          </div>
          <div className="lg:flex md:flex lg:gap-6 md:gap-6 gap-4 lg:pt-8 md:pt-8 pt-6">
            <div className="gradient-border">
              <Button
                borderRadius={30}
                id="0721216577"
                className={
                  colorMode === "light"
                    ? "dynamicStyle btn-circle btn-dual sm-btn-dual  clr-white sm-fsize14 "
                    : "dynamicStyle btn-circle btn-dual sm-btn-dual  clr-white sm-fsize14 "
                }
              >
                <img
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/reward.png"
                  alt="reward"
                  class="reward-img object-contain"
                />
                <span onClick={() => handleClick("0721216577")}>
                  {data ? data["0721216577"] : "Lorem ipsum"}
                </span>
                <FaLongArrowAltRight className="textwhite ml-2 textColor-light sm-arrow" />
              </Button>
            </div>
            <Button
              id="7290790218"
              variant="outline"
              borderRadius={30}
              className="dynamicStyle btn-dual sm-btn-dual  sm-fsize14 text-primary border-primary lg:mt-0 md:mt-0 mt-3"
            >
              <span onClick={() => handleClick("7290790218")}>
                {data ? data["7290790218"] : "Lorem ipsum"}
              </span>
            </Button>
          </div>
        </div>
        <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
          <div className=" w-full lg:pr-10 md:pr-10 pr-0">
            {" "}
            <Image
              src={`${process.env.REACT_APP_STORAGE_URL}${selectedcaseStudyData?.image}`}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="object-contain w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyHeroSection;
