import React, { useEffect, useRef, useState } from "react";
import { ArrowRight } from "react-feather";
import { Button, Card, Image, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const GridCard3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const navigate = useNavigate();
  const link = "https://example.com";

  const getAllPinData = useSelector((item) => item.caseStudy.PinCaseStudy);
  console.log(getAllPinData);
  let filterPinData = [];
  if (
    getAllPinData &&
    getAllPinData.result &&
    getAllPinData.result.length > 0
  ) {
    filterPinData = [
      ...new Set(getAllPinData.result.map((item) => item.casestudy[0][0])),
    ];
    console.log(filterPinData);
  }

  const handleCaseStudy = (e) => {
    const originalLink = removeSpacesFromLink(e?.projectname);
    console.log(originalLink, "linkyurl");
    navigate(`/case study?cp=${originalLink}`);
  };

  // Function to replace %20 with -
  const removeSpacesFromLink = (str) => {
    return str.replace(/\s+/g, "-");
  };

  return (
    <section className="container mx-auto lg:py-16 md:py-8 py-4 lg:px-12 md:px-8 px-5">
      <p
        id="5569353772"
        className="dynamicStyle font-semibold text-primary textColor-light"
      >
        {data ? data["5569353772"] : "Lorem Ipsum"}
      </p>
      <p
        id="1417110583"
        className="dynamicStyle inline-block fsize34 md-fsize26 sm-fsize22 font-medium lg:mb-8 md:mb-8 mb-4 clr-191E22 cust-textColor"
      >
        {data ? data["1417110583"] : "Lorem Ipsum generated"}
      </p>
      <div className="w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-12 md:gap-4 gap-4">
        {filterPinData.map((item, index) => (
          <Card
            onClick={() => handleCaseStudy(item)}
            className="cursor-pointer p-4 cust-shadow"
            key={index}
          >
            <div className="overflow-hidden">
              <Image
                className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover object-contain"
                src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt=""
              />
            </div>
            <p className="lineClamp1 mb-0 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              {item.projectname}
            </p>
            <div className="mb-0">
              <div className="lineClamp1 mb-2">
                <div className="inline-flex items-center gap-2 mr-3">
                  <span className="block h-1 w-1 bg-primary rounded-full cust-bgColor"></span>
                  <p className="fsize14 md-fsize12 sm-fsize12  clr-0E1317 textColor-light">
                    {item.roletitle1}
                  </p>
                </div>
                <div className="inline-flex items-center gap-2 mr-3">
                  <span className="block h-1 w-1 bg-primary rounded-full cust-bgColor"></span>
                  <p className="fsize14 md-fsize12 sm-fsize12  clr-0E1317 textColor-light">
                    {item.roletitle2}
                  </p>
                </div>
                <div className="inline-flex items-center gap-2 ">
                  <span className="block h-1 w-1 bg-primary rounded-full cust-bgColor"></span>
                  <p className="fsize14 md-fsize12 sm-fsize12  clr-0E1317 textColor-light">
                    {item.roletitle3}
                  </p>
                </div>
                {/* <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
                  • {item.roletitle2}
                </p>
                <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
                  • {item.roletitle3}
                </p> */}
              </div>
            </div>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-2/4 cursor-pointer">
              <p className="text-primary sm-fsize14 textColor-light">
                Customize
              </p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </Card>
        ))}
      </div>
      <div className="text-center lg:mt-12 md:mt-12 mt-6">
        <Button
          variant="outline"
          id="0391086893"
          className={
            colorMode === "light"
              ? "dynamicStyle  text-primary border-primary sm-fsize14 lg:py-6 sm-py-6 theme-btn2"
              : "dynamicStyle cust-textColor sm-fsize14 lg:py-6 sm-py-6 theme-btn2"
          }
        >
          <span onClick={() => handleClick("0391086893")}>
            {data ? data["0391086893"] : "Buy Now"}
          </span>
        </Button>
      </div>
    </section>
  );
};

export default GridCard3;
