import React from "react";
import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";

const AboutSection14 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  return (
    <div className="container m-auto lg:flex  lg:px-12 md:px-8 px-5 lg:py-28 md:py-16 py-16">
      <div className="lg:w-1/2 md:w-full">
        <h2
          id="2937376032"
          className="dynamicStyle fsize34 md-fsize28 sm-fsize22 text-primary cust-textColor font-semibold pb-2"
        >
          {data ? data["2937376032"] : "Achieve Your Goals With Us"}
        </h2>
        <p
          id="7014789812"
          className="dynamicStyle fsize16 sm-fsize14 textColor-light lg:pb-8 md:pb-6 pb-4"
        >
          {data
            ? data["7014789812"]
            : "It is a long establgned lock that a recost mall oe aistocted"}
        </p>

        <Button
          id="5460882958"
          size="lg"
          className="dynamicStyle bg-primary theme-btn cust-bgColor clr-fff sm-fsize14 buttonAnimate bdr-2px"
        >
          <a href="/elearning-course">
            {data ? data["5460882958"] : "Explore Course"}
          </a>
        </Button>
      </div>
      <div className="lg:w-1/2 md:w-full lg:ml-20 lg:pt-0 md:pt-8 pt-6">
        <div className="w-full flex lg:gap-10 md:gap-10 gap-5 w-full">
          <p
            id="6114415178"
            className="dynamicStyle w-1/5 text-white flex items-center justify-center fsize18 lg:mt-2 md:mt-2 md-fsize18 sm-fsize14 text-center custpointwh  rounded-full bg-343434"
          >
            {data ? data["6114415178"] : "1"}
          </p>
          <div className="w-4/5">
            <p
              id="3509243031"
              className="dynamicStyle  fsize30 md-fsize20 sm-fsize20 cust-textColor"
            >
              {data ? data["3509243031"] : "learn the latest skills"}
            </p>
            <p
              id="2772382643"
              className="dynamicStyle textColor-light sm-fsize14 pt-2"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["2772382643"]
                  : "Embrace innovation and stay ahead by mastering the latest",
              }}
            ></p>
          </div>
        </div>
        <div className="w-full flex lg:gap-10 md:gap-10 gap-5 lg:pt-8 md:pt-6 pt-4">
          <p
            id="8105447381"
            className="dynamicStyle text-white flex items-center justify-center fsize18 lg:mt-2 md:mt-2 md-fsize18 sm-fsize14 text-center custpointwh  rounded-full bg-343434"
          >
            {data ? data["8105447381"] : "2"}
          </p>
          <div className="">
            <p
              id="8137793069"
              className="dynamicStyle  fsize30 md-fsize20 sm-fsize20 cust-textColor"
            >
              {data ? data["8137793069"] : "Get ready or a career"}
            </p>
            <p
              id="5598414986"
              className="dynamicStyle textColor-light pt-2"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["5598414986"]
                  : "Prepare for a promising career by acquiring essential",
              }}
            ></p>
          </div>
        </div>
        <div className="w-full flex lg:gap-10 md:gap-10 gap-5 lg:pt-8 md:pt-6 pt-4">
          <p
            id="8965725835"
            className="dynamicStyle text-white flex items-center justify-center fsize18 lg:mt-2 md:mt-2 md-fsize18 sm-fsize14 text-center custpointwh  rounded-full bg-343434"
          >
            {data ? data["8965725835"] : "3"}
          </p>
          <div className="">
            <p
              id="4417932954"
              className="dynamicStyle  fsize30 md-fsize20 sm-fsize20 cust-textColor"
            >
              {data ? data["4417932954"] : "Earn a certificate"}
            </p>
            <p
              id="8434905103"
              className="dynamicStyle textColor-light sm-fsize14 pt-2"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["8434905103"]
                  : "Elevate your expertise and showcase your accomplishments",
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection14;
