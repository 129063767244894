import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard25 = ({ pageName }) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    function getImage(image) {
        if (image && image.includes('blob:')) {
          return image;
        } else {
          return process.env.REACT_APP_STORAGE_URL + image;
        }
      }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
      <div className="lg:w-10/12 md:w-10/12 w-full text-center mx-auto lg:mb-8 md:mb-8 mb-6">
        <h3 className="fsize32 sm-fsize20 font-semibold pb-2 cust-textColor">
          Lorem ipsum dolor sit amet
        </h3>
        <p className="text-gray sm-fsize14 textColor-light">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum aut
          rem voluptatum non dignissimos maxime similique modi fuga aspernatur
          soluta!
        </p>
      </div>
      <div className="">
        <Swiper
          spaceBetween={20}
          modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-nexts',
              prevEl: '.image-swiper-button-prevs',
            }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            425: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}
        >
            <div className="swiper-button image-swiper-button-prevs cursor-pointer cust-textColor">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nexts cursor-pointer cust-textColor">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
          <SwiperSlide>
            <Card 
            className={
                colorMode === 'light'
                  ? 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
                  : 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
              }
            >
              <div>
                <p className="fsize20 sm-fsize16 font-medium pb-4 cust-textColor">Family Law</p>
                <div className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 mx-auto'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24'
                  />
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card 
            className={
                colorMode === 'light'
                  ? 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
                  : 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
              }
            >
              <div>
                <p className="fsize20 sm-fsize16 font-medium pb-4 cust-textColor">Family Law</p>
                <div className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 mx-auto'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24'
                  />
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card 
            className={
                colorMode === 'light'
                  ? 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
                  : 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
              }
            >
              <div>
                <p className="fsize20 sm-fsize16 font-medium pb-4 cust-textColor">Family Law</p>
                <div className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 mx-auto'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24'
                  />
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card 
            className={
                colorMode === 'light'
                  ? 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
                  : 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
              }
            >
              <div>
                <p className="fsize20 sm-fsize16 font-medium pb-4 cust-textColor">Family Law</p>
                <div className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 mx-auto'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24'
                  />
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card 
            className={
                colorMode === 'light'
                  ? 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
                  : 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
              }
            >
              <div>
                <p className="fsize20 sm-fsize16 font-medium pb-4 cust-textColor">Family Law</p>
                <div className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 mx-auto'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24'
                  />
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card 
            className={
                colorMode === 'light'
                  ? 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
                  : 'text-center bdr lg:px-6 lg:py-16 md:px-6 md:py-16 px-4 py-12 card25 cursor-pointer theme-bg themeShadow themeShadow-insert theme-border-0'
              }
            >
              <div>
                <p className="fsize20 sm-fsize16 font-medium pb-4 cust-textColor">Family Law</p>
                <div className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 mx-auto'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24'
                  />
                </div>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default FeatureCard25;
