import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Image, useColorMode } from "@chakra-ui/react";
import { handleScroll } from "./fadeeffect/FadeEffect";

const FeatureCard16 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section
      //   className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6 bg-grad"
      className={
        colorMode === "light"
          ? "lg:px-12 md:px-8 px-4 lg:py-10 md:py-8 py-6 bg-white theme-bg"
          : "lg:px-12 md:px-8 px-4 lg:py-10 md:py-8 py-6 theme-bg"
      }
    >
      <div className="container mx-auto  lg:px-12 md:px-4 px-1 md:mt-3">
        <div className="lg:w-2/3 md:w-2/3 mx-auto text-center lg:pb-12 md:pb-12 pb-6">
          <h2
            id="4388230550"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data["4388230550"] : "Welcome to My World"}
          </h2>
          <p
            id="1127523224"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["1127523224"]
                : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
            }}
            className="dynamicStyle fsize16 sm-fsize14 text-gray textColor-light theme-textColor"
          >
            {/* Lorem ipsum dolor sit amet consectetur adipisicing. */}
          </p>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom p-2  ${isVisible ? "visiblebottom" : ""} `}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            <Card boxShadow={0}>
              <div
                className={
                  colorMode === "light"
                    ? "shadow py-10 px-4  w-full text-center relative"
                    : "py-10 px-4  w-full text-center relative"
                }
              >
                <p
                  id="3666242522"
                  className="dynamicStyle fsize24 sm-fsize16 font-semibold text-center cust-textColor text-dark pb-6 theme-textColor"
                >
                  {data ? data["3666242522"] : "Welcome to My World"}
                </p>
                <p
                  id="1484777357"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["1484777357"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
                  }}
                  className="dynamicStyle textColor-light"
                >
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
                non incidunt alias cupiditate voluptates reprehenderit */}
                </p>
              </div>
            </Card>
            <Card boxShadow={0}>
              <div
                className={
                  colorMode === "light"
                    ? "shadow py-10 px-4  w-full text-center relative"
                    : "py-10 px-4  w-full text-center relative"
                }
              >
                <p
                  id="9345680175"
                  className="dynamicStyle fsize24 sm-fsize16 font-semibold text-center cust-textColor text-dark pb-6 theme-textColor"
                >
                  {data ? data["9345680175"] : "Welcome to My World"}
                </p>
                <p
                  id="6526663743"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["6526663743"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
                  }}
                  className="dynamicStyle textColor-light"
                >
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
                non incidunt alias cupiditate voluptates reprehenderit */}
                </p>
              </div>
            </Card>
            <Card boxShadow={0}>
              <div
                className={
                  colorMode === "light"
                    ? "shadow py-10 px-4  w-full text-center relative"
                    : "py-10 px-4  w-full text-center relative"
                }
              >
                <p
                  id="9749662362"
                  className="dynamicStyle fsize24 sm-fsize16 font-semibold text-center cust-textColor text-dark pb-6 theme-textColor"
                >
                  {data ? data["9749662362"] : "Welcome to My World"}
                </p>
                <p
                  id="6750099659"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["6750099659"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
                  }}
                  className="dynamicStyle textColor-light"
                >
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
                non incidunt alias cupiditate voluptates reprehenderit */}
                </p>
              </div>
            </Card>
            <Card boxShadow={0}>
              <div
                className={
                  colorMode === "light"
                    ? "shadow py-10 px-4  w-full text-center relative"
                    : "py-10 px-4  w-full text-center relative"
                }
              >
                <p
                  id="9489821608"
                  className="dynamicStyle fsize24 sm-fsize16 font-semibold text-center cust-textColor text-dark pb-6 theme-textColor"
                >
                  {data ? data["9489821608"] : "Welcome to My World"}
                </p>
                <p
                  id="3145891950"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["3145891950"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
                  }}
                  className="dynamicStyle textColor-light"
                >
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
                non incidunt alias cupiditate voluptates reprehenderit */}
                </p>
              </div>
            </Card>
            <Card boxShadow={0}>
              <div
                className={
                  colorMode === "light"
                    ? "shadow py-10 px-4  w-full text-center relative"
                    : "py-10 px-4  w-full text-center relative"
                }
              >
                <p
                  id="1006843756"
                  className="dynamicStyle fsize24 sm-fsize16 font-semibold text-center cust-textColor text-dark pb-6 theme-textColor"
                >
                  {data ? data["1006843756"] : "Welcome to My World"}
                </p>
                <p
                  id="2807698129"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["2807698129"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
                  }}
                  className="dynamicStyle textColor-light"
                >
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
                non incidunt alias cupiditate voluptates reprehenderit */}
                </p>
              </div>
            </Card>
            <Card boxShadow={0}>
              <div
                className={
                  colorMode === "light"
                    ? "shadow py-10 px-4  w-full text-center relative"
                    : "py-10 px-4  w-full text-center relative"
                }
              >
                <p
                  id="0691188053"
                  className="dynamicStyle fsize24 sm-fsize16 font-semibold text-center cust-textColor text-dark pb-6 theme-textColor"
                >
                  {data ? data["0691188053"] : "Welcome to My World"}
                </p>
                <p
                  id="6181219647"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["6181219647"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus",
                  }}
                  className="dynamicStyle textColor-light"
                >
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
                non incidunt alias cupiditate voluptates reprehenderit */}
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard16;
