export const handleScroll = (elementRef, setIsVisible) => {
  const handleScrollEvent = () => {
    if (elementRef.current) {
      const top = elementRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisible(top < windowHeight);
    }
  };

  handleScrollEvent();

  window.addEventListener('scroll', handleScrollEvent);

  return () => {
    window.removeEventListener('scroll', handleScrollEvent);
  };
};

