import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Image,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Summary1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  let selectedStyle = useSelector(
    (state) => state.brandDetails.brandProfile.businessThemeVariation
  );
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="relative h-100vh w-full overflow-hidden">
      <div className="w-full h-100vh  dynamicStyle2" id="0559625905">
        <Image
          src={data ? getImage(data["0559625905"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="summary-banner"
          className="object-cover sm_laungeimage"
        />
      </div>
      <div className="absolute top-0 left-0 z-50 h-100vh w-full lg:flex md:flex lg:py-20 md:py-20 py-8 lg:px-20 md:px-16 px-5">
        <div className="lg:w-2/3 md-w-2/3 w-full">
          <div className="flex items-start gap-3 dynamicStyle2" id="0950266107">
            <Image
              src={data ? getImage(data["0950266107"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="summary-image"
              className="object-contain lg:w-16 md:w-12 w-8 lg:h-16 md:h-12 h-8 rounded-full lg:block md:block hidden"
            />
            <div className="">
              <div className="flex items-center gap-2 mb-2">
                <h1
                  id="3151488027"
                  className="dynamicStyle lh-28px font-semibold fsize28 md:fsize28 sm-fsize24 text-black cust-textColor"
                >
                  {data ? data["3151488027"] : "Hello"}
                </h1>
                <span
                  id="0900372054"
                  className="dynamicStyle text-primary lh-28px font-semibold fsize28 md:fsize28 sm-fsize24 cust-textColor"
                >
                  {data ? data["0900372054"] : "Royal Enfield"}
                </span>
              </div>

              <h1
                id="7974348498"
                className="dynamicStyle lh-28px font-semibold fsize28 md:fsize28 sm-fsize24 text-black lg:pr-28 md:pr-12 cust-textColor"
              >
                {data
                  ? data["7974348498"]
                  : "Lorem ipsum dolor sit amet consectetur"}
              </h1>
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 md-w-1/3 w-full lg:mt-0 md:mt-0 mt-6">
          <Card className="cust-shadow mb-6">
            <div className="lg:p-8 md:p-8 p-4">
              <h1
                id="7988667627"
                className="dynamicStyle cust-textColor fsize20 md-fsize20 sm-fsize16 font-semibold text-black"
              >
                {data ? data["7988667627"] : "Got a Questions?"}
              </h1>
              <p
                className="dynamicStyle mt-3 fsize14 sm-fsize12 textColor-light"
                id="3981067672"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["3981067672"]
                    : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
              >
                {/* Our experts are here to help you out? Pick */}
              </p>
              {/* <div className="mt-4">
                <FormControl mb={3}>
                  <FormLabel
                    id="7842622527"
                    className="fsize14 dynamicStyle cust-textColor"
                    mb={1}
                  >
                    {data ? data["7842622527"] : "Organisation name"}
                  </FormLabel>
                  <Input
                    placeholder="Enter organisation name"
                    className="fsize14"
                  />
                </FormControl>
                <FormControl mb={3}>
                  <FormLabel
                    id="9427475107"
                    className="fsize14 dynamicStyle cust-textColor"
                    mb={1}
                  >
                    {data ? data["9427475107"] : "Full Name"}
                  </FormLabel>
                  <Input placeholder="Enter full name" className="fsize14" />
                </FormControl>
                <FormControl id="7029648470" mb={3}>
                  <FormLabel
                    className="fsize14 cust-textColor dynamicStyle"
                    mb={1}
                  >
                    {data ? data["7029648470"] : "Email"}
                  </FormLabel>
                  <Input placeholder="Enter email" className="fsize14" />
                </FormControl>
                <FormControl mb={3}>
                  <FormLabel
                    id="3139574416"
                    className="fsize14 dynamicStyle cust-textColor"
                    mb={1}
                  >
                    {data ? data["3139574416"] : "Phone no."}
                  </FormLabel>
                  <Input placeholder="Enter Phone no." className="fsize14" />
                </FormControl>
                <div className="mt-6">
                  <Button
                    id="3502276186"
                    className={
                      colorMode === "light"
                        ? "dynamicStyle  bg-primary clr-fff w-full"
                        : "dynamicStyle bg-darkprimary bg-primary clr-fff w-full"
                    }
                  >
                    <span onClick={() => handleClick("3502276186")}>
                      {data ? data["3502276186"] : "Submit"}
                    </span>
                  </Button>
                </div>
              </div> */}
            </div>
          </Card>

          <Card className="cust-shadow">
            <div className="lg:p-8 md:p-8 p-4">
              <h1
                id="1729070496"
                className="dynamicStyle cust-textColor fsize20 md-fsize20 sm-fsize16 font-semibold text-black"
              >
                {data ? data["1729070496"] : "Got a Questions?"}
              </h1>
              <p
                className="dynamicStyle mt-3 fsize14 sm-fsize12 textColor-light"
                id="7785145668"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["7785145668"]
                    : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
              ></p>
              <div className="mt-6">
                <Button
                  id="9218318742"
                  colorScheme="primary"
                  py="6"
                  px="8"
                  className={
                    selectedStyle
                      ? "rounded-sm fsize14 clr-fff dynamicStyle   rounded-100px "
                      : "rounded-sm fsize14  clr-fff dynamicStyle   rounded-100px "
                  }
                >
                  <span onClick={() => handleClick("9218318742")}>
                    {data ? data["9218318742"] : "Create a Ticket"}
                  </span>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Summary1;
