import React from "react";
import { useSelector } from "react-redux";
import { Image, useColorMode } from "@chakra-ui/react";

import { Link, useNavigate } from "react-router-dom";
const Footer1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  const navigate = useNavigate();

  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  if (window.location.pathname === "/coming-soon") return null;
  if (window.location.pathname === "/user-registration") return null;

  // if (window.location.pathname === "/login") return null;
  // if (window.location.pathname === "/register") return null;
  // if (window.location.pathname === "/forgot password") return null;
  return (
    <section
      className={
        colorMode === "light"
          ? "bg-515151 theme-bg text-white"
          : "cust-bgColor textColor-light"
      }
    >
      {/* <div className=" lg:px-12 md:px-12 px-4 lg:py-4 py-4">
        <div className="lg:flex w-full items-center">
        <div
            id=""
            className="w-5/12 lg:block md:flex justify-center sm-mx-auto"
          >
            <Image
              src={getSecondaryImage(brandProfile)}
              className="w-10rem h-20 object-contain"
              alt="footer-logo"
            />
          </div>
        <div className=" lg:flex md:flex items-center justify-center lg:mt-0 mt-4 lg:w-full md:w-full w-full">
          <div className="flex flex-wrap lg:gap-12 md:gap-12 gap-8 lg:w-3/4 lg:justify-center md:justify-end justify-center md:w-3/4">
            <p
              onClick={() => navigate("/Pricing")}
              className="cursor-pointer fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2"
            >
              Pricing
            </p>
            <p
              onClick={() => navigate("/blogs")}
              className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer"
            >
              Blogs
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/contact us">Contact Us</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/user-agreement">User Agreement</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              {" "}
              <Link to="/terms-conditions"> Terms & Conditions</Link>
            </p>
          </div>
        </div>
        </div>
        <div className="lg:flex w-full justify-between md:mt-4 mt-6 lg:mt-4 items-center">
          <div className="lg:mt-0  md:lg-mt-0 mt-4">
            {project.poweredBy ? (
              <p className="fsize14 text-white sm-fsize14 ">
                powered by BRAINED
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div> */}

      <div className="text-center text-white fsize15 sm-fsize14 theme-textColor bordertop py-4 lg:flex md:flex justify-between items-center lg:px-12 md:px-12 px-4">
        <div className="lg:w-1/4 md:w-1/4">
          <div className="w-full lg:mb-0 md:mb-0 my-4 lg:justify-start justify-center flex items-center lg:gap-3 md:gap-3 gap-3">
            <div id="6074700231" className="dynamicStyle2">
              <a href="https://www.instagram.com/brained.app/" target="_blank">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-3-0/undefined/0.6912629471291782.png-_jP92eUtBfOZUwLV9kDm1-1718031809"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-6 h-6 mx-auto object-contain"
                  alt="footer-logo"
                />
              </a>
            </div>
            <div id="0979181830" className="dynamicStyle2">
              <a
                href="https://www.linkedin.com/company/brainedapp"
                target="_blank"
              >
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-3-0/undefined/0.3038232532580647.png-ZEa6niXEe7kH0P9DsOGmS-1718031809"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-6 h-6 mx-auto object-contain"
                  alt="footer-logo"
                />
              </a>
            </div>
            <div id="9496329671" className="dynamicStyle2">
              <a href="https://www.facebook.com/BrainedApp" target="_blank">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-3-0/undefined/0.1849530180014809.png-l2JutotJ8HJ2_uwVoYg0x-1718031809"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-6 h-6 mx-auto object-contain"
                  alt="footer-logo"
                />
              </a>
            </div>

            {/* <div id="9496329671" className="dynamicStyle2">
              <a href="" target="_blank">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-3-0/undefined/0.9175096026023812.png-cawgKsbkPYq5LkHNv9iqv-1718031809"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-6 h-6 mx-auto object-contain"
                  alt="footer-logo"
                />
              </a>
            </div> */}
            {/* <div id="4171537432" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['4171537432']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-6 h-6 mx-auto object-contain"
                alt="footer-logo"
              />
            </div> */}
          </div>
        </div>
        <div className="lg:my-0 md:my-0 my-4 flex flex-wrap lg:gap-12 md:gap-12 gap-8 lg:w-2/4 lg:justify-center md:justify-end justify-center md:w-2/4">
          <p
            onClick={() => navigate("/Pricing")}
            className="cursor-pointer fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2"
          >
            Pricing
          </p>
          <p
            onClick={() => navigate("/blog")}
            className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer"
          >
            Blogs
          </p>
          <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
            <Link to="/contact-us">Contact Us</Link>
          </p>
          {/* <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/user-agreement">User Agreement</Link>
            </p> */}
          <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          {/* <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              {" "}
              <Link to="/terms-conditions"> Terms & Conditions</Link>
            </p> */}
        </div>
        <div className="lg:w-1/4 md:w-1/4">
          {project.poweredBy ? (
            <p>Powered by Brained</p>
          ) : (
            <p>Copyright &copy;2024 All Right reserved</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Footer1;
