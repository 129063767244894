import React, { useEffect } from "react";
import { Button, Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
const CaseStudyFeatureCard = ({ pageName }) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const selectedCaseStudyData = useSelector(
    (state) => state.caseStudy.SingleCaseStudy
  );

  // console.log(selectedCaseStudyData, "HeroSection17");

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:pt-16 md:pt-16 pt-8  lg:pb-8 md:pb-8 pb-8">
      <div className="lg:flex md:flex ">
        <div className="lg:w-1/2 md:w-full">
          <h2 className="fsize34 md-fsize28 sm-fsize22 text-primary cust-textColor font-semibold pb-2">
            {selectedCaseStudyData?.longtitle}
          </h2>
          <p
            className="ul-ol-margin-left text-black fsize16 sm-fsize14 textColor-light lg:pb-8 md:pb-6 pb-4"
            dangerouslySetInnerHTML={{
              __html: selectedCaseStudyData?.longdescription,
            }}
          ></p>
          <h2
            id="4342784510"
            className="fsize28 dynamicStyle text-black md-fsize28 sm-fsize22  cust-textColor font-semibold pb-4"
          >
            {data ? data["4342784510"] : "Role"}
          </h2>

          <div className="flex lg:px-4 md:px-4 py-2 items-center lg:gap-6 md:md:gap-4 gap-5 lg:pb-8 md:pb-6 pb-4">
            <div className="lg:w-2/12 md:w-2/12 w-3/12 flex justify-center">
              <div className=" lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full">
                <Image
                  src={`${process.env.REACT_APP_STORAGE_URL}${selectedCaseStudyData?.roleicon1}`}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="w-full h-full rounded-full object-contain"
                />
              </div>
            </div>
            <div className="lg:w-10/12 md:w-10/12 w-9/12">
              <p
                id=""
                className=" text-black fsize20 md-fsize18 sm-fsize16 font-semibold cust-textColor"
              >
                {selectedCaseStudyData?.roletitle1}
              </p>
              <p
                id=""
                className="textColor-light text-black fsize16 sm-fsize14 pt-2"
              >
                {selectedCaseStudyData?.roledescription1}
              </p>
            </div>
          </div>
          <div className="flex lg:px-4 md:px-4 py-2 items-center lg:gap-6 md:md:gap-4 gap-5 lg:pb-6 md:pb-6 pb-4">
            <div className="lg:w-2/12 md:w-2/12 w-3/12 flex justify-center">
              <div className=" lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full">
                <Image
                  src={`${process.env.REACT_APP_STORAGE_URL}${selectedCaseStudyData?.roleicon2}`}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="w-full h-full rounded-full object-contain"
                />
              </div>
            </div>
            <div className="lg:w-10/12 md:w-10/12 w-9/12">
              <p
                id=""
                className="text-black  fsize20 md-fsize18 font-semibold sm-fsize16 cust-textColor"
              >
                {selectedCaseStudyData?.roletitle2}
              </p>
              <p
                id="8434905103"
                className="textColor-light text-black fsize16 sm-fsize14 pt-2 text-black"
              >
                {selectedCaseStudyData?.roledescriptio2}
              </p>
            </div>
          </div>
          <div className="flex lg:px-4 md:px-4 py-2 items-center lg:gap-6 md:md:gap-4 gap-5 lg:pb-8 md:pb-6 pb-4">
            <div className="lg:w-2/12 md:w-2/12 w-3/12 flex justify-center">
              <div className=" lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full">
                <Image
                  src={`${process.env.REACT_APP_STORAGE_URL}${selectedCaseStudyData?.roleicon3}`}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="w-full h-full rounded-full object-contain"
                />
              </div>
            </div>
            <div className="lg:w-10/12 md:w-10/12 w-9/12">
              <p
                id=""
                className="text-black fsize20 md-fsize18  font-semibold sm-fsize16 cust-textColor"
              >
                {selectedCaseStudyData?.roletitle3}
              </p>
              <p
                id="8434905103"
                className="textColor-light  fsize16 sm-fsize14 pt-2 text-black"
              >
                {selectedCaseStudyData?.roledescription3}
              </p>
            </div>
          </div>
          <Link to="/register">
            <div className="flex lg:px-4 md:px-4 py-2 items-center bg-pinkLight cust-bgColor rounded-sm lg:gap-6 md:gap-4 gap-5 ">
              <div className="lg:w-2/12 md:w-2/12 w-3/12 flex justify-center">
                <div
                  id="9181565934"
                  className="dynamicStyle2 lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
                >
                  <Image
                    src={data ? getImage(data["9181565934"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              </div>
              <div className="lg:w-10/12 md:w-10/12 w-9/12">
                <p
                  id="5353874126"
                  className="dynamicStyle text-primary font-medium fsize20 md-fsize18 sm-fsize16 cust-textColor"
                >
                  {data ? data["5353874126"] : "learn the latest skills"}
                </p>
                <p
                  id="6149017535"
                  className="dynamicStyle textColor-light sm-fsize14 pt-2 text-black"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["6149017535"]
                      : "Embrace innovation and stay ahead by mastering the latest",
                  }}
                ></p>
              </div>
            </div>
          </Link>

          {/* <Button
          id="5460882958"
          size="lg"
          className="dynamicStyle bg-primary theme-btn cust-bgColor clr-fff sm-fsize14 buttonAnimate bdr-2px"
        >
          <a href="/elearning-course">
            {data ? data['5460882958'] : 'Explore Course'}
          </a>
        </Button> */}
        </div>
        <div className="lg:w-1/2 md:w-full lg:ml-20 lg:pt-0 md:pt-8 pt-6">
          <h2
            id="8721133746"
            className="fsize28 dynamicStyle text-black md-fsize28 sm-fsize22  cust-textColor font-semibold pb-8"
          >
            {data ? data["8721133746"] : "Feature"}
          </h2>
          <p
            className="ul-ol-margin-left text-black fsize16 sm-fsize14 textColor-light lg:pb-8 md:pb-6 pb-4"
            dangerouslySetInnerHTML={{
              __html: selectedCaseStudyData?.feature,
            }}
          ></p>

          {/* <div className="flex lg:gap-6 md:md:gap-4 gap-5 lg:pt-8 md:pt-6 pt-4">
                    
                    <div className="w-2/12 flex justify-center">
                      <div
                        id="1356034544"
                        className="dynamicStyle2 lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
                      >
                        <Image
                          src={data ? getImage(data["1356034544"]) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img"
                          className="w-full h-full rounded-full"
                        />
                      </div>
                    </div>
                    <div className="w-10/12">
                      <p
                        id="8137793069"
                        className="dynamicStyle  fsize30 md-fsize20 sm-fsize20 cust-textColor"
                      >
                        {data ? data["8137793069"] : "Get ready or a career"}
                      </p>
                      <p
                        id="5598414986"
                        className="dynamicStyle textColor-light pt-2"
                        dangerouslySetInnerHTML={{
                          __html: data
                            ? data["5598414986"]
                            : "Prepare for a promising career by acquiring essential",
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="flex lg:gap-6 md:md:gap-4 gap-5 lg:pt-8 md:pt-6 pt-4">
                    
                    <div className="w-2/12 flex justify-center">
                      <div
                        id="9818234064"
                        className="dynamicStyle2 lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
                      >
                        <Image
                          src={data ? getImage(data["9818234064"]) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img"
                          className="w-full h-full rounded-full"
                        />
                      </div>
                    </div>
                    <div className="w-10/12">
                      <p
                        id="4417932954"
                        className="dynamicStyle  fsize30 md-fsize20 sm-fsize20 cust-textColor"
                      >
                        {data ? data["4417932954"] : "Earn a certificate"}
                      </p>
                      <p
                        id="8434905103"
                        className="dynamicStyle textColor-light sm-fsize14 pt-2"
                        dangerouslySetInnerHTML={{
                          __html: data
                            ? data["8434905103"]
                            : "Elevate your expertise and showcase your accomplishments",
                        }}
                      ></p>
                    </div>
                  </div> */}
        </div>
      </div>
    </div>
  );
};

export default CaseStudyFeatureCard;
