import { Button, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import { Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const AddBanner2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const { colorMode, toggleColorMode } = useColorMode();

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="relative application-h">
      <div className="dynamicStyle2 w-full" id="6704948690">
        <Image
          src={data ? getImage(data["6704948690"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full object-cover  application-h"
          alt="application"
        />
      </div>

      <div className="absolute top-0 left-0 flex items-center application-h w-full">
        <div className="container mx-auto lg:px-12 md:px-8 px-5">
          <div className="lg:w-5/12 " data-aos="fade-up" data-aos-once="true">
            <small
              className="fsize18 sm-fsize15 text-left text-primary dynamicStyle"
              id="7698708597"
            >
              {data ? data["7698708597"] : "Lorem Ipsum"}
            </small>
            <h3
              className="fsize38 md-fsize36 sm-fsize20 font-semibold text-white text-left dynamicStyle"
              id="3856192915"
            >
              {data ? data["3856192915"] : "Lorem Ipsum"}
            </h3>
            <p
              className="fsize18 md:fsize16 sm-fsize12 text-left mt-2 text-white dynamicStyle lg:mr-0 md:mr-0 mr-28"
              id="1405252807"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["1405252807"]
                  : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
              }}
            ></p>
            
            <div className="gradient-border mt-6">
            <Button
            borderRadius={30}
              colorScheme="white"
              px={8}
              className={
                colorMode === "light"
                  ? "dynamicStyle btn-circle btn-dual sm-btn-dual  clr-white sm-fsize14 "
                  : "dynamicStyle btn-circle btn-dual sm-btn-dual  clr-white sm-fsize14 "
              }
              
              id="2408499979"
            >
              <img
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/reward.png"
                  alt="reward"
                  class="reward-img object-contain"
                />
                <span
                  onClick={() => handleClick("2408499979")}
                  className="ml-2 textwhite"
                >
                  {data ? data["2408499979"] : "Lorem ipsum"}
                </span>
                <FaLongArrowAltRight className="textwhite ml-2 textColor-light" />
              
            </Button>
            </div>

            
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBanner2;
