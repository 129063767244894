import React from 'react'
import { Image } from '@chakra-ui/react';
import { Mail } from 'react-feather';

const AboutSection34 = () => {
  return (
    <div className="relative h-800px md-h-700px sm-h550px">
      <Image
        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        className="h-800px md-h-700px sm-h550px w-full object-cover"
      />
      <div className="h-800px md-h-700px sm-h550px absolute top-0 left-0 w-full flex items-center">
        <div className="lg:w-1/2 md:w-3/5 lg:pl-20 md:pl-12 md:px-0 px-5 lg:py-20 md:py-16 py-8">
          <h2 className="fsize48 md-fsize36 sm-fsize24 lheight48  sm-lh-24 font-light">All Your</h2>
          <h2 className="fsize64 md-fsize48 md-lh-48 sm-fsize32 sm-lh-46 font-semibold font-semibold">Beauty Favourites</h2>
          <p className="fsize24 md-fsize20 fsize16 font-light text-primary lg:mt-4 md:mt-3 mt-2 mb-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          </p>
          
          <div className="grid grid-cols-2 lg:gap-12 md:gap-8 gap-6 lg:mt-12 md:mt-10 mt-6">
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs' alt='skin' className='lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover'/>
                <p className='fsize16 sm-fsize14'>Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs' alt='skin' className='lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover'/>
                <p className='fsize16 sm-fsize14'>Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs' alt='skin' className='lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover'/>
                <p className='fsize16 sm-fsize14'>Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs' alt='skin' className='lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover'/>
                <p className='fsize16 sm-fsize14'>Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs' alt='skin' className='lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover'/>
                <p className='fsize16 sm-fsize14'>Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs' alt='skin' className='lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover'/>
                <p className='fsize16 sm-fsize14'>Lorem Ispum</p>
            </div>
           
         
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection34
