import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Header4 = () => {
//     let brandProfile = useSelector(state => state.brandDetails.brandProfile);
//   let menuData = useSelector(state => state.menuState.menu);


  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);

  const handleFirstDropdownClick = () => {
    setIsFirstDropdownOpen(!isFirstDropdownOpen);
  };

  const handleSecondDropdownClick = () => {
    setIsSecondDropdownOpen(!isSecondDropdownOpen);
  };

  //primarylogo
//   function getPrimaryImage(value) {
//     return value
//       ? value.primaryLogo
//         ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
//         : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
//       : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
//   }
  return (
     <header>
       <nav>
         <div className="logo flex items-center">
           <NavLink to="">King</NavLink>
         </div>
         <label htmlFor="menudrop" className="bartoggle">
           <HamburgerIcon />
         </label>
         <input type="checkbox" id="menudrop" />
         <ul className="NavMenu">
          
           <li>
             <NavLink to="#" onClick={handleFirstDropdownClick}>
               <label htmlFor="droplist1" className="toggle">
                 Solutions <ChevronDownIcon />
               </label>
             </NavLink>
             <input
               type="checkbox"
               id="droplist1"
               checked={isFirstDropdownOpen}
               onChange={handleFirstDropdownClick}
               style={{ display: 'none' }}
             />
             {/* FirstDropDown */}
             {isFirstDropdownOpen && (
               <ul className="dropdown">
                 
                 <li>
                   <NavLink to="#" onClick={handleSecondDropdownClick}>
                     <label htmlFor="droplist2" className="toggle">
                       Web 2 <ChevronDownIcon />
                     </label>
                   </NavLink>
                   <input
                     type="checkbox"
                     id="droplist2"
                     checked={isSecondDropdownOpen}
                     onChange={handleSecondDropdownClick}
                     style={{ display: 'none' }}
                   />
                   {/* Sub Drop Menu */}
                   {isSecondDropdownOpen && (
                     <ul className="dropdown">
                       <li>
                         <NavLink to="">Website</NavLink>
                       </li>
                       <li>
                         <NavLink to="">Android & OS APPS</NavLink>
                       </li>
                       <li>
                         <NavLink to="">Web Applications</NavLink>
                       </li>
                     </ul>
                   )}
                 </li>
                 <li>
                   <NavLink to="#" onClick={handleSecondDropdownClick}>
                     <label htmlFor="droplist2" className="toggle">
                       Web 3 <ChevronDownIcon />
                     </label>
                   </NavLink>
                   <input
                     type="checkbox"
                     id="droplist2"
                     checked={isSecondDropdownOpen}
                     onChange={handleSecondDropdownClick}
                     style={{ display: 'none' }}
                   />
                   {/* Sub Drop Menu */}
                   {isSecondDropdownOpen && (
                     <ul className="dropdown">
                       <li>
                         <NavLink to="">LM Solutions</NavLink>
                       </li>
                       <li>
                         <NavLink to="">Metaverse</NavLink>
                       </li>
                      
                     </ul>
                   )}
                 </li>
                 
               </ul>
             )}
           </li>
           <li>
           <NavLink to="#" >
            
                 Solutions 
               
             </NavLink>
           </li>
           
         </ul>
       </nav>
     </header>
  )
}

export default Header4
