import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

const SkeletonCaseStudy = () => {
  return (
    <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-8 gap-6 lg:mt-12 md:mt-12 mt-8">
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
    </div>
  );
};

export default SkeletonCaseStudy;
