import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorMode,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

const FAQSection4 = ({ pageName }) => {
  const { colorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-16 px-5 py-10">
      <div className="lg:pb-8 md:pb-8 pb-4">
        <p
          id="1571274210"
          className="dynamicStyle text-primary fsize16 md-fsize16 sm-fsize14 textColor-light font-medium"
        >
          {data ? data["1571274210"] : "Start With The Basics"}
        </p>
        <p
          id="2279070131"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor"
        >
          {data ? data["2279070131"] : "Frequently Asked Questions"}
        </p>
      </div>

      <div>
        <Tabs>
          <TabList className="lg:pb-6 md:pb-6 pb-2 overflow-swipe">
            <Tab className=" fsize18 sm-fsize14 font-medium text-primary cust-textColor theme-textColor">
              <div id="3437786096" className="dynamicStyle">
                {data ? data["3437786096"] : "Conditions"}
              </div>
            </Tab>
            {/* <Tab className="fsize18 sm-fsize14 font-medium cust-textColor theme-textColor">
              Products
            </Tab>
            <Tab className="fsize18 sm-fsize14 font-medium cust-textColor theme-textColor">
              Billing
            </Tab>
            <Tab className="fsize18 sm-fsize14 font-medium cust-textColor theme-textColor">
              Portal
            </Tab>
            <Tab className="fsize18 sm-fsize14 font-medium cust-textColor theme-textColor">
              Customer Service
            </Tab> */}
          </TabList>

          <TabPanels
            className={
              colorMode === "light"
                ? "border-0 custShadow1 lg:p-8 md:p-8 p-4  theme-bg themeShadow"
                : "lg:p-8 md:p-8 p-4 cust-bgColor theme-bg themeShadow"
            }
          >
            <TabPanel>
              <div id="8058383966" className="dynamicStyle">
                {data && data["8058383966"]
                  ? data["8058383966"].map((e) => {
                      return (
                        <div>
                          <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                            {e.field1}
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e.field2,
                            }}
                            className="text-gray-400 sm-fsize14 textColor-light"
                          ></p>
                          <hr className="bdr-btm my-6 w-full" />
                        </div>
                      );
                    })
                  : "no-data"}
              </div>
            </TabPanel>
            {/* <TabPanel>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provianimi, dolorum obcaecati, omnis amet
                  officia fuga natus cum officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipent! Tempora,
                  animi, dolorum obcaecati, omnis amet officia fuga natus cum
                  officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fuga natofficiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fugam officiis hic in.
                </p>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam ati, omnis amet officia fuga hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consecteolorum obcaecati, omnis
                  amet officia fugacum officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officiin.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fuga natus cum officiis hic in.
                </p>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! dolorum obcaecati, omnis amet
                  officia fuga n in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem olor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fuga natus cum officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdampora, animi, dolorum obcaecati, omnis amet officia
                  fuga natus cum officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fuga
                </p>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14 textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fuga natus cum officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14">
                  Lorem ipsum doit. Quibusdam, ipsam provident! Tempora, animi,
                  dolorum obcaecati, omnis amet officia fuga natus cum officiis
                  hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-gray-400 sm-fsize14">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsam provident! Tempora, animi, dolorum obcaecati,
                  omnis amet officia fuga natus cum officiis hic in.
                </p>
                <hr className="bdr-btm my-6 w-full" />
              </div>
              <div>
                <p className="fsize20 sm-fsize16 font-semibold pb-2 cust-textColor">
                  Lorem ipsum dolor .
                </p>
                <p className="text-gray-400 sm-fsize14">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, ipsamanimi, dolorum obcaecati, omnis amet officia
                  fuga natus cum officiis hic in.
                </p>
              </div>
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default FAQSection4;
