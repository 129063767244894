const initialState = {
  JobPosting: [],
  JobApplication: [],
  SelectedCareer: [],
  SingleJobPosting: {},
};

const careers_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_JOB_POSTING":
      return {
        ...state,
        JobPosting: action.payload,
      };
    case "SET_SINGLE_JOB_POSTING":
      return {
        ...state,
        SingleJobPosting: action.payload,
      };
    case "SET_ALL_JOB_APPLICATION":
      return {
        ...state,
        JobApplication: action.payload,
      };

    case "SET_SELECTED_CAREER":
      return {
        ...state,
        SelectedCareer: action.payload,
      };

    default:
      return state;
  }
};

export default careers_reducer;
