import React, { useEffect, useRef, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Divider,
  AbsoluteCenter,
  Box,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";

const AboutSection35 = ({ pageName }) => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const elementRef = useRef(null);
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <section>
      <div className="lg:flex md:flex container mx-auto  lg:py-16 md:py-10 py-8 lg:px-12 md:px-8 px-5 relative">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-8 md:pr-8 flex flex-col justify-center relative zindex9">
          <div className=" lg:w-11/12 md:w-11/12  rounded-lg">
            <iframe
              src="https://www.youtube-nocookie.com/embed/kkipHKSh3i8?si=b3_X-QHhyJlp-mhn&amp;controls=0"
              title="Example Iframe"
              height="288"
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              refreerepolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ border: "none" }}
            />
            {/* <Image
              src={data ? getImage(data['9917014687']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
             
            /> */}
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-6 zindex9 flex flex-col justify-center">
          <h2
            id="7856408819"
            className="dynamicStyle text-black cust-textColor lg:text-4xl md:text-2xl  text-xl  font-bold"
          >
            {data ? data["7856408819"] : "no-data"}
          </h2>

          <p
            id="0690964196"
            className=" dynamicStyle textColor-light lg:mt-4 md:mt-4 mt-2 text030303 lg:text-base md:text-base text-sm lg:leading-7 md:leading-7 leading-6"
            dangerouslySetInnerHTML={{
              __html: data ? data["0690964196"] : "no-data",
            }}
          ></p>

          {/* <div className="zindex9 flex gap-4">
            <Button
              colorScheme="primary"
              className="dynamicStyle lg:mt-8 md:mt-8 mt-6 clr-fff theme-btn bg-primary cust-bgColor rounded-full sm-fsize14 buttonAnimate bdr-2px"
              id="1359649800"
            >
              <span onClick={() => handleClick("1359649800")}>
                {data ? data["1359649800"] : "Button"}
              </span>
            </Button>
            <Button
              className="dynamicStyle lg:mt-8 md:mt-8 mt-6 theme-btn cust-bgColor rounded-full sm-fsize14 buttonAnimate bdr-2px"
              id="8855980468"
            >
              <span onClick={() => handleClick("8855980468")}>
                {data ? data["8855980468"] : "Button"}
              </span>
            </Button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AboutSection35;
