const initialState = {
  client: [],
  clients: [],
};
const ClientDetails = (state = initialState, action) => {
  switch (action.type) {
    case "setClient":
      return { ...state, client: action.payload };
    case "setAllClient":
      return { ...state, clients: action.payload };
    default:
      return state;
  }
};

export default ClientDetails;
