import React, { useEffect, useRef, useState } from "react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "@chakra-ui/react";
import FeatherIcon from "feather-icons-react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";

const Services3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className=" w-full lg:mb-16 md:mb-8">
        <h3
          id="1006273985"
          className="dynamicStyle text-primary fsize24 sm-fsize16 font-semibold clr-ddd9d8 lg:mb-2 textColor-light"
        >
          {data ? data["1006273985"] : "Lorem Ipsum"}
        </h3>
        <h1
          id="1795184656"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["1795184656"]
              : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          }}
          className="dynamicStyle fsize32 sm-fsize24 font-semibold lg:mb-8 md:mb-8 mb-6 cust-textColor"
        ></h1>
        {/* <p
          id="6941527127"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["6941527127"]
              : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          }}
          className="dynamicStyle fsize18 sm-fsize14 lg:mb-0 md:mb-0 mb-4 textColor-light"
        ></p> */}
      </div>

      <div>
        <Tabs position="relative" variant="unstyled">
          <TabList>
            <Tab id="0711949835" className="dynamicStyle font-semibold">
              {data ? data["0711949835"] : "Lorem Ipsum"}
            </Tab>
            <Tab id="1465049220" className="dynamicStyle font-semibold">
              {data ? data["1465049220"] : "Lorem Ipsum"}
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />

          <TabPanels>
            <TabPanel className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
              <div id="6397860388" className="dynamicStyle2 h-80">
                <Image
                  className="h-80 w-full rounded-lg"
                  src={data ? getImage(data["6397860388"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="8286456221" className="dynamicStyle2 h-80">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["8286456221"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="5496008493" className="dynamicStyle2 h-80">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["5496008493"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="7853261816" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["7853261816"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>

              <div id="0496764067" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["0496764067"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
            </TabPanel>
            <TabPanel className="grid grid-cols-5 gap-4">
              <div id="8836459571" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["8836459571"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="9303796134" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["9303796134"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="8956020627" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["8956020627"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="3227194009" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["3227194009"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>

              <div id="3777368327" className="dynamicStyle2 h-80 ">
                <Image
                  className=" h-80 w-full rounded-lg"
                  src={data ? getImage(data["3777368327"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </section>
  );
};

export default Services3;
