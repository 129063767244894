import React, { useEffect, useRef, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const Team13 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="lg:flex md:flex sm-reverse lg:gap-12 md:gap-8 gap-6">
        <div className="lg:w-7/12 md:w-7/12 w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          <div
            className={
              colorMode === 'light'
                ? 'px-4 py-6 text-center bg-e7ddff theme-bg themeShadow rounded-lg'
                : 'px-4 py-6 text-center cust-bgColor theme-bg themeShadow rounded-lg '
            }
          >
            <div className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 mx-auto">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-full theme-border border-4px-primary"
              />
            </div>
            <p className="fsize20 font-medium lg:pt-6 md:pt-6 pt-4 clr-fff theme-textColor cust-textColor">
              Michael Foster
            </p>
            <p className="fsize18 lg:py-4 md:py-4 py-2 clr-9c9c9c textColor-light">
              Co-Founder / CO
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'px-4 py-6 text-center bg-e7ddff theme-bg themeShadow rounded-lg'
                : 'px-4 py-6 text-center cust-bgColor theme-bg themeShadow rounded-lg '
            }
          >
            <div className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 mx-auto">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-full theme-border border-4px-primary"
              />
            </div>
            <p className="fsize20 font-medium lg:pt-6 md:pt-6 pt-4 clr-fff theme-textColor cust-textColor">
              Michael Foster
            </p>
            <p className="fsize18 lg:py-4 md:py-4 py-2 clr-9c9c9c textColor-light">
              Co-Founder / CO
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'px-4 py-6 text-center bg-e7ddff theme-bg themeShadow rounded-lg'
                : 'px-4 py-6 text-center cust-bgColor theme-bg themeShadow rounded-lg '
            }
          >
            <div className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 mx-auto">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-full theme-border border-4px-primary"
              />
            </div>
            <p className="fsize20 font-medium lg:pt-6 md:pt-6 pt-4 clr-fff theme-textColor cust-textColor">
              Michael Foster
            </p>
            <p className="fsize18 lg:py-4 md:py-4 py-2 clr-9c9c9c textColor-light">
              Co-Founder / CO
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'px-4 py-6 text-center bg-e7ddff theme-bg themeShadow rounded-lg'
                : 'px-4 py-6 text-center cust-bgColor theme-bg themeShadow rounded-lg '
            }
          >
            <div className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 mx-auto">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-full theme-border border-4px-primary"
              />
            </div>
            <p className="fsize20 font-medium lg:pt-6 md:pt-6 pt-4 clr-fff theme-textColor cust-textColor">
              Michael Foster
            </p>
            <p className="fsize18 lg:py-4 md:py-4 py-2 clr-9c9c9c textColor-light">
              Co-Founder / CO
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'px-4 py-6 text-center bg-e7ddff theme-bg themeShadow rounded-lg'
                : 'px-4 py-6 text-center cust-bgColor theme-bg themeShadow rounded-lg '
            }
          >
            <div className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 mx-auto">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-full theme-border border-4px-primary"
              />
            </div>
            <p className="fsize20 font-medium lg:pt-6 md:pt-6 pt-4 clr-fff theme-textColor cust-textColor">
              Michael Foster
            </p>
            <p className="fsize18 lg:py-4 md:py-4 py-2 clr-9c9c9c textColor-light">
              Co-Founder / CO
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'px-4 py-6 text-center bg-e7ddff theme-bg themeShadow rounded-lg'
                : 'px-4 py-6 text-center cust-bgColor theme-bg themeShadow rounded-lg '
            }
          >
            <div className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 mx-auto">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-full theme-border border-4px-primary"
              />
            </div>
            <p className="fsize20 font-medium lg:pt-6 md:pt-6 pt-4 clr-fff theme-textColor cust-textColor">
              Michael Foster
            </p>
            <p className="fsize18 lg:py-4 md:py-4 py-2 clr-9c9c9c textColor-light">
              Co-Founder / CO
            </p>
          </div>
        </div>
        <div className="lg:w-5/12 md:w-5/12 w-full">
          <h3 className="fsize40 md-fsize32 sm-fsize20 font-bold cust-textColor pb-2">
            Meet Our Team
          </h3>
          <p className="lg:leading-8 md:leading-7 leading-6 sm-fsize14 textColor-light">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam
            sunt alias, non ratione repellendus corrupti corporis quia, eligendi
            veniam, qui minima. Dicta neque dignissimos rerum autem vitae enim
            commodi minus. Lorem ipsum dolor sit amet consectetur, adipisicing
            elit. Aliquam sunt alias, non ratione repellendus corrupti corporis
            quia, eligendi veniam, qui minima. Dicta neque dignissimos rerum
            autem vitae enim commodi minus. Lorem ipsum dolor sit amet
            consectetur, adipisicing elit. Aliquam sunt alias, non ratione
            repellendus corrupti corporis quia, eligendi veniam, qui minima.
            Dicta neque dignissimos rerum autem vitae enim commodi minus.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Team13;
