import { Button, Image, useColorMode } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/darkLogo.png";

const ComingSoon1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  return (
    <div className="relative w-full h-100vh overflow-hidden">
      <div className="container mx-auto lg:px-12 md:px-12 px-5 flex lg:flex-row md:flex-row flex-col items-center justify-center lg:gap-20 md:gap-12 h-100vh">
        <div className="lg:w-1/2 md:w-1/2 w-full">
          {colorMode === "light" ? (
            <NavLink to="/">
              <Image
                src={getPrimaryImage(brandProfile)}
                alt="logo"
                className="w-32 h-16 object-contain clr-fff"
              />
            </NavLink>
          ) : (
            <NavLink to="/">
              <Image
                src={Logo}
                alt="logo"
                className="w-32 h-16 object-contain clr-fff"
              />
            </NavLink>
          )}

          <h2
            id="6308064098"
            className="dynamicStyle fsize36 md:fsize28 sm-fsize24 mt-6 cust-textColor font-semibold"
          >
            {data ? data["6308064098"] : "Coming Soon"}
          </h2>
          <p
            className="dynamicStyle textColor-light fsize14 text-gray sm-fsize14 mb-6 lg:pr-16 md:pr-12"
            id="0070511462"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["0070511462"]
                : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            }}
          ></p>

          <div id="1604530638" className="dynamicStyle inline-block">
            <NavLink to="/">
              <Button
                borderRadius={30}
                px={8}
                className=" bg-primary cust-bgColor clr-fff "
              >
                {data ? data["1604530638"] : "Home"}
              </Button>
            </NavLink>
          </div>
        </div>
        <div
          id="3375488556"
          className="lg:mt-0 md:mt-0 mt-6 lg:w-1/2 md:w-1/2 w-full dynamicStyle2"
        >
          <Image
            src={data ? getImage(data["3375488556"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="coming-soon"
            className="w-full object-contain"
          />
        </div>
      </div>

      <div className="circle-pink cust-bgColor"></div>
      <div className="circle-yellow cust-bgColor"></div>
    </div>
  );
};

export default ComingSoon1;
