import { InfoIcon, JobIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getData } from '../../../redux/forms/forms-action';
import FeatherIcon from 'feather-icons-react';
const CareersDetails = () => {
  const [params, setParams] = useSearchParams();
  const dispatch = useDispatch();
  const [jobDetails, setJobDetails] = useState({});
  console.log(jobDetails, 'ssst');
  useEffect(() => {
    async function mount() {
      if (params) {
        let jobId = params.get('ji');

        let payload = {
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
          _id: jobId,
        };
        let data = await dispatch(getData('careers-job_postings', payload));
        if (data) setJobDetails(data.result[0]);
      }
    }
    mount();
  }, [dispatch]);

  
  return (
    <div className="container mx-auto">
      <div className="w-full lg:px-12 md:px-8 px-5">
        <div className=" w-full mx-auto pt-6 ">
          <h2 className="font-bold fsize32 cust-textColor">{jobDetails.jobtitle}</h2>
          <div className="flex items-center gap-2 fsize20 pt-6">
            <FeatherIcon
              icon="briefcase"
              size={18}
              className="cursor-pointer cust-textColor"
            />
            <p className='textColor-light'>{jobDetails.departmentname}</p>
          </div>
          <div className="flex items-center gap-2 fsize20 pt-6">
            <FeatherIcon icon="map-pin" size={18} className="cursor-pointer cust-textColor" />
            <p className='textColor-light'>{jobDetails.joblocation}</p>
          </div>
          <div className="  pt-10">
            <h2 className="font-bold fsize24 sm-fsize20 cust-textColor">Requirements:</h2>
            <p className="text-gray fsize16  leading-7 pt-2 textColor-light">
              {jobDetails.requirements}
            </p>
          </div>

          <div className="pt-8">
            <h2 className="font-bold fsize24 sm-fsize20 cust-textColor">Responsibilities:</h2>
            <p className="text-gray fsize16 leading-7 pt-2 textColor-light">
              {jobDetails.responsibilities}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDetails;
