import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
  Stack,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import Multiselect from "multiselect-react-dropdown";

import axios from "axios";
import {
  getFormSchema,
  getSchemaByFormId,
  getInputByFormId,
} from "../../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { object } from "yup";
import { values } from "lodash";
import form from "../../../../apis/client/Forms/form";
const CRMForm = ({ pageName, formId, close }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const sectionName = useSelector((state) => state.cms.sectionNames[pageName]);
  // let getallField = useSelector((state) => state.Forms.FormInput);
  let getFormName = useSelector((state) => state.Forms.Forms);
  // let getSchemaForm = useSelector((state) => state.Forms.FormSchemaByFormId);

  let userToken = useSelector((state) => state.AuthDetails.user.token);
  const [selectValue, setSelectvalue] = useState([]);

  const [options] = useState([
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    // Handle the select event
    setSelectvalue(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Handle the remove event
    setSelectvalue(selectedList);
  };
  const [formFields, setForm] = useState({});
  const [change, setChange] = useState(false);
  const [getallField, setFormData] = useState("");
  const [getSchemaForm, setGetSchemaForm] = useState("");
  const [render, setRender] = useState(false);

  console.log(getallField, "getAllField");
  useEffect(() => {
    async function mount() {
      //   if (formId) {
      //     let payload = {
      //       formId: formId,
      //     };

      //     let formDetails = await dispatch(getInputByFormId(payload));
      //     setFormData(formDetails);
      //     let formSchema = await dispatch(getSchemaByFormId(payload));
      //     setGetSchemaForm(formSchema);
      //   } else {
      //     let formSection = sectionName.filter(
      //       (x) => x.sectionId === "4457530739"
      //     );

      //     if (formSection.length && formSection[0].form) {
      //       let payload = {
      //         formId: formSection[0].form,
      //       };

      //       let formDetails = await dispatch(getInputByFormId(payload));
      //       setFormData(formDetails);
      //       let formSchema = await dispatch(getSchemaByFormId(payload));
      //       setGetSchemaForm(formSchema);
      //     }
      //   }

      try {
        let { data } = await axios.post(
          `${process.env.REACT_APP_SERVICE_BASE_URL}` + "crmForms/getForms",
          {
            _id: "6671371db468640012ea2664",
          }
        );
        if (data?.data?.length > 0) {
          console.log(data, "checkcrm");
          setFormData(data?.data[0]?.field);
        }
      } catch (error) {}

      try {
        let { data } = await axios.post(
          `${process.env.REACT_APP_SERVICE_BASE_URL}` + "crmForms/getSchema",
          {
            formId: "6671371db468640012ea2664",
          }
        );

        if (data?.data?.length > 0) {
          console.log(data, "getschemacheckcrm");
          setGetSchemaForm(data?.data);
        }
      } catch (error) {}

      // .then((response) => {
      //   console.log(response.data.data, "checkcrm");
      //   setFormData(response.data.data);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
      setRender(!render);
    }
    mount();
  }, [dispatch]);
  console.log(formFields, "from value");
  //getallField[0].field.value,
  useEffect(() => {
    console.log("render", getallField, getSchemaForm);
  }, [render]);
  const handleClear = () => {
    if (getallField) {
      let tempForm = {};
      getallField.map((x) => {
        if (x.inputType != "button") tempForm[x.value] = "";
      });
      setForm(tempForm);
    }
  };

  const handleSubmit = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
    };
    payload = { ...formFields, ...payload };

    let key = Object.keys(payload);
    let value = Object.values(payload);
    let formData = new FormData();

    console.log(payload, "cloudpl");
    for (let i = 0; i < key.length; i++) {
      if (value[i].type && value[i].type === "files") {
        for (let j = 0; j < value[i].files.length; j++) {
          formData.append(key[i], value[i].files[j]);
        }
      } else {
        formData.append(key[i], value[i]);
      }
    }

    let output = await axios.post(
      `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm[0].schemaName}`,
      formData,

      {
        headers: {
          "community-name": process.env.REACT_APP_COMMUNITY_NAME,
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (output && output.data) {
      handleClear();
      close(false);
    }
  };

  const handleChange = async (e, field, item) => {
    let fields;

    if (item === "file") {
      fields = { ...formFields, [field]: e.target.files[0] };
    } else if (item === "files") {
      fields = {
        ...formFields,
        [field]: { type: "files", files: e.target.files },
      };
    } else if (item === "radio") {
      fields = { ...formFields, [field]: e };
    } else {
      fields = { ...formFields, [field]: e.target.value };
      // console.log('ENtered else');
    }

    setForm(fields);
  };

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5  lg:py-12 md:py-10 py-8">
        <div className="text-center mb-8">
          <h2
            id="3532025242"
            className=" fsize30 sm-fsize16 font-semibold text-primary"
          >
            {getSchemaForm &&
            getSchemaForm.length &&
            getSchemaForm[0].formId &&
            getSchemaForm[0].formId.formName
              ? getSchemaForm[0].formId.formName
              : ""}
          </h2>
        </div>
        <div>
          <div className="lg:w-3/5 m-auto lg:grid md:grid grid-cols-2 gap-4 ">
            {getallField
              ? getallField.map((item, index) => {
                  return (
                    <div className="lg:pb-2 md:pb-2 pb-4 ">
                      {item.inputType !== "button" && (
                        <p
                          id="1437369070"
                          className=" lg:pb-2 md:pb-2 pb-1 clr-191E22"
                        >
                          {item.label}
                        </p>
                      )}
                      {item.inputType == "input" && (
                        <Input
                          className="p-2 inp-bdr w-full bg-white"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        />
                      )}
                      {item.inputType == "multiline" && (
                        <Textarea
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        />
                      )}
                      {item.inputType == "phone" && (
                        <Input
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        />
                      )}
                      {item.inputType == "email" && (
                        <Input
                          type="email"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        />
                      )}
                      {item.inputType == "url" && (
                        <Input
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        />
                      )}
                      {item.inputType === "singleselect" && (
                        <Select
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          className="fsize14"
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        >
                          {item.enum.map((i) => {
                            return <option value={i}>{i}</option>;
                          })}
                        </Select>
                      )}
                      {item.inputType === "radiobutton" && (
                        <RadioGroup
                          defaultValue="Itachi"
                          onChange={(e) => {
                            handleChange(e, item.value, "radio");
                          }}
                        >
                          <HStack spacing="24px">
                            {item.enum.map((e) => {
                              return <Radio value={e}>{e}</Radio>;
                            })}
                          </HStack>
                        </RadioGroup>
                      )}
                      {item.inputType === "imagefile" && (
                        <input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "file");
                          }}
                        />
                      )}
                      {item.inputType === "file" && (
                        <input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "file");
                          }}
                        />
                      )}

                      {item.inputType === "profilepic" && (
                        <input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "file");
                          }}
                        />
                      )}

                      {item.inputType === "multipleimagefile" && (
                        <input
                          type="file"
                          multiple
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                        />
                      )}

                      {item.inputType === "multiselect" && (
                        <Multiselect
                          className="w-full"
                          options={item.enum.map((select, index) => {
                            return { name: select, id: index };
                          })} // Options to display in the dropdown
                          selectedValues={selectValue} // Preselected value to persist in dropdown
                          onSelect={onSelect} // Function will trigger on select event
                          onRemove={onRemove} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      )}

                      {item.inputType === "date" && (
                        <Input
                          type="date"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                        />
                      )}

                      {item.inputType === "date&time" && (
                        <Input
                          type="datetime-local"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                        />
                      )}

                      {item.inputType === "checkbox" && (
                        // <CheckboxGroup defaultValue="select">
                        //   <Stack>
                        //     {item.enum.map(check => {
                        //       <Checkbox value={check}>{check}</Checkbox>;
                        //     })}
                        //   </Stack>
                        // </CheckboxGroup>
                        <CheckboxGroup defaultValue="select">
                          <Checkbox>check</Checkbox>
                          <Checkbox>check</Checkbox>
                          <Checkbox>check</Checkbox>
                        </CheckboxGroup>
                      )}
                      {item.inputType == "button" && (
                        <Input
                          type="button"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}
                      {item.inputType === "time" && (
                        <Input
                          type="time"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                        />
                      )}

                      {item.inputType == "notessystem" && (
                        <Textarea
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        />
                      )}
                    </div>
                  );
                })
              : ""}
          </div>

          <div className="flex justify-center mt-4">
            <Button
              onClick={() => handleSubmit()}
              colorScheme="primary"
              py="py-4"
              px="8"
              className=" font-semibold bg-da8f2c py-2 px-4 mt-6"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CRMForm;
