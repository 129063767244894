import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import UploaderBox from "../../../components/UploaderBox";
import { useNavigate } from "react-router-dom";
import { getAllJobPosting } from "../../../redux/careers/careers-action";
import SkeletonCareer from "../../../components/skeleton/SkeletonCareer";

const CareersSection = ({ pageName }) => {
  const { colorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const getJobPostingData = useSelector((state) => state.careers.JobPosting);

  const {
    isOpen: isOpenJob,
    onOpen: onOpenJob,
    onClose: onCloseJob,
  } = useDisclosure();

  const navigate = useNavigate();

  // const handleDirect = () => {
  //   navigate("/career-details");
  // };
  const dispatch = useDispatch();

  const [load, setLoad] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     dispatch(getAllJobPosting());
  //     setLoad(false);
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    try {
      setLoad(true);
      dispatch(getAllJobPosting());
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }, []);

  return (
    <section>
      <Modal isOpen={isOpenJob} onClose={onCloseJob} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="clr-fff">Job Application Details</ModalHeader>
          <ModalCloseButton className="clr-fff" />
          <ModalBody pb={6}>
            {/* <FormControl>
              <FormLabel mt={4}>Job Title</FormLabel>
              <Input placeholder="Job Title" />
            </FormControl> */}
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-x-4 w-full">
              <FormControl>
                <FormLabel mt={4}>First Name*</FormLabel>
                <Input placeholder="First name" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontWeight={500}>Last Name*</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontWeight={500}>Phone*</FormLabel>
                <Input placeholder="Phone" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontWeight={500}>Email*</FormLabel>
                <Input placeholder="Email" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontWeight={500}>Location*</FormLabel>
                <Input placeholder="Location" />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel fontWeight={500}>Linkedin Profile</FormLabel>
                <Input placeholder="Linkedin Profile" />
              </FormControl>
            </div>
            <FormControl mt={4}>
              <FormLabel fontWeight={500}>Employee Referral Code</FormLabel>
              <Input placeholder="Employee Referral Code" />
            </FormControl>
            <div className="mt-4">
              <FormLabel fontWeight={500}>Resume/CV*</FormLabel>
              <UploaderBox accept="image/*" />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="primary" mr={3}>
              Save
            </Button>
            <Button onClick={onCloseJob}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div>
        <div className={colorMode === "light" ? "bg-white" : ""}>
          <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-10">
            <div className="lg:flex md:flex block justify-between w-full items-center ">
              <h4
                id="2012666979"
                className="dynamicStyle fsize34 md-fsize24 sm-fsize21 font-semibold text-dark cust-textColor lg:mb-0 md:mb-0 mb-3"
              >
                {data ? data["2012666979"] : "Browse open position"}
              </h4>
            </div>
            {load ? (
              <SkeletonCareer />
            ) : (
              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:mt-8 md:mt-5 mt-4 gap-6">
                {getJobPostingData &&
                getJobPostingData.result &&
                getJobPostingData.result.length > 0
                  ? getJobPostingData.result.map((career, index) => {
                      return (
                        <Card
                          key={index}
                          className={
                            colorMode === "light"
                              ? "bg-white lg:p-6 md:p-6 p-4 rounded-lg cust-shadow themeShadow theme-bg"
                              : "bg-white lg:p-6 md:p-6 p-4 rounded-lg cust-shadow themeShadow theme-bg"
                          }
                        >
                          <h6 className="fsize20 md-fsize17 sm-fsize17 font-medium cust-textColor lg:h-16">
                            {career.jobtitle}
                          </h6>
                          <p className="mt-1 fsize14 sm-fsize13 text-primary font-medium textColor-light">
                            {career.dptname}
                          </p>
                          <p className="mt-3 fsize14 sm-fsize13 text-gray cust-textColor">
                            {career.joblocation}
                          </p>
                          <div className="w-full flex gap-1 mt-4">
                            {/* <Button
                            className={
                              colorMode === "light"
                                ? "w-full fsize14 bg-primary clr-fff border-1px buttonAnimate"
                                : "w-full fsize14 border-1px cust-textColor"
                            }
                            onClick={onOpenJob}
                          >
                            Apply
                          </Button> */}
                            <Button
                              onClick={() => {
                                navigate(
                                  `/career-details?cd=${encodeURI(
                                    career.jobtitle
                                  )}`
                                );
                              }}
                              className={
                                colorMode === "light"
                                  ? "w-full fsize14 bg-primary clr-fff border-1px buttonAnimate"
                                  : "w-full fsize14 border-1px cust-textColor"
                              }
                            >
                              Know More
                            </Button>
                          </div>
                        </Card>
                      );
                    })
                  : "no-career"}
              </div>
            )}

            <div className="text-center mt-9 textColor-light">
              <p
                className="dynamicStyle fsize18 md-fsize16 sm-fsize15 text-gray textColor-light"
                id="3893106965"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["3893106965"]
                    : "Hang on !! Couldn't find an appropiate match for your profile?",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersSection;
