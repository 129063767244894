import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/exports";
import { useSearchParams, useNavigate } from "react-router-dom";
import UploaderBox from "../../../components/UploaderBox";
import { getSingleJobPosting } from "../../../redux/careers/careers-action";
import {
  FaBriefcase,
  FaClock,
  FaMap,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import axios from "axios";
import { useForm } from "react-hook-form";
const CareersDetails2 = () => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const careerData = useSelector((state) => state.careers.SingleJobPosting);
  const [selectedFile, setSelectedFile] = useState(null);

  console.log(selectedFile, "selectedFile");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  console.log(watch("formshow"));
  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      setValue("resume", file); // Set the selected file as the 'resume' field value
    }
  };
  const onSubmit = async (data) => {
    try {
      // const payload = {
      //   ...data, // Use data directly from onSubmit function
      // };
      // console.log(data, "datalog");

      let payload = new FormData();
      if (data.firstName) payload.append("firstName", data.firstName);
      if (data.lastName) payload.append("lastName", data.lastName);
      if (data.phone) payload.append("mobile", data.phone);
      if (data.email) payload.append("email", data.email);

      if (data.location) payload.append("location", data.location);
      if (data.linkedin) payload.append("linkedinProfile", data.linkedin);

      if (data.referral) payload.append("ReferralCode", data.referral);
      if (selectedFile) payload.append("resume", selectedFile);

      let data1 = await axios.post(
        `${process.env.REACT_APP_BASE_URL}custom/applyjob/create`,
        payload,
        {
          headers: {
            "community-name": process.env.REACT_APP_COMMUNITY_NAME,
          },
        }
      );
      console.log(data1, "datalog");
      if (data1 && data1.data.code == 200) {
        Swal.fire({
          title: "Submission Successful!",
          text: `Thank you for submitting your apllication. Our team will review your information and get back to you soon. We appreciate your interest in joining our company!`,
          icon: "success",
          confirmButtonColor: "#2bc370",
        });
        reset();
      } else {
        Swal.fire({
          title: "error",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonColor: "#2bc370",
        });
      }

      // console.log(response.data); // Log response or handle success
    } catch (error) {
      console.error("Error submitting form:", error); // Handle error
    }
  };
  const getQueryData = async () => {
    let careerJobTitle = searchParams.get("cd");
    let payload = {
      columnFilter: "jobtitle",
      searchBy: careerJobTitle,
    };
    await dispatch(getSingleJobPosting(payload));
    console.log(careerData, "careerData");
    // console.log(careerData, "careerData");
    // let filtercareer = careerData.result.filter(
    //   (cs) => cs.jobtitle === careerJobTitle
    // );
    // console.log(filtercareer[0], "filtercareer");

    // dispatch(setSelectedCareer(filtercareer[0]));
  };
  useEffect(() => {
    getQueryData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value); // Update form value
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const formData = new FormData();
  formData.append("file", selectedFile);

  return (
    <section>
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex lg:gap-4">
          <div className="lg:w-2/3 md:w-2/3 w-full lg:pr-8 md:pr-8">
            <h2 className="fsize34 md:fsize28 sm-fsize20 text-black cust-textColor font-semibold">
              {careerData?.jobtitle}
            </h2>
            <div className="flex items-center gap-2 mt-1">
              <FaMapMarkerAlt />
              <p className=" fsize18 md-fsize18 sm-fsize14 text-black  font-medium cust-textColor">
                {careerData?.joblocation}
              </p>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <FaBriefcase />
              <p
                className="fsize18 md-fsize18 sm-fsize14 text-black mt-1 font-medium cust-textColor"
                dangerouslySetInnerHTML={{
                  __html: careerData?.["experience "],
                }}
              >
                {/* {careerData?.dptname} */}
              </p>
            </div>

            {/* <div className="flex gap-2 mt-3">
                <p
                  dangerouslySetInnerHTML={{
                    __html: careerData?.["experience "],
                  }}
                  className="fsize16 md-fsize16 sm-fsize12 font-medium   text-black textColor-light"
                ></p>
              </div> */}
            <div className="flex items-center gap-2 mt-2">
              <FaClock />
              <p
                dangerouslySetInnerHTML={{
                  __html: careerData?.jobtype,
                }}
                className="fsize16 md-fsize16 sm-fsize12 font-medium   text-black textColor-light"
              ></p>
            </div>

            <div className="mt-6">
              <p className="fsize18 md-fsize18 sm-fsize16 font-semibold">
                Roles & Responsibilities:
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: careerData?.responsibilities,
                }}
                className="fsize16 sm-fsize12 mt-1 text-black textColor-light"
              ></p>
            </div>
            <div className="mt-6">
              <p className="fsize18 md-fsize18 sm-fsize16 font-semibold">
                Requirements:
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: careerData?.["requirements "],
                }}
                className="fsize16 sm-fsize12 mt-3 text-black textColor-light"
              ></p>
            </div>
          </div>
          <div
            className={
              colorMode === "light"
                ? "lg:w-1/3 md:w-1/3 w-full px-4 pb-4 border1 rounded-lg lg:mt-0 md:mt-0 mt-8 themeShadow theme-bg"
                : "lg:w-1/3 md:w-1/3 w-full px-4 pb-4 border1 rounded-lg lg:mt-0 md:mt-0 mt-8 themeShadow theme-bg"
            }
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel
                  mt={4}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  First Name*
                </FormLabel>
                <Input
                  id="firstName"
                  {...register("firstName", {
                    required: "Name is required",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: `Only alphabets is allowed`,
                    },
                  })}
                  onChange={handleInputChange}
                  placeholder="First name"
                  className="md-fsize14 sm-fsize12"
                />

                {errors.firstName && (
                  <p className="text-danger  fsize12 pt-2">
                    {" "}
                    {errors.firstName.message}
                  </p>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Last Name*
                </FormLabel>
                <Input
                  id="lastName"
                  {...register("lastName", {
                    required: "Last Name is required",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: `Only alphabets is allowed`,
                    },
                  })}
                  onChange={handleInputChange}
                  placeholder="Last name"
                  className="md-fsize14 sm-fsize12"
                />{" "}
                {errors.lastName && (
                  <p className="text-danger  fsize12 pt-2">
                    {errors.lastName.message}
                  </p>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Phone*
                </FormLabel>
                <Input
                  type="tel"
                  id="phone"
                  {...register("phone", {
                    required: "Phone is required",
                    pattern: {
                      value: /^\d{10}$/,
                      message: `Phone number is invalid`,
                    },
                  })}
                  onChange={handleInputChange}
                  placeholder="Phone"
                  className="md-fsize14 sm-fsize12"
                />
                {errors.phone && (
                  <p className="text-danger  fsize12 pt-2">
                    {errors.phone.message}
                  </p>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Email*
                </FormLabel>
                <Input
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: `email is invalid`,
                    },
                  })}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="md-fsize14 sm-fsize12"
                />
                {errors.email && (
                  <p className="text-danger  fsize12 pt-2">
                    {errors.email.message}
                  </p>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Location*
                </FormLabel>
                <Input
                  onChange={handleInputChange}
                  id="location"
                  {...register("location", {
                    required: "Location is required",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: `Only alphabets is allowed`,
                    },
                  })}
                  placeholder="Location"
                  className="md-fsize14 sm-fsize12"
                />
                {errors.location && (
                  <p className="text-danger  fsize12 pt-2">
                    {errors.location.message}
                  </p>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Linkedin Profile
                </FormLabel>
                <Input
                  onChange={handleInputChange}
                  id="linkedin"
                  {...register("linkedin")}
                  placeholder="Linkedin Profile"
                  className="md-fsize14 sm-fsize12"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Employee Referral Code
                </FormLabel>
                <Input
                  onChange={handleInputChange}
                  id="referral"
                  {...register("referral")}
                  placeholder="Employee Referral Code"
                  className="md-fsize14 sm-fsize12"
                />
              </FormControl>
              <div className="mt-4">
                <FormLabel
                  fontWeight={500}
                  className="text-black cust-textColor md-fsize14 sm-fsize14"
                >
                  Resume/CV*
                </FormLabel>
                <div className="relative mt-4">
                  <input
                    id="resume"
                    {...register("resume", {
                      required: "Resume is required",
                    })}
                    className="absolute top-0 left-0  h-full opacity-0"
                    type="file"
                    onChange={handleFileChange}
                  />

                  <div className="flex items-center gap-2 cursor-pointer">
                    <FeatherIcon
                      icon="upload-cloud"
                      className="cursor-pointer text-dark"
                    />
                    <h2 className="text-primary font-semibold">Upload</h2>
                  </div>
                  <p className="fsize14 text-gray mt-2">
                    {selectedFile && selectedFile.name}
                  </p>
                </div>
                {errors.resume && (
                  <p className="text-danger  fsize12 pt-2">
                    {errors.resume.message}
                  </p>
                )}
              </div>
              <div className="mt-6 text-center">
                <Button
                  type="submit"
                  px="8"
                  className={
                    colorMode === "light"
                      ? "bg-primary clr-fff sm-fsize14 theme-btn2 themeShadow theme-bg"
                      : "clr-fff sm-fsize14 theme-btn2 themeShadow theme-bg"
                  }
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersDetails2;
