import React, { useEffect, useRef, useState } from "react";
// import 'swiper/css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules';
import { Image, Card } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import Marquee from "react-fast-marquee";
import axios from "axios";

const Clients1 = ({ pageName }) => {

  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  // const [getSchemaForm, setGetSchemaForm] = useState("");
  const clients = useSelector((state => state.clients.clients))
  console.log(clients, "clients")
  const data = useSelector((state) => state.cms.allSections[pageName]);
  // console.log(data["7724235249"], "fd");
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    return () => {
      cleanp1();
    };
  }, []);
  // useEffect(() => {
  //   const clientFetch = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}forms/get/clients`,
  //         {
  //           projectName: process.env.REACT_APP_COMMUNITY_NAME,
  //           isDeleted: false,
  //         },
  //         {
  //           headers: {
  //             "community-name": process.env.REACT_APP_COMMUNITY_NAME,
             
  //           },
  //         }
  //       );
  //       setClient(response.data.data);
  //       console.log(response.data.data, "ssss");
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   clientFetch();
  // }, []);

  return (
    <section>
      <div className="container mx-auto lg:py-8 md:py-8 py-8 lg:px-12 md:px-8 px-8">
        <div
          ref={elementRef}
          className={`fade-bottom ${isVisible ? "visiblebottom" : ""} `}
        >
          <div id="7724235249" className="dynamicStyle">
            <Marquee id="7724235249" loop={0} autoFill={true}>
              {clients && clients.result && clients.result.length > 0
                ? clients?.result.map((e, i) => {
                    return (
                      <Card key={i}>
                        <div>
                          <Image
                            src={`${process.env.REACT_APP_STORAGE_URL}${e.image}`}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            className="rounded-md object-contain client-w"
                          />
                        </div>
                      </Card>
                    );
                  })
                : "no-data"}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients1;
