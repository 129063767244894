import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Card, Image, useColorMode } from "@chakra-ui/react";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { useDispatch } from "react-redux";
import { getAllCaseStudy } from "../../../redux/caseStudy/cs-action";
const AboutSection38 = ({ pageName }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const caseStudyData = useSelector((state) => state.caseStudy.CaseStudy);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    // dispatch(getAllCaseStudy());
    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container mx-auto mx-auto md:px-8 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="pb-8">
        <h3
          id="9166555862"
          className="dynamicStyle fsize20 font-semibold text-primary"
        >
          {data ? data["9166555862"] : "Lorem Ipsum"}
        </h3>
        <h4 id="4715844610" className="dynamicStyle fsize32 ">
          {data ? data["4715844610"] : "Lorem Ipsum"}
        </h4>
      </div>
      {caseStudyData
        ? caseStudyData.result.map((e) => {
            return (
              <div className="h-400px">
                <Image
                  src={`${process.env.REACT_APP_STORAGE_URL}${e.flowchart}`}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="h-400px w-full object-contain"
                />
              </div>
            );
          })
        : "no-data"}

      <div className="flex gap-6 mt-10">
        <div className="w-1/2 cust-shadow flex gap-4  items-center p-4 rounded-lg">
          <div id="0503987346" className="dynamicStyle2 w-16 h-16">
            <Image
              src={data ? getImage(data["0503987346"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div>
            <p id="7778017586" className="dynamicStyle text-primary">
              {data ? data["7778017586"] : "Lorem Ipsum"}
            </p>
            <p id="4742320532" className="dynamicStyle">
              {data ? data["4742320532"] : "Lorem Ipsum"}
            </p>
          </div>
        </div>
        <div className="w-1/2 cust-shadow flex gap-4  items-center p-4 rounded-lg">
          <div id="3917601556" className="dynamicStyle2 w-16 h-16">
            <Image
              src={data ? getImage(data["3917601556"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div>
            <p id="5853968639" className="dynamicStyle text-primary">
              {" "}
              {data ? data["5853968639"] : "Lorem Ipsum"}
            </p>
            <p id="8122230399" className="dynamicStyle">
              {data ? data["8122230399"] : "Lorem Ipsum"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection38;
