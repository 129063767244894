import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Image, Button, useColorMode } from '@chakra-ui/react';

const Services1 = () => {
    const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div 
    className={
        colorMode === 'light'
          ? 'container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 theme-bg'
          : 'container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
      }>
      <div className='text-center lg:mb-16 md:mb-12 mb-8'>
        <p className="fsize40 md-fsize32 sm-fsize20 font-semibold text-primary pb-2">
          Services
        </p>
        <hr className='hr-blg' />
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-10 md:gap-8 gap-6">
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Services1;
