import { Button, Image } from '@chakra-ui/react';
import React from 'react';

const AddBanner7 = () => {
  return (
    <div className="">
      <div className="h-350px sm-h250px relative w-full">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          alt="img"
          className="h-350px sm-h250px w-full"
        />
        <div className="absl7-text h-350px sm-h250px flex items-center">
          <div>
          <p className="clr-fff fsize40 md-fsize32 sm-fsize20 font-semibold">
            Lorem ipsum dolor sit amet.
          </p>
          <Button
            width="180px"
            height="50px"
            className="bg-primary cust-bgColor lg:mt-4 md:mt-4 mt-2 textwhite sm-btntextwh bdr-2px"
            id=""
          >
            Book Appointment
          </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner7;
