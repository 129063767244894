import React, { useEffect, useRef, useState } from "react";
// import cardBG from "../../../../assets/images/CardBg.svg";
import { Image, CardBody, Card, useColorMode } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { handleScroll } from "../../components/fadeeffect/FadeEffect";

const CaseStudyCard = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      {" "}
      <div className=" container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:w-2/3 ms:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
          <h2
            id="4745715827"
            className={
              colorMode === "light"
                ? "dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold mb-2 text-priamry"
                : "dynamicStyle fsize34 md-fsize24 sm-fsize20 text-white font-semibold mb-2 cust-textColor"
            }
          >
            {data ? data["4745715827"] : "HOW FIT2GOASIA HELP YOU"}
          </h2>
          <p
            id="2752292551"
            className="dynamicStyle fsize16 sm-fsize14 textColor-light"
          >
            {data
              ? data["2752292551"]
              : "Tailored Programs to Meet Your Unique Goals"}
          </p>
        </div>
        <div id="6326492275" className="dynamicStyle">
          <div
            ref={elementRef}
            className={`fade-bottom grid lg:grid-cols-3  md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-8 gap-6 ${
              isVisible ? "visiblebottom" : ""
            } `}
          >
            {data && data["6326492275"]
              ? data["6326492275"].map((e) => {
                  return (
                    <div className="relative">
                      {/* <Image
             src={cardBG}
             alt="image"
             className="cardbgset sm-none w-full"
           /> */}

                      <Card className=" cardimage theme_insertShadow theme-bg">
                        <CardBody>
                          <div className="lg:px-4 md:px-2 lg:py-8 md:py-6">
                            <h2 className="font-semibold text-center lg:text-2xl md:text-xl text-xl cust-textColor">
                              {e.field1}
                            </h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e.field2,
                              }}
                              className="text8787 textColor-light lg:line-clamp-5  md:line-clamp-5 lg:text-base md:text-base text-sm mt-4 lg:leading-7 md:leading-6 leading-6"
                            ></p>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })
              : "no-data"}
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyCard;
