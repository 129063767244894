import React, { useEffect, useRef, useState } from "react";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";

const HeroSection21 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="herosection21-img relative">
      <div id="7359342077" className="w-full dynamicStyle2">
        <Image
          src={data ? getImage(data["7359342077"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          className="herosection21-img object-cover "
        />
      </div>
      <div className="absolute top-0 left-0 w-full flex items-center justify-center herosection21-img text-center">
        <div
          ref={elementRef}
          className={`fade-bottom lg:px-12 md:px-8 px-5  ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <h3
            id="8239850905"
            className="cust-textColor fsize45 md-fsize40 sm-fsize28 font-semibold text-white dynamicStyle"
          >
            {data ? data["8239850905"] : "EDUCATE"}
          </h3>
          <p
            id="3557342361"
            dangerouslySetInnerHTML={{
              __html: data ? data["3557342361"] : "no-data",
            }}
            className="fsize18 md-fsize16 sm-fsize14 cust-textColor dynamicStyle"
          ></p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection21;
