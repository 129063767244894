import { Image } from '@chakra-ui/react';
import React from 'react';

const Addbanner6 = () => {
  return (
    <div className="pt-16 pb-28">
      <div className="h-350px relative w-full">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          alt="img"
          className="h-350px w-full"
        />
        <div className="absl6-text">
          <p className="clr-fff fsize20 sm-fsize16 font-semibold py-2 letter-spacing4px">
            Lorem Ipsum
          </p>
          <p className="pb-4 clr-fff fsize32 sm-fsize20 font-semibold">
            Lorem ipsum dolor sit amet.
          </p>
          <p className="clr-fff sm-fsize14">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quo
            reprehenderit commodi quam est id?
          </p>
        </div>
        <div className="grid grid-cols-4 lg:gap-6 md:gap-6 gap-2 lg:px-20 md:px-14 px-2 lg:w-3/4 w-full absl6-cards">
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-border-0">
            <div>
              <p className="fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">324+</p>
              <p className="fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
                Happy Traveller
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-border-0">
            <div>
              <p className="fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">324+</p>
              <p className="fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
                Happy Traveller
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-border-0">
            <div>
              <p className="fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">324+</p>
              <p className="fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
                Happy Traveller
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-border-0">
            <div>
              <p className="fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">324+</p>
              <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-black px-8 textColor-light">
                Happy Traveller
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addbanner6;
