import apis from "../../apis/client/Forms/case_Study";

export const getAllCaseStudy = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllCaseStudy(payload1);

      if (data) {
        dispatch(setCaseStudy(data.data));
        record = data;
        // dispatch(setCaseStudy(data.data.result[1]))
      }

      return record;
    } catch (error) {}
  };
};

export const getSingleCaseStudy = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllCaseStudy(payload1);

      if (data?.data?.result?.length > 0) {
        dispatch(setSingleCaseStudy(data?.data?.result[0]));
        record = data.data;
        // dispatch(setCaseStudy(data.data.result[1]))
      }

      return record;
    } catch (error) {}
  };
};

export const getAllPinCaseStudy = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isDeleted: false,
        limit: 10,
        offset: 0,
        paginated: true,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllPinCaseStudy(payload1);

      if (data) {
        dispatch(setPinCaseStudy(data.data));
        record = data;
        // dispatch(setCaseStudy(data.data.result[1]))
      }

      return record;
    } catch (error) {}
  };
};

export const getAllCaseStudyIndustries = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isDeleted: false,
        limit: 10,
        offset: 0,
        paginated: true,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllCaseStudyIndustries(payload1);

      if (data) {
        dispatch(setCaseStudtyIndustries(data.data));
        record = data;
        // dispatch(setCaseStudy(data.data.result[1]))
      }

      return record;
    } catch (error) {}
  };
};

export const setCaseStudtyIndustries = (data) => {
  return {
    type: "SET_ALL_CASE_STUDY_INDUSTRIES",
    payload: data,
  };
};

export const setPinCaseStudy = (data) => {
  return {
    type: "SET_ALL_PIN_CASE_STUDY",
    payload: data,
  };
};

export const setCaseStudy = (data) => {
  return {
    type: "SET_ALL_CASE_STUDY",
    payload: data,
  };
};
export const setSingleCaseStudy = (data) => {
  return {
    type: "SET_SINGLE_CASE_STUDY",
    payload: data,
  };
};

export const setSelectedCaseStudy = (data) => {
  return {
    type: "SET_SELECTED_CASE_STUDY",
    payload: data,
  };
};
