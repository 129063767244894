import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard27 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
      <div className="lg:w-10/12 md:w-10/12 w-full text-center mx-auto lg:mb-8 md:mb-8 mb-6">
        <h3 className="fsize32 sm-fsize20 font-light  pb-2 cust-textColor">
          LOREM <span className="font-semibold">IPSUM DOLOR</span>
        </h3>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-12 gap-6">
        <Card className="cursor-pointer">
          <div className="relative feature26 ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
            />

            <div className="flex justify-center feature27absolute">
              <div 
              className={
                colorMode === 'light'
                  ? 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg'
                  : 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 cust-bgColor rounded-t-md theme-bg'
              }
              >
                <p id="" className="fsize16 md-fsize13 sm-fsize13 text-gray textColor-light">
                  March 15, 2019
                </p>
                <div className="">
                  <p
                    id=""
                    className="fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2 font-semibold cust-textColor"
                  >
                    publishing and graphic design, Lorem ipsum is a placeholder
                    text
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="cursor-pointer">
          <div className="relative feature26 ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
            />

            <div className="flex justify-center feature27absolute">
              <div 
              className={
                colorMode === 'light'
                  ? 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg'
                  : 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 cust-bgColor rounded-t-md theme-bg'
              }
              >
                <p id="" className="fsize16 md-fsize13 sm-fsize13 text-gray textColor-light">
                  March 15, 2019
                </p>
                <div className="">
                  <p
                    id=""
                    className="fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2 font-semibold cust-textColor"
                  >
                    publishing and graphic design, Lorem ipsum is a placeholder
                    text
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="cursor-pointer">
          <div className="relative feature26 ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
            />

            <div className="flex justify-center feature27absolute">
              <div 
              className={
                colorMode === 'light'
                  ? 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg'
                  : 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 cust-bgColor rounded-t-md theme-bg'
              }
              >
                <p id="" className="fsize16 md-fsize13 sm-fsize13 text-gray textColor-light">
                  March 15, 2019
                </p>
                <div className="">
                  <p
                    id=""
                    className="fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2 font-semibold cust-textColor"
                  >
                    publishing and graphic design, Lorem ipsum is a placeholder
                    text
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FeatureCard27;
