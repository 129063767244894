import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeroSection30 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section>
      <div className="relative w-full h-500px">
        <div className="1546004139" id='dynamicStyle2'>
        <Image
           src={data ? getImage(data['1546004139']) : ''}
           fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full h-500px"
        />
        </div>
        <div className="h-500px flex flex-col justify-center lg:pl-20 md:pl-12 px-5 absolute top-0 left-0 lg:w-1/2 md:w-1/2 w-full">
          <p id='7375843169' className="dynamicStyle hero-header clr-fff">
          {data
                ? data['1192846446']
                : 'Experience the future of comfort and convenience at your fingertips.'}
          </p>
          <p className="fsize16 mt-2 clr-fff dynamicStyle"
          id='2742587260'
          dangerouslySetInnerHTML={{
            __html: data
              ? data['2742587260']
              : 'Experience top-notch detailing services for a pristine shine.',
          }}>
            
          </p>
          <div className="mt-8">
            <Button
              borderRadius={0}
              colorScheme="primary"
              variant="outline"
              fontWeight={400}
              className="border-primary text-primary"
            >
              Explore Services <FaLongArrowAltRight className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-2/3 bg-272b3f lg:py-6 lg:px-16 md:py-4 md:px-8 flex">
          <div className="w-1/3 flex flex-col justify-center">
            <p className="fsize24 font-semibold clr-fff">05</p>
            <p className="fsize16 clr-fff textColor-light">Lorem Ipsum</p>
          </div>
          <div className="verticalLine"></div>
          <div className="w-1/3 flex flex-col justify-center">
            <p className="fsize24 font-semibold clr-fff">05</p>
            <p className="fsize16 clr-fff textColor-light">Lorem Ipsum</p>
          </div>
          <div className="verticalLine"></div>
          <div className="w-1/3 flex flex-col justify-center">
            <p className="fsize24 font-semibold clr-fff">05</p>
            <p className="fsize16 clr-fff textColor-light">Lorem Ipsum</p>
          </div>
        </div>
        <div className="w-1/3 bg-444853 lg:py-6 lg:px-16 md:py-4 md:px-8">
          <div className="">
            <p className="fsize24 font-semibold clr-fff">Home Theatre</p>
            <p className="text-primary fsize16 flex items-center">
              Explore <FaLongArrowAltRight className="ml-2" />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection30;
