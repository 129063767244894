const initialState = {
  CaseStudy: [],
  SelectedCaseStudy: [],
  PinCaseStudy: [],
  CaseStudyIndustries: [],
  SingleCaseStudy: {},
};

const case_Study = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_CASE_STUDY_INDUSTRIES":
      return {
        ...state,
        CaseStudyIndustries: action.payload,
      };

    case "SET_SINGLE_CASE_STUDY":
      return {
        ...state,
        SingleCaseStudy: action.payload,
      };

    case "SET_ALL_CASE_STUDY":
      return {
        ...state,
        CaseStudy: action.payload,
      };

    case "SET_SELECTED_CASE_STUDY":
      return {
        ...state,
        SelectedCaseStudy: action.payload,
      };

    case "SET_ALL_PIN_CASE_STUDY":
      return {
        ...state,
        PinCaseStudy: action.payload,
      };

    default:
      return state;
  }
};

export default case_Study;
