import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux/es/exports";

import { debounce } from "lodash";
import { openAiClient } from "./OpenAiClient";
import {
  setConversationHistory,
  setRecognizedText,
  setuseGpt,
  setRecognizer,
  setInputText,
  setisGPTResponseReceived,
} from "../../../../../redux/Aiassist/Aiassist-actions";
import getCurrentTime from "./getCurrentTime";
const GetEmotion = async (gptResponse) => {
  try {
    const completions = await openAiClient.getChatCompletions(
      process.env.REACT_APP_OPEN_AI_DEPLOYMENT_NAME,

      [
        {
          role: "system",
          content: "You are Ai assistant",
        },
        {
          role: "user",
          content: `styles=[
                    "affectionate",
                    "angry",
                    "calm",
                    "chat",
                    "cheerful",
                    "depressed",
                  
                    "embarassed",
                    "empathetic",
                    "envious",
                    "excited",
                    "fearful",
                    "friendly",
                    "gentle",
                    "hopeful",
                    "lyrical",
                    "sad",
                    "serious",
                    "shouting",
                    "whispering",
                    "terrified",
                    "unfriendly"
                ];
                for a input sentence pick one emotion from the above , give one word answer only.
                `,
        },
        {
          role: "user",
          content: "What makes a Dog sad?",
        },
        {
          role: "assistant",
          content: "sad",
        },
        {
          role: "user",
          content: "How Dare you?",
        },
        {
          role: "assistant",
          content: "angry",
        },
        {
          role: "user",
          content: gptResponse,
        },
      ]
    );

    const emotion = completions.choices[0].message.content;
    console.log(emotion, "emotion");
    return emotion;
  } catch (error) {
    console.error(error);
  }
};
export default GetEmotion;
