import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Card, Image, useColorMode } from "@chakra-ui/react";
import UserEffect from "../../components/usereeffect/UserEffect";
import { handleScroll } from "../../components/fadeeffect/FadeEffect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Magnifier from "react-magnifier";

const CaseStudyBanner = ({ pageName }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();

  const data = useSelector((state) => state.cms.allSections[pageName]);

  const selectedCaseStudyData = useSelector(
    (state) => state.caseStudy.SingleCaseStudy
  );

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, [dispatch]);

  return (
    <div className="container mx-auto mx-auto md:px-8 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="pb-8">
        <h3
          id="0076612502"
          className="dynamicStyle fsize20 font-semibold cust-textColor inline-block text-primary"
        >
          {data ? data["0076612502"] : "Lorem Ipsum"}
        </h3>
        <h4
          id="0007439253"
          className="dynamicStyle text-black font-semibold textColor-light fsize32 md:fsize24 sm-fsize18 "
          dangerouslySetInnerHTML={{
            __html: data ? data["0007439253"] : "Lorem ipsum generated",
          }}
        ></h4>
      </div>

      <div className="">
        <Magnifier
          src={`${process.env.REACT_APP_STORAGE_URL}${selectedCaseStudyData?.flowchart}`}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          zoomFactor={1}
          mgShowOverflow={false}
          className="w-full object-contain"
        />
      </div>

      <div className="lg:flex md:flex gap-6 mt-10">
        <Card className="lg:w-1/2 md:w-1/2 w-full cust-shadow">
          <div className="flex items-center p-4 rounded-lg">
            <div id="3900861184" className="dynamicStyle2 lg:w-1/6 w-1/4">
              <Image
                src={data ? getImage(data["3900861184"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="w-16 h-16 rounded-full object-cover"
              />
            </div>
            <div className="lg:w-5/6 w-3/4">
              <p
                id="8053209742"
                className="dynamicStyle cust-textColor text-primary"
              >
                {data ? data["8053209742"] : "Lorem Ipsum"}
              </p>
              <p
                id="8246973476"
                className="dynamicStyle textColor-light lineClamp1 text-black"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["8246973476"]
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
              ></p>
            </div>
          </div>
        </Card>

        <Card className="lg:w-1/2 md:w-1/2 w-full cust-shadow lg:mt-0 md:mt-0 mt-2">
          <div className=" flex items-center p-4 rounded-lg">
            <div id="8287282881" className="dynamicStyle2 lg:w-1/6 w-1/4">
              <Image
                src={data ? getImage(data["8287282881"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="w-16 h-16 rounded-full object-cover"
              />
            </div>
            <div className="lg:w-5/6 w-3/4">
              <p
                id="0657167498"
                className="dynamicStyle cust-textColor text-primary"
              >
                {" "}
                {data ? data["0657167498"] : "Lorem Ipsum"}
              </p>
              <p
                id="0527676610"
                className="dynamicStyle textColor-light lineClamp1 text-black"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["0527676610"]
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
              ></p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CaseStudyBanner;
