import React from "react";
import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { updateStore } from "../redux/cms/cms-actions";

import { Image, Input, Button, useDisclosure, Select } from "@chakra-ui/react";

import { debounce } from "lodash";
import axios from "axios";

import "react-light-accordion/demo/css/index.css";
import Dialog from "./Dialog";

import "swiper/css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// import Modal from 'react-modal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  getElementById,
  updateCms,
  updateSectionsCss,
  updateSections,
  updateImage,
  uploadImageToCloud,
  aiPrompt,
  mediaFetch,
  pixabaySearch,
  mediaUpload,
} from "../redux/cms/cms-actions";
import FeatherIcon from "feather-icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Typography from "./Typography";
//advance cropper
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

class ContentUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: props.isShow,
      handleShow: false,
      content: "",
      imageUrl: "",
      baseUrl: process.env.REACT_APP_STORAGE_URL,
      file: {},
      formData: new FormData(),
      css: "",
      sectionData: {},
      updateData: [],
      oldData: [],
      isShowTypo: false,
      selectedIndex: -1,
      tab: "General",
      contentTab: "Manual",
      mediaTab: "AdminImage",
      styleObj: {
        "font-size": "",
        color: "",
        "letter-spacing": "",
        "line-height": "",
        "font-weight": "",
      },
      styleString: "",
      aiPromptQuery: "",
      aiPromptResult: "",
      reset: false,
      searchTerm: "",
      // Web scraping
      url: "",
      Web_url: "",
      scraped_data: [],
      bool: false,
      loading: false,
      selectedIndex: "",
      contentType: "",
      myMedia: [],
      search: "",
      imageData: [],
      selectedMyMediaImageindex: "",
      selectedSearchImageindex: "",
      deviceMedia: {},
      deviceMediaUrl: "",
      loop: [],

      menuData: [],
      allPages: [],
      selectedOption: "",
      selectedMenuOption: "",
      selectedMenuData: "",
      showInput: false,
      filterURLData: [],
      isModalOpen: false,
      isOpen: false,
      size: "xl",
      croppedImage: "",
    };
    this.cropperRef = React.createRef();

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.onEdit = this.onEdit.bind(this);

    // Web scraping
    this.handleSearch = this.handleSearch.bind(this);
    this.handleUrl = this.handleUrl.bind(this);
    this.debouncedFunction = debounce(this.FetchedData, 800);
    this.FetchedData = this.FetchedData.bind(this);
    this.selectIndex = this.selectIndex.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  async componentDidMount() {
    this.handleFilterMenu();
    if (this.props.isShow) {
      let data = await this.props.dispatch(
        getElementById({
          selectedElement: this.props.selectedElement,
          pageName: this.props.pageName,
        })
      );

      if (data && data.length) {
        this.setState({
          sectionData: data[0],
        });
        if (data[0].contentType === "Image") {
          this.setState({
            imageUrl: this.state.baseUrl + data[0].content,
          });
          this.setState({ content: data[0].content });
        } else if (data[0].contentType === "Cards") {
          if (data[0].hasOwnProperty("loop") && data[0].loop.length) {
            let data1 = data[0].loop;
            let cards = [];
            for (let i = 0; i < data1.length; i++) {
              let cardElements = Object.keys(data1[i]);
              let elementIndex = 0;
              cardElements.forEach((x) => {
                if (parseInt(x.slice(-1)) > elementIndex)
                  elementIndex = parseInt(x.slice(-1));
              });
              let card = [];
              for (let j = 1; j <= elementIndex; j++) {
                let payload = {};

                payload["fieldValue"] = data1[i][`field${j}`];
                payload["type"] = data1[i][`fieldType${j}`];
                payload["id"] = data1[i][`fieldId${j}`];
                payload["link"] = data1[i][`fieldLink${j}`]
                  ? data1[i][`fieldLink${j}`]
                  : "";
                payload["loopLink"] = data1[i][`fieldLink${j}`] ? true : false;
                payload["newFile"] = false;
                payload["file"] = "";
                card.push(payload);
              }
              cards.push(card);
            }

            this.setState({ loop: cards });
          }
        } else {
          this.setState({ content: data[0].content });
        }
      }
    }
    await this.fetchMedia();
  }

  async componentDidUpdate() {
    console.log(this.state.selectedMenuData, "selected");
    console.log(this.props.menuData, "showmenu");
    console.log(this.state.allPages, "showPages");
    // if (!this.props.isShow && this.state.content) {
    //   await this.setState({ content: '' });
    // }
    // if (
    //   this.props.isShow &&
    //   !!this.props.selectedData &&
    //   !Object.keys(this.state.sectionData).length &&
    //   this.props.selectedElement
    // ) {
    //   console.log('fetch', this.props.selectedElement);
    //   await this.props.dispatch(getElementById(this.props.selectedElement));
    //   if (!!this.props.selectedData) {
    //     this.setState({ sectionData: this.props.selectedData });
    //   }
    //   if (!this.state.content) {
    //     this.setState({ content: this.props.selectedData.content });
    //     console.log(this.props, 'update state');
    //   }
    //   if (!this.state.imageUrl) {
    //     this.setState({
    //       imageUrl: this.state.baseUrl + this.props.selectedData.content,
    //     });
    //     console.log(this.props, 'update state');
    //   }
    // }
  }
  //<==================To display images================>
  getImage(image) {
    if (image?.includes("blob:")) {
      return image;
    } else {
      return this.state.baseUrl + image;
    }
  }

  //<==================For Single Input================>
  async handleButtonInput(event, index) {
    let newData = this.state.sectionData;

    newData[index].content = event.target.value;
    await this.setState({ sectionData: newData });

    // console.log(this.state.content);

    let payload = {
      section: newData[index].section,
      elementId: newData[index].fieldId,
      content: newData[index].content,
      pageName: newData[index].pageName,
    };
    await this.props.dispatch(updateSections(payload));
  }

  //<==================For Single Input================>
  async handleInput(event) {
    await this.setState({ content: event });
    let selectedData = this.state.sectionData;
    selectedData.content = event;

    await this.setState({ sectionData: selectedData });
    let payload = {
      section: this.state.sectionData.section,
      elementId: this.state.sectionData.fieldId,
      content: this.state.content,
      pageName: this.state.sectionData.pageName,
    };
    await this.props.dispatch(updateSections(payload));
  }
  //<==================For Single Css================>
  async handleCssInput(event, index) {
    let newData = this.state.sectionData;

    let cssData = event.target.value;
    // let splitCss = cssData.split(",");
    // let css = {};

    // let cssString = JSON.stringify(css);
    // console.log(cssString);
    newData[index].css = cssData;

    await this.setState({ sectionData: newData });
    // await this.setState({ css: event.target.value });
    // console.log(this.state.css);
    let payload = {
      section: newData[index].section,
      elementId: newData[index].fieldId,
      css: newData[index].css,
      pageName: newData[index].pageName,
    };
    await this.props.dispatch(updateSectionsCss(payload));
  }

  //<==================For Single TextArea================>
  async handleTextArea(event, index) {
    let newData = this.state.sectionData;

    newData[index].content = event.target.value;

    await this.setState({ sectionData: newData });

    // console.log(this.state.content);

    let payload = {
      section: newData[index].section,
      elementId: newData[index].fieldId,
      content: newData[index].content,
      pageName: newData[index].pageName,
    };
    await this.props.dispatch(updateSections(payload));
  }

  async handleCursorChange(event) {
    console.log(event.target.selectionStart, "cursor");
  }

  //<==================On Submit================>
  async onEdit() {
    // if (this.props.selectedData.contentType === "Image") {
    //   let payload = {
    //     _id: this.props.selectedData._id,
    //     path: this.props.selectedData.projectName + "/cms",
    //     projectName: this.props.selectedData.projectName,
    //     image: this.state.file,
    //     contentType: this.props.selectedData.contentType,
    //   };

    //   await this.props.dispatch(updateCms(payload));
    // } else {
    let payload = {
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      pageName: this.state.sectionData.pageName,
    };
    let data = [
      {
        _id: this.state.sectionData._id,
        content: this.state.sectionData.content,
        contentType: this.state.sectionData.contentType,
        css: this.state.sectionData.css,
        class: this.state.sectionData.class,
        classRef: this.state.sectionData.classRef,
        link: this.state.selectedMenuData,
        loopData: this.state.loop,
      },
    ];

    Object.assign(payload, { sections: JSON.stringify(data) });

    await this.props.dispatch(updateCms(payload));
    // }

    this.props.closeSidebar(false);
  }

  //<==================For Single Image================>
  async imageChange(event, index, elementId) {
    let newData = this.state.sectionData;

    let file = event.target.files[0];
    await this.setState({ file: file });
    let image = URL.createObjectURL(file);
    await this.setState({ imageUrl: image });

    newData[index].content = image;
    newData[index].file = file;

    await this.setState({ sectionData: newData });
    let payload = {
      section: newData[index].section,
      elementId: newData[index].fieldId,
      content: newData[index].content,
      pageName: newData[index].pageName,
    };
    let ImagePayload = {
      section: newData[index].section,
      elementId: newData[index].fieldId,
      file: newData[index].file,
      pageName: newData[index].pageName,
    };
    await this.props.dispatch(updateImage(ImagePayload));
    await this.props.dispatch(updateSections(payload));
  }

  //<==================For Single Image Upload================>
  async uploadImage(elementId, index) {
    let file = this.props.files[elementId];
    let payload = {
      file: file,
      elementId: elementId,
    };

    let data = await this.props.dispatch(uploadImageToCloud(payload));
    let newData = this.state.sectionData;
    newData[index].content = data[0];
    await this.setState({ sectionData: newData });
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  setisShowTypoFunc() {
    this.setState({
      styleString: this.state.sectionData.css ? this.state.sectionData.css : "",
    });
    let element = document.getElementById(this.state.sectionData.fieldId);
    let elementStyle = this.state.styleObj;
    elementStyle["font-size"] = window.getComputedStyle(element).fontSize;
    elementStyle["color"] = window.getComputedStyle(element).color;
    elementStyle["letter-spacing"] =
      window.getComputedStyle(element).letterSpacing;
    elementStyle["line-height"] = window.getComputedStyle(element).lineHeight;

    this.setState({ styleObj: elementStyle });
    this.setState({ isShowTypo: true });
  }

  selectContentForStyle(index) {
    this.setState({ selectedIndex: index });
    this.setState({ tab: "Style" });
  }
  defaultTab(index) {
    this.setState({ tab: "General" });
  }

  updateProps(value) {
    this.setState({ sectionData: value });
  }

  async handleCssInput2(event, index) {
    let newData = this.state.sectionData;

    let allStyle = [];
    allStyle = this.state.styleString.split(";");
    let stylingData = "";

    let newStyle = [];
    let RegexString = new RegExp(`^${index}`);
    // console.log(
    //   allStyle,
    //   RegexString,
    //   allStyle.some(x => RegexString.test(x)),
    //   'styleeee'
    // );

    let data = "";
    let postFix = "";
    let styleProperties = [
      "margin-top",
      "margin-right",
      "margin-left",
      "margin-bottom",
      "padding-left",
      "padding-right",
      "padding-top",
      "padding-bottom",
      "font-size",
      "letter-spacing",
      "line-height",
    ];
    if (styleProperties.includes(index)) {
      data = event.target.value;
      postFix = "px";
    } else if (index === "color") {
      data = event.target.value;
      postFix = "";
    } else {
      data = event;
      postFix = "";
    }

    if (allStyle.some((x) => RegexString.test(x))) {
      for (let i = 0; i < allStyle.length; i++) {
        if (allStyle[i] && RegexString.test(allStyle[i])) {
          // console.log(allStyle[i], 'for');
          allStyle[i] = index + ":" + data + postFix;
          // console.log(allStyle[i], 'for');
        }
      }
      stylingData = stylingData + allStyle.join(";");
    } else {
      if (styleProperties.includes(index)) {
        stylingData =
          this.state.styleString + index + ":" + event.target.value + "px;";
      } else if (index === "color") {
        data = event.target.value;
        postFix = ";";
      } else {
        stylingData = this.state.styleString + index + ":" + event + ";";
      }
    }

    let styles = this.state.styleObj;
    styles[index] = data;

    this.setState({ styleObj: styles });
    this.setState({ styleString: stylingData });
    // setStyleString(stylingData);

    newData.css = stylingData;
    this.setState({ sectionData: newData });

    let payload = {
      section: newData.section,
      elementId: newData.fieldId,
      css: stylingData,
      pageName: newData.pageName,
    };
    await this.props.dispatch(updateSectionsCss(payload));
  }

  async handleAiPrompt(event) {
    // console.log(event);
    this.setState({ aiPromptQuery: event });
  }
  async generateAI() {
    let data = await this.props.dispatch(aiPrompt(this.state.aiPromptQuery));

    // this.state.aiPromptResult=data[0]
    this.setState({ aiPromptResult: data.data[0] });
  }

  //<==================Web Scraping================>
  async handleUrl(event) {
    this.setState({ loading: true });
    this.setState({ Web_url: event.target.value });
    // this.debouncedFunction();
  }

  handleSearch(event) {
    this.setState({ searchTerm: event.target.value });
  }
  async FetchedData() {
    this.setState({ scraped_data: [] });
    let { data } = await axios.post(
      "https://web-scrapper.aiab.in/api/v1/Scrape",
      {
        url: this.state.Web_url,
      }
    );

    this.iterate_elements(data);
    this.setState({ loading: false });
  }

  iterate_elements(element) {
    const uniqueTexts = new Set(this.state.scraped_data.map((ele) => ele.text));

    if (element.text) {
      if (element.tagName === "div" && !uniqueTexts.has(element.text)) {
        this.setState((prevState) => ({
          scraped_data: [
            ...prevState.scraped_data,
            {
              id: Math.random(),
              pageName: "",
              text: element.text,
            },
          ],
        }));
      }
    }

    if (element.children) {
      element.children.forEach((child) => {
        this.iterate_elements(child);
      });
    }
  }
  highlightSubstring = (str, searchTerm) => {
    if (!searchTerm || !str) {
      return str; // No highlighting for empty search term or string
    }

    let lowerCaseStr = str.toLowerCase();
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    let index = lowerCaseStr.indexOf(lowerCaseSearchTerm);
    let result = [];

    while (index !== -1) {
      // Push the text before the substring occurrence
      result.push(str.substring(0, index));

      // Push the highlighted substring
      result.push(
        <span
          key={Math.random()}
          className="highlight"
          style={{
            backgroundColor: "yellow",
            fontWeight: "bold",
          }}
        >
          {str.substring(index, index + searchTerm.length)}
        </span>
      );

      // Update the string to move beyond the current substring
      str = str.slice(index + searchTerm.length);
      // Update the lowercase string for the remaining substring

      // Find the next occurrence of the search term in the remaining string
      //nextstart = index + searchTerm.length;

      index = str.toLowerCase().indexOf(lowerCaseSearchTerm);
    }

    // Push the remaining part of the string
    result.push(str);
    //console.log(result, 'index');
    return <React.Fragment>{result}</React.Fragment>;
  };
  renderParagraphs = () => {
    const { searchTerm } = this.state;

    // Use a Set to keep track of unique text elements
    const uniqueTextsSet = new Set();

    // Filter and map through scraped_data
    let filteredData = this.state.scraped_data
      .filter((item) => {
        // Check if the entire text (sentence) contains the searchTerm (case-insensitive)
        return item.text.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter((item) => {
        // Use Set to ensure unique text elements
        if (!uniqueTextsSet.has(item.text)) {
          uniqueTextsSet.add(item.text);
          return true;
        }
        return false;
      });

    return filteredData.map((item) => {
      const { id, text, pageName } = item;

      return (
        <div className="mb-3 cust-shadow rounded-md p-3" key={id}>
          <h6 className="text-primary mb-1 fsize14 font-semibold">
            {pageName}
          </h6>
          <div className="flex items-center">
            <p className="themefamily text-gray fsize12 w-11/12">
              {this.highlightSubstring(text, searchTerm)}
            </p>
            <div
              onClick={async () => {
                await this.setState({
                  content: text,
                });

                let payload = {
                  projectName: process.env.REACT_APP_COMMUNITY_NAME,
                };
                let data = [
                  {
                    _id: this.state.sectionData._id,
                    content: this.state.content,
                    contentType: this.state.sectionData.contentType,
                    css: this.state.sectionData.css,
                    class: this.state.sectionData.class,
                    classRef: this.state.sectionData.classRef,
                  },
                ];
                Object.assign(payload, { sections: JSON.stringify(data) });

                await this.props.dispatch(updateCms(payload));
              }}
              className="w-1/12 flex justify-end"
            >
              <div className="cursor-pointer rounded-full border-solid border-2 px-1">
                Add
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  selectIndex(index) {
    this.setState({ selectedIndex: index });

    this.handleInput(index);
  }
  aiText(aiGeneratedText) {
    this.handleInput(aiGeneratedText);
  }

  async fetchMedia() {
    let { data } = await this.props.dispatch(mediaFetch());
    // console.log(data);
    this.setState({ myMedia: data });
  }

  async getInput(e) {
    this.setState({ search: e.target.value });
  }

  async searchQuery() {
    let payload = {
      search: this.state.search,
      type: "photo",
    };
    let data = await this.props.dispatch(pixabaySearch(payload));

    this.setState({ imageData: data.hits });
  }

  async SaveUsingRemote(url, index) {
    this.setState({ selectedSearchImageindex: index });

    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const file = new File([blob], filename, { type: blob.type });
    await this.beforeUpload(file);
  }

  async beforeUpload(file) {
    let formData = new FormData();
    const fileType = file.type;
    formData.append("file", file);

    if (
      fileType.startsWith("image/jpeg") ||
      fileType.startsWith("image/png") ||
      fileType.startsWith("image/gif")
    ) {
      formData.append("type", "image");
    } else if (
      fileType.startsWith("video/mp4") ||
      fileType.startsWith("video/webm") ||
      fileType.startsWith("video/ogg")
    ) {
      formData.append("type", "video");
    } else if (fileType.startsWith("image/svg+xml")) {
      formData.append("type", "svg");
    } else {
      this.isShowDetailsAddons = false;

      return;
    }
    let URL = process.env.REACT_APP_BASE_URL.split("/api/")[0];

    formData.append("Storeendpoint", URL);
    let data = await this.props.dispatch(mediaUpload(formData));

    if (data) {
      this.fetchMedia();
      this.setState({ deviceMedia: "", deviceMediaUrl: "" });
    }
    return false;
  }

  async mediaFromDevice(e) {
    const file = e.target.files[0];
    this.setState({ deviceMedia: file });
    const url = URL.createObjectURL(file);
    this.setState({ deviceMediaUrl: url });
  }

  async addMediaFromDevice() {
    await this.beforeUpload(this.state.deviceMedia);
  }

  async removeMediaFromDevice() {
    this.setState({ deviceMedia: {}, deviceMediaUrl: "" });
  }

  async previewSelected(link, index) {
    this.setState({ selectedMyMediaImageindex: index });
    this.setState({
      content: `${process.env.REACT_APP_STORAGE_URL}${link}`,
    });
    let selectedData = this.state.sectionData;
    selectedData.content = link;

    this.setState({ sectionData: selectedData });
    let payload = {
      section: this.state.sectionData.section,
      elementId: this.state.sectionData.fieldId,
      content: link,
      pageName: this.state.sectionData.pageName,
    };
    await this.props.dispatch(updateSections(payload));
  }

  async updateLoop(cardIndex, itemIndex, content) {
    let loopData = this.state.loop;
    if (loopData[cardIndex][itemIndex].type === "Image") {
      let file = content.target.files[0];
      let image = URL.createObjectURL(file);
      loopData[cardIndex][itemIndex].fieldValue = image;
      loopData[cardIndex][itemIndex].file = file;
      loopData[cardIndex][itemIndex].newFile = true;
    } else {
      console.log(content, "man");

      if (content.hasOwnProperty("target")) {
        loopData[cardIndex][itemIndex].fieldValue = content.target.value;
      } else {
        loopData[cardIndex][itemIndex].fieldValue = content;
      }
    }

    this.setState({ loop: loopData });
    let updatedData = [];
    for (let i = 0; i < this.state.loop.length; i++) {
      let payload = {};
      for (let j = 0; j < this.state.loop[i].length; j++) {
        payload[`field${j + 1}`] = this.state.loop[i][j].fieldValue;
        payload[`fieldType${j + 1}`] = this.state.loop[i][j].type;

        if (this.state.loop[i][j].link)
          payload[`fieldLink${j + 1}`] = this.state.loop[i][j].link;

        payload[`fieldId${j + 1}`] = !this.state.loop[i][j].id;
      }
      updatedData.push(payload);
    }
    let payload = {
      section: this.state.sectionData.section,
      elementId: this.state.sectionData.fieldId,
      content: updatedData,
      pageName: this.state.sectionData.pageName,
    };
    await this.props.dispatch(updateSections(payload));
    this.setState({ loop: loopData });
  }

  async uploadLoopImage(cardIndex, itemIndex) {
    let payload = {
      file: this.state.loop[cardIndex][itemIndex]["file"],
    };
    let data = await this.props.dispatch(uploadImageToCloud(payload));
    if (data && data.length) {
      let loopData = this.state.loop;
      loopData[cardIndex][itemIndex].fieldValue = data[0];
      loopData[cardIndex][itemIndex].file = {};
      loopData[cardIndex][itemIndex].newFile = false;
      this.setState({ loop: loopData });
      let updatedData = [];
      for (let i = 0; i < this.state.loop.length; i++) {
        let payload = {};
        for (let j = 0; j < this.state.loop[i].length; j++) {
          payload[`field${j + 1}`] = this.state.loop[i][j].fieldValue;
          payload[`fieldType${j + 1}`] = this.state.loop[i][j].type;

          if (this.state.loop[i][j].link)
            payload[`fieldLink${j + 1}`] = this.state.loop[i][j].link;

          payload[`fieldId${j + 1}`] = !this.state.loop[i][j].id;
        }
        updatedData.push(payload);
      }
      let payload = {
        section: this.state.sectionData.section,
        elementId: this.state.sectionData.fieldId,
        content: updatedData,
        pageName: this.state.sectionData.pageName,
      };
      await this.props.dispatch(updateSections(payload));
      this.setState({ loop: loopData });
    }
  }
  selectedMenu = (event) => {
    this.setState({
      selectedMenuOption: event.target,
    });
    console.log(this.state.selectedMenuOption, "valecheck");
  };

  handleSelectPageType = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption == "Menu") {
      this.handleFilterMenu();
    }
    if (selectedOption == "Menu") {
    }

    this.setState({
      selectedOption,
      showInput: true,
    });
  };

  handleFilterMenu = (type) => {
    if (this.props.menuData && this.props.menuData.length > 0 && type) {
      console.log(this.props.menuData, type, "checkkkk");
      let filterData = this.props.menuData.filter(
        (item) => item.menutype === type.toLowerCase()
      );
      return this.setState({ filterURLData: filterData });
    }
    this.setState({ filterURLData: this.props.menuData });
  };

  setSelectedMenu = (e) => {
    console.log(e.target.value, "selected menu");
    this.setState({
      selectedMenuData: e.target.value,
    });
  };

  handleOpen = (link, index) => {
    this.setState({ selectedMyMediaImageindex: index });
    this.setState({ toCrop: link });
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ selectedMyMediaImageindex: null });
    this.setState({ isOpen: false });
  };
  handleCroperChange = () => {
    this.setState({
      croppedImage: this.cropperRef.current.getCanvas()?.toDataURL(),
    });
  };

  handleCroper = async () => {
    fetch(this.state.croppedImage)
      .then((re) => re.blob())
      .then(async (blob) => {
        const file = new File([blob], "cropped-image", {
          type: "image/jpeg",
        });

        console.log(file, "helper");
        await this.beforeUpload(file);
        await this.fetchMedia();
        this.handleClose();
      });
  };

  render() {
    const pageRoute = ["Select...", "Menu", "Pages", "URL"];
    const { isOpen, size } = this.state;

    const modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons

        ["blockquote", "code-block"],

        ["link", "image", "video", "formula"],

        [{ header: 1 }, { header: 2 }], // custom button values

        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

        [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown

        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme

        [{ font: [] }],

        [{ align: [] }],

        ["clean"],
      ],
    };

    const formats = [
      "font",
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      // "indent",
      "link",
      // "image",
      // "align",
      // "color",
      // "background",
    ];
    const { selectedData } = this.props;
    const {
      content,
      imageUrl,
      css,
      sectionData,
      isShowTypo,
      tab,
      selectedIndex,
      styleObj,
      handleShow,
      contentTab,
      mediaTab,
      aiPromptQuery,
      aiPromptResult,
      reset,
      scraped_data,
      myMedia,
      Web_url,
      loading,
      imageData,
      search,
      selectedMyMediaImageindex,
      selectedSearchImageindex,
      deviceMediaUrl,
      contentType,
      loop,
      isModalOpen,
      toCrop,
      croppedImage,
    } = this.state;
    const customStyle = {
      content: {
        width: "50%",
        height: "50%",
      },
    };
    //
    return (
      <div>
        <div>
          {handleShow ? (
            <>
              <div className="flex justify-center items-center popup fixed left-0">
                <div className="bg-white rounded-xl w-popup lg:py-6 md:py-6 lg:px-8 md:px-8 p-4 relative">
                  <Dialog />
                  <div className="absolute right-8 top-12">
                    <FeatherIcon
                      icon="x"
                      className="cursor-pointer"
                      onClick={() =>
                        this.setState({
                          handleShow: false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <Sidebar
          pullRight
          rootClassName="stop-abcall"
          overlayClassName="stop-abchild"
          sidebarClassName="sidebarclass sidebarfixcls"
          sidebar={
            <div className="sidebar-overlay">
              <div className="flexsetjustify px-20px">
                <h2 className="sideheader">
                  {isShowTypo ? "Typography" : "Content Management System "}
                </h2>

                {this.isShowTypo ? (
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      this.setState({ reset: true });
                      this.isShowTypo(false);
                    }}
                  >
                    <FeatherIcon icon="x" className="text-white" />
                  </p>
                ) : (
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      this.setState({ reset: true });
                      this.props.closeSidebar(false);
                    }}
                  >
                    <FeatherIcon icon="x" className="text-white" />
                  </p>
                )}
              </div>
              <Modal isOpen={isOpen} size={size} onClose={this.handleClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader className="flexsetjustify">
                    Image Upload
                  </ModalHeader>
                  <ModalCloseButton className="text-white" />
                  <ModalBody p={6}>
                    <Cropper
                      onChange={this.handleCroperChange}
                      src={this.getImage(toCrop)}
                      className="cropper height-20rem"
                      ref={this.cropperRef}
                    />
                    <div className="mt-2 flex gap-3">
                      <Button onClick={this.handleCroper}>Crop</Button>
                      <Button className="flexsetjustify">Cancel</Button>
                    </div>

                    {croppedImage && (
                      <div className="mt-8">
                        <h2 className="font-semibold fsize18 mb-4">
                          Cropped Image Preview
                        </h2>

                        <img
                          className="w-full"
                          src={croppedImage}
                          alt="Cropped Image"
                        />
                        <Button
                          className="mt-6"
                          px="8"
                          py="5"
                          colorScheme="primary"
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </ModalBody>
                </ModalContent>
              </Modal>
              <div className="modalcss-set modal-width">
                {/* {' '}
                <Modal isOpen={isModalOpen} style={customStyle}>
                  <Cropper
                    onChange={onChange}
                    src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                    className="cropper"
                  />
                  <div>
                    <p>hello</p>
                  </div>
                </Modal>{' '} */}
              </div>
              <div className=" ">
                {isShowTypo ? (
                  <div className="rounded-lg p-6 sidecomponent-scroll2">
                    <div className="p-6 bg-white rounded-lg">
                      <div className="flex items-center justify-between mb-6">
                        <h3 className="fsize16 font-semibold text-3d3b3b">
                          Font Family
                        </h3>
                        <select className="select-cust">
                          <option>Poppins</option>
                          <option>abcd</option>
                          <option>abcd</option>
                        </select>
                      </div>

                      <div className="flex items-center justify-between mb-6">
                        <h3 className="fsize16 font-semibold text-3d3b3b">
                          Font Weight{" "}
                          <Image
                            src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                            alt="image"
                            className="ml-2 w-5"
                          />
                        </h3>
                        <select className="select-cust">
                          <option>Default</option>
                          <option>abcd</option>
                          <option>abcd</option>
                        </select>
                      </div>

                      <div className="flex items-center justify-between mb-6">
                        <h3 className="fsize16 font-semibold text-3d3b3b">
                          Font Color
                        </h3>
                        <div className="flex justify-center items-center h-full">
                          <input
                            type="color"
                            placeholder="0"
                            className="h-12 rounded-md"
                            value={styleObj["color"]}
                            onChange={(event) => {
                              this.handleCssInput2(event, "color");
                            }}
                          />
                        </div>
                      </div>

                      <div className=" mb-6">
                        <div className="flex items-center justify-between ">
                          <h3 className="fsize16 font-semibold text-3d3b3b">
                            Font Size
                            <Image
                              src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                              alt="image"
                              className="ml-2 w-5"
                            />
                          </h3>
                          <p className="fsize14">px</p>
                        </div>
                        <div className="flex items-center justify-between ">
                          <div className="w-3/4 pr-4">
                            {" "}
                            <input
                              value={styleObj["font-size"]}
                              type="range"
                              min="0"
                              max="100"
                              className="w-full"
                              onChange={(event) => {
                                this.handleCssInput2(event, "font-size");
                              }}
                            />
                          </div>
                          <div className="w-1/4 mt-2">
                            <input
                              value={styleObj["font-size"]}
                              placeholder="0"
                              className="w-full rounded-4px input-lightgray"
                              onChange={(event) => {
                                this.handleCssInput2(event, "font-size");
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className=" mb-6">
                        <h3 className="fsize16 font-semibold text-3d3b3b">
                          Style
                        </h3>
                        <div className="grid grid-cols-3 rounded-lg mt-2 w-3/4">
                          <div className="p-3 cursor-pointer custborder-1 hoverbg-primary flex justify-center items-center h-full">
                            <FeatherIcon icon="bold" size={18} />
                          </div>
                          <div className="cursor-pointer  custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full">
                            <FeatherIcon icon="italic" size={18} />
                          </div>
                          <div className="cursor-pointer custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full">
                            <FeatherIcon icon="underline" size={18} />
                          </div>
                        </div>
                      </div>

                      <div className=" mb-6">
                        <div className="flex items-center justify-between ">
                          <h3 className="fsize16 font-semibold text-3d3b3b">
                            Letter Spacing
                            <Image
                              src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                              alt="image"
                              className="ml-2 w-5"
                            />
                          </h3>
                          <p className="fsize14">px</p>
                        </div>
                        <div className="flex items-center justify-between ">
                          <div className="w-3/4 pr-4">
                            {" "}
                            <input
                              type="range"
                              min="10"
                              max="100"
                              className="w-full"
                              value={styleObj["letter-spacing"]}
                              onChange={(event) => {
                                this.handleCssInput2(event, "letter-spacing");
                              }}
                            />
                          </div>
                          <div className="w-1/4 mt-2">
                            <input
                              placeholder="0"
                              className="w-full rounded-4px input-lightgray"
                              value={styleObj["letter-spacing"]}
                              onChange={(event) => {
                                this.handleCssInput2(event, "letter-spacing");
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className=" mb-6">
                        <div className="flex items-center justify-between ">
                          <h3 className="fsize16 font-semibold text-3d3b3b">
                            Line Height
                            <Image
                              src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                              alt="image"
                              className="ml-2 w-5"
                            />
                          </h3>
                          <p className="fsize14">px</p>
                        </div>
                        <div className="flex items-center justify-between ">
                          <div className="w-3/4 pr-4">
                            {" "}
                            <input
                              type="range"
                              min="5"
                              max="100"
                              className="w-full"
                              value={styleObj["line-height"]}
                              onChange={(event) => {
                                this.handleCssInput2(event, "line-height");
                              }}
                            />
                          </div>
                          <div className="w-1/4 mt-2">
                            <input
                              placeholder="0"
                              className="w-full rounded-4px input-lightgray"
                              value={styleObj["line-height"]}
                              onChange={(event) => {
                                this.handleCssInput2(event, "line-height");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Tabs
                    defaultTab={tab}
                    currentTab={tab}
                    onChange={(tabId) => {}}
                  >
                    <TabList className="bg-white border-0">
                      <Tab tabFor="General" onClick={() => this.defaultTab()}>
                        {" "}
                        General
                      </Tab>
                      <Tab tabFor="Style">Style</Tab>
                    </TabList>

                    <TabPanel tabId="General">
                      <div className="rounded-lg p-6 sidecomponent-scroll">
                        <div className="relative coll-bordernone">
                          <div className="p-6 bg-white rounded-lg">
                            <div>
                              {/* <h3 className="fsize16 font-semibold text-3d3b3b">
                                Text Editor
                              </h3> */}

                              <div>
                                <div>
                                  <div className=" w-full  ">
                                    <div className="flex items-center ">
                                      {" "}
                                      <p className="fsize18 font-semibold flex items-center">
                                        Content
                                      </p>
                                    </div>
                                    {sectionData.contentType === "Image" ? (
                                      <div className="gap-2  mt-8 flex w-full items-center h-full">
                                        <div></div>
                                        <Tabs
                                          defaultTab={mediaTab}
                                          currentTab={mediaTab}
                                          onChange={(tabId) => {}}
                                          className="w-full"
                                        >
                                          <TabList>
                                            <Tab
                                              tabFor="AdminImage"
                                              className="manual-ui"
                                            >
                                              {" "}
                                              Admin Images
                                            </Tab>
                                            <Tab tabFor="AIRecommadation">
                                              AI Recommendation
                                            </Tab>

                                            <TabPanel
                                              tabId="AdminImage"
                                              className="w-full"
                                            >
                                              <input
                                                value={content}
                                                onChange={(event) => {
                                                  this.handleInput(
                                                    event.target.value
                                                  );
                                                }}
                                                className="w-full rounded-4px fsize14 mt-4 input-lightgrayt"
                                              ></input>

                                              <div className="py-4">
                                                <div className="bg-f4 rounded-lg p-4 ">
                                                  <div className="lg:w-8/12"></div>
                                                  <div className="hei-define lg:mt-8 mt-4">
                                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                                      {myMedia.map(
                                                        (e, index) => {
                                                          return (
                                                            <div className="border relative">
                                                              <Image
                                                                alt="new"
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_STORAGE_URL +
                                                                  e.link
                                                                }
                                                                className={
                                                                  selectedMyMediaImageindex ===
                                                                  index
                                                                    ? "w-full green-overlay"
                                                                    : "w-full"
                                                                }
                                                                onClick={() => {
                                                                  this.previewSelected(
                                                                    e.link,
                                                                    index
                                                                  );
                                                                }}
                                                              />
                                                              <div className="absolute right-2 top-2 cursor-pointer">
                                                                <FeatherIcon
                                                                  icon="edit"
                                                                  className="text-brained"
                                                                  size={16}
                                                                  onClick={() =>
                                                                    this.handleOpen(
                                                                      e.link,
                                                                      index
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                              {/* <Button
                                                                onClick={
                                                                  this
                                                                    .handleOpen
                                                                }
                                                              >
                                                                Open Modal
                                                              </Button> */}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </TabPanel>
                                            <TabPanel tabId="AIRecommadation">
                                              {" "}
                                              <div className="p-4">
                                                <div className="bg-f4 rounded-lg p-4  ">
                                                  <div className="flex  justify-betweem w-full gap-2">
                                                    <div className="w-1/2 ">
                                                      <div className=" relative">
                                                        <input
                                                          placeholder="Admin Images..."
                                                          className="w-full p-3 border bg-white rounded-lg remove-outline fsize14"
                                                          value={search}
                                                          onChange={(e) => {
                                                            this.getInput(e);
                                                          }}
                                                        />
                                                        <div className="absolute top-0 right-0 m-3">
                                                          <FeatherIcon
                                                            icon="search"
                                                            className="svgicon-gray cursor-pointer"
                                                            onClick={() => {
                                                              this.searchQuery();
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="w-1/2">
                                                      {" "}
                                                      <Button className="cms-mediabtn py-3 w-full relative">
                                                        <FeatherIcon
                                                          size={24}
                                                          icon="upload-cloud"
                                                          className="text-gray cursor-pointer text-white mx-auto"
                                                        />{" "}
                                                        <p>Select from media</p>
                                                        <input
                                                          type="file"
                                                          className="absolute top-0 left-0 w-full h-full opacity-0 "
                                                          onChange={(e) => {
                                                            this.mediaFromDevice(
                                                              e
                                                            );
                                                          }}
                                                        />
                                                      </Button>{" "}
                                                    </div>
                                                  </div>
                                                  {deviceMediaUrl ? (
                                                    <div className="mt-8">
                                                      <Image
                                                        src={deviceMediaUrl}
                                                        alt="Image"
                                                      />{" "}
                                                      <div className="mt-4 text-right">
                                                        <Button
                                                          colorScheme="primary"
                                                          onClick={() => {
                                                            this.addMediaFromDevice();
                                                          }}
                                                        >
                                                          Upload
                                                        </Button>{" "}
                                                        <Button
                                                          colorScheme="secondary"
                                                          onClick={() =>
                                                            this.removeMediaFromDevice()
                                                          }
                                                        >
                                                          {" "}
                                                          Cancel
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <div className="hei-define lg:mt-8 mt-4">
                                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                                      {imageData.map(
                                                        (e, index) => {
                                                          return (
                                                            <div className="border">
                                                              <Image
                                                                alt="new"
                                                                src={
                                                                  e.largeImageURL
                                                                }
                                                                className={
                                                                  selectedSearchImageindex ===
                                                                  index
                                                                    ? "w-full green-overlay"
                                                                    : "w-full"
                                                                }
                                                                onClick={() => {
                                                                  this.SaveUsingRemote(
                                                                    e.largeImageURL,
                                                                    index
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </TabPanel>
                                          </TabList>
                                        </Tabs>
                                      </div>
                                    ) : (
                                      <div className="gap-2  flex w-full items-center h-full mt-4">
                                        <Tabs
                                          defaultTab={contentTab}
                                          currentTab={contentTab}
                                          onChange={(tabId) => {}}
                                          className="w-full"
                                        >
                                          <TabList>
                                            <Tab
                                              tabFor="Manual"
                                              className="manual-ui"
                                            >
                                              {" "}
                                              Manual
                                            </Tab>
                                            <Tab tabFor="AI"> AI Prompt</Tab>
                                            <Tab tabFor="Web scrab">
                                              {" "}
                                              Web Scraper
                                            </Tab>

                                            <TabPanel
                                              tabId="Manual"
                                              className="w-full"
                                            >
                                              {(sectionData &&
                                                sectionData.contentType &&
                                                sectionData.contentType ===
                                                  "Text") ||
                                              sectionData.contentType ===
                                                "Button" ? (
                                                <div className="mt-4">
                                                  <input
                                                    value={content}
                                                    onChange={(event) => {
                                                      this.handleInput(
                                                        event.target.value
                                                      );
                                                    }}
                                                    className="w-full rounded-4px fsize14  input-lightgrayt"
                                                  ></input>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {sectionData &&
                                              sectionData.contentType &&
                                              sectionData.contentType ===
                                                "Button" ? (
                                                <div className="mt-4">
                                                  <div className="mb-4">
                                                    <h4 className="fsize14 font-medium mb-1">
                                                      {" "}
                                                      Select Redirection Option
                                                    </h4>
                                                    <Select
                                                      className="fsize14"
                                                      onChange={(event) => {
                                                        this.handleSelectPageType(
                                                          event
                                                        );
                                                        this.handleFilterMenu(
                                                          event.target.value
                                                        );
                                                      }}
                                                    >
                                                      {pageRoute.map((e) => {
                                                        return (
                                                          <option value={e}>
                                                            {e}
                                                          </option>
                                                        );
                                                      })}
                                                    </Select>
                                                  </div>

                                                  {this.state.selectedOption ===
                                                    "Menu" && (
                                                    <div className="mb-4">
                                                      <h4 className="fsize14 font-medium mb-1">
                                                        Menu
                                                      </h4>

                                                      <Select
                                                        className="fsize14"
                                                        value={
                                                          this.state
                                                            .selectedMenuData
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            selectedMenuData:
                                                              e.target.value,
                                                          });
                                                        }}
                                                      >
                                                        {this.props.menuData.map(
                                                          (e) => {
                                                            return (
                                                              <option>
                                                                {e.menuName}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </Select>
                                                    </div>
                                                  )}

                                                  {/* allpages */}

                                                  {this.state.selectedOption ===
                                                    "Pages" && (
                                                    <div className="mb-4">
                                                      <h4 className="fsize14 font-medium mb-1">
                                                        Pages
                                                      </h4>

                                                      <Select
                                                        className="fsize14"
                                                        value={
                                                          this.state
                                                            .selectedMenuData
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            selectedMenuData:
                                                              e.target.value,
                                                          });
                                                        }}
                                                      >
                                                        {this.props.allPages.map(
                                                          (e) => {
                                                            return (
                                                              <option
                                                                value={e.page}
                                                              >
                                                                {e.page}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </Select>
                                                    </div>
                                                  )}

                                                  {this.state.selectedOption ===
                                                    "URL" && (
                                                    <div className="mb-4">
                                                      <h4 className="fsize14 font-medium mb-1">
                                                        URL
                                                      </h4>
                                                      {/* <Select
                                                            className="fsize14"
                                                            value={
                                                              this.state
                                                                .selectedMenuData
                                                            }
                                                            onChange={e =>
                                                              this.setSelectedMenu(
                                                                e
                                                              )
                                                            }
                                                          >
                                                            {this.state.filterURLData.map(
                                                              e => {
                                                                return (
                                                                  <option
                                                                    value={
                                                                      e.menuData
                                                                    }
                                                                  >
                                                                    {e.menuName}
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                          </Select> */}

                                                      <input
                                                        type="text"
                                                        placeholder="https://www.example.com"
                                                        value={
                                                          this.state
                                                            .selectedMenuData
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            selectedMenuData:
                                                              e.target.value,
                                                          });
                                                        }}
                                                        className="inputcss fsize14"
                                                      ></input>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {sectionData &&
                                              sectionData.contentType &&
                                              sectionData.contentType ===
                                                "Textfield" ? (
                                                <ReactQuill
                                                  theme="snow"
                                                  className="mt-4 reactquill-h"
                                                  modules={modules}
                                                  formats={formats}
                                                  value={content}
                                                  onChange={(event) => {
                                                    this.handleInput(event);
                                                  }}
                                                />
                                              ) : (
                                                ""
                                              )}

                                              {sectionData &&
                                              sectionData.contentType &&
                                              sectionData.contentType ===
                                                "Cards" ? (
                                                <Swiper
                                                  spaceBetween={20}
                                                  modules={[Navigation]}
                                                  // className="mySwiper container relative lg:mx-auto md:mx-5  overflow-x-hidden"
                                                  autoplay={{
                                                    delay: "1000",
                                                  }}
                                                  navigation={{
                                                    nextEl:
                                                      ".image-swiper-button-next",
                                                    prevEl:
                                                      ".image-swiper-button-prev",
                                                  }}
                                                  className="mySwiper p-4"
                                                  breakpoints={{
                                                    1536: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 20,
                                                    },
                                                    1280: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 20,
                                                    },
                                                    1024: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 20,
                                                    },
                                                    991: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 20,
                                                    },
                                                    640: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 10,
                                                    },
                                                    425: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 10,
                                                    },
                                                    325: {
                                                      slidesPerView: 1.2,
                                                      spaceBetween: 10,
                                                    },
                                                  }}
                                                >
                                                  <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                                                    <svg
                                                      stroke="currentColor"
                                                      fill="currentColor"
                                                      stroke-width="0"
                                                      viewBox="0 0 512 512"
                                                      height="1em"
                                                      width="1em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                                                    </svg>
                                                  </div>
                                                  <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                                                    <svg
                                                      stroke="currentColor"
                                                      fill="currentColor"
                                                      stroke-width="0"
                                                      viewBox="0 0 512 512"
                                                      height="1em"
                                                      width="1em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                                                    </svg>
                                                  </div>
                                                  {loop.map(
                                                    (card, cardIndex) => {
                                                      console.log(
                                                        card,
                                                        "txtcard"
                                                      );
                                                      return (
                                                        <SwiperSlide className="remflex cust-shadow p-4 rounded-lg">
                                                          {card.map(
                                                            (
                                                              item,
                                                              itemIndex
                                                            ) => {
                                                              return (
                                                                <div className="w-full mb-2">
                                                                  {item &&
                                                                  item.type ===
                                                                    "Text" ? (
                                                                    <input
                                                                      placeholder="Enter..."
                                                                      value={
                                                                        item.fieldValue
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.updateLoop(
                                                                          cardIndex,
                                                                          itemIndex,
                                                                          e
                                                                        )
                                                                      }
                                                                      className="w-full rounded-4px fsize14  input-lightgrayt"
                                                                      rows="4"
                                                                      cols="100"
                                                                    ></input>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                  {item &&
                                                                  item.type ===
                                                                    "Textfield" ? (
                                                                    // <textarea
                                                                    //   placeholder="Enter..."
                                                                    //   value={fieldValue}
                                                                    //   onChange={this.handleTextArea}
                                                                    //   className="inputcss"
                                                                    //   rows="4"
                                                                    //   cols="50"
                                                                    // ></textarea>
                                                                    <ReactQuill
                                                                      theme="snow"
                                                                      value={
                                                                        item.fieldValue
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.updateLoop(
                                                                          cardIndex,
                                                                          itemIndex,
                                                                          e
                                                                        )
                                                                      }
                                                                      modules={
                                                                        modules
                                                                      }
                                                                      formats={
                                                                        formats
                                                                      }
                                                                      className="quill-height"
                                                                    />
                                                                  ) : (
                                                                    ""
                                                                  )}

                                                                  {item &&
                                                                  item.type ===
                                                                    "Link" ? (
                                                                    <input
                                                                      placeholder="Enter..."
                                                                      value={
                                                                        item.fieldValue
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.updateLoop(
                                                                          cardIndex,
                                                                          itemIndex,
                                                                          e
                                                                        )
                                                                      }
                                                                      className="inputcss1"
                                                                      rows="4"
                                                                      cols="100"
                                                                    ></input>
                                                                  ) : (
                                                                    ""
                                                                  )}

                                                                  {item &&
                                                                  item.type ===
                                                                    "Image" ? (
                                                                    <>
                                                                      <div className="relative">
                                                                        <img
                                                                          src={
                                                                            item.fieldValue.includes(
                                                                              "blob"
                                                                            )
                                                                              ? item.fieldValue
                                                                              : process
                                                                                  .env
                                                                                  .REACT_APP_STORAGE_URL +
                                                                                item.fieldValue
                                                                          }
                                                                          className="h-60 w-full"
                                                                          alt="images"
                                                                        />
                                                                        <div className="relative upload-ui">
                                                                          <div className="cursor-pointer flex items-center gap-2">
                                                                            <FeatherIcon
                                                                              icon="upload-cloud"
                                                                              size={
                                                                                20
                                                                              }
                                                                              className="flex "
                                                                            />
                                                                            <h2 className="font-semibold fsize16 ">
                                                                              {" "}
                                                                              Click
                                                                              here
                                                                              to
                                                                              Upload
                                                                            </h2>
                                                                          </div>

                                                                          <input
                                                                            type="file"
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              this.updateLoop(
                                                                                cardIndex,
                                                                                itemIndex,
                                                                                e
                                                                              )
                                                                            }
                                                                            className="left-0 h-full absolute opacity0  top-0 w-full border opactid"
                                                                          ></input>
                                                                        </div>
                                                                        {item.newFile ? (
                                                                          <div className="text-center mt-4">
                                                                            <Button
                                                                              px="12"
                                                                              py="4"
                                                                              colorScheme="green"
                                                                              className="updatebutton fsize16 sm-fsize12 cursor-pointer"
                                                                              onClick={() =>
                                                                                this.uploadLoopImage(
                                                                                  cardIndex,
                                                                                  itemIndex
                                                                                )
                                                                              }
                                                                            >
                                                                              Upload
                                                                            </Button>
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    ""
                                                                  )}

                                                                  {item &&
                                                                  item.type ===
                                                                    "Button" ? (
                                                                    <div>
                                                                      <input
                                                                        type="file"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.updateLoop(
                                                                            cardIndex,
                                                                            itemIndex,
                                                                            e
                                                                          )
                                                                        }
                                                                        className="h-60 w-full border opactid"
                                                                      ></input>
                                                                    </div>
                                                                  ) : (
                                                                    ""
                                                                  )}

                                                                  {item &&
                                                                  item.type ===
                                                                    "Button" ? (
                                                                    <div>
                                                                      <input
                                                                        type="text"
                                                                        value={
                                                                          item.fieldValue
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.updateLoop(
                                                                            cardIndex,
                                                                            itemIndex,
                                                                            e
                                                                          )
                                                                        }
                                                                        className="inputcss"
                                                                      ></input>
                                                                    </div>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </SwiperSlide>
                                                      );
                                                    }
                                                  )}
                                                </Swiper>
                                              ) : (
                                                ""
                                              )}
                                            </TabPanel>
                                            <TabPanel tabId="AI">
                                              <div className="mt-4 bg-grays p-3 border-ll">
                                                {" "}
                                                <Input
                                                  type="textarea"
                                                  autosize={{
                                                    minRows: 2,
                                                    maxRows: 4,
                                                  }}
                                                  placeholder="Please input"
                                                  className="border-none cmstextarea"
                                                  value={aiPromptQuery}
                                                  onChange={(event) => {
                                                    this.handleAiPrompt(event);
                                                  }}
                                                />
                                                <div className="relative">
                                                  <Input
                                                    type="textarea"
                                                    autosize={{
                                                      minRows: 2,
                                                      maxRows: 4,
                                                    }}
                                                    placeholder="Give a prompt"
                                                    className="border-none cmscolor-bg mt-6 cmstextarea"
                                                    value={aiPromptResult}
                                                  />
                                                  <div
                                                    className="absolute right-2 bottom-2 flex justify-end"
                                                    onClick={() =>
                                                      this.aiText(
                                                        aiPromptResult
                                                      )
                                                    }
                                                  >
                                                    <p className="bg-brained textwhite cursor-pointer rounded-md px-4 py-1 fsize12">
                                                      Apply
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="flex gap-2 mt-4 items-center justify-end">
                                                  <button
                                                    className="cms-btnsuccess"
                                                    onClick={() =>
                                                      this.generateAI()
                                                    }
                                                  >
                                                    Generate Text{" "}
                                                  </button>

                                                  <button className="cms-btnrem">
                                                    Regenerate
                                                  </button>
                                                </div>
                                              </div>
                                            </TabPanel>
                                            <TabPanel
                                              tabId="Web scrab"
                                              className="w-full"
                                            >
                                              <div className="mt-4">
                                                {" "}
                                                <input
                                                  onChange={this.handleUrl}
                                                  value={Web_url}
                                                  placeholder="Enter url"
                                                  className=" rounded-4px input-lightgrayt mr-2"
                                                />
                                                <Button
                                                  className="bg-brained textwhite fsize16 sm-fsize14 cursor-pointer"
                                                  py={4}
                                                  px={8}
                                                  onClick={() => {
                                                    this.FetchedData();
                                                  }}
                                                >
                                                  Scrape
                                                </Button>
                                              </div>
                                              <div className="mb-2  ">
                                                <input
                                                  type="text"
                                                  placeholder="Keyword Search..."
                                                  value={this.searchTerm}
                                                  onChange={this.handleSearch}
                                                  className="w-full rounded-4px input-lightgrayt mt-3"
                                                />
                                              </div>

                                              <div className="cms-scrollbar p-2">
                                                {loading != false ? (
                                                  <div className="loader"></div>
                                                ) : (
                                                  this.renderParagraphs()
                                                )}
                                              </div>
                                            </TabPanel>
                                          </TabList>
                                        </Tabs>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <div>
                                <div>
                                  <div className="flex items-center w-full mt-10 px-1">
                                    <div className="flex items-center w-6/12">
                                      {" "}
                                      <p className="fsize14  flex items-center">
                                        Button Icon
                                      </p>
                                    </div>
                                    <div className="w-6/12  flex justify-end items-center h-full">
                                      <Switch
                                        value={true}
                                        onText=""
                                        offText=""
                                      ></Switch>
                                    </div>
                                  </div>
                                </div>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="p-2 mt-2 border rounded-lg w-full"
                                >
                                  <option>Grow</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </Form.Select>
                              </div>
                              <div className="mt-5">
                                <div className="flex  w-full mt-2 px-1">
                                  <div className="flex items-center">
                                    {" "}
                                    <p className="fsize14  flex items-center">
                                      Alignment{" "}
                                    </p>
                                    <Image
                                      src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                                      alt="image"
                                      className="ml-2 w-5"
                                    />
                                  </div>
                                </div>
                                <div className="grid grid-cols-5 rounded-lg mt-2">
                                  <div className="custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full ">
                                    <FeatherIcon icon="align-right" size={18} />
                                  </div>
                                  <div
                                    className="custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full "
                                    id="align-center"
                                  >
                                    <FeatherIcon
                                      icon="align-center"
                                      size={18}
                                    />
                                  </div>
                                  <div className="custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full">
                                    <FeatherIcon icon="align-left" size={18} />
                                  </div>
                                  <div className="custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full">
                                    <FeatherIcon
                                      icon="align-justify"
                                      size={18}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className="flex items-center w-full mt-10 px-1">
                                    <div className="flex items-center w-6/12">
                                      {" "}
                                      <p className="fsize14  flex items-center">
                                        Source
                                      </p>
                                    </div>
                                    <div className="w-6/12  flex justify-end items-center h-full">
                                      <div className="flex items-center border rounded-lg">
                                        <div className="tabs2 border-rr bg-primary">
                                          <p className="fsize14 text-white">
                                            Page
                                          </p>
                                        </div>
                                        <div className="tabs2 bg-transparent">
                                          <p className="fsize14 text-dark">
                                            link
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="p-2 mt-2 border rounded-lg w-full"
                                >
                                  <option>About Us</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </Form.Select>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel tabId="Style">
                      <Typography
                        sectionsData={this.props.selectedData}
                        updateProps={(value) => {
                          this.updateProps(value);
                        }}
                        reset={reset}
                        setisShowTypoFunc={(value) => this.setisShowTypoFunc()}
                      />
                    </TabPanel>
                  </Tabs>
                )}

                <div className="mt-3 py-4 px-8 bg-white flex justify-between fixed bottom-0 w-38per">
                  <div className="flex items-center justify-between w-1/3 pr-8">
                    <FeatherIcon
                      icon="info"
                      className="text-gray cursor-pointer mr-4"
                    />
                    <FeatherIcon
                      icon="rotate-ccw"
                      className="text-gray cursor-pointer mr-4"
                    />
                    <FeatherIcon
                      icon="eye"
                      className="text-gray cursor-pointer mr-4"
                    />
                  </div>
                  <div className="w-1/3">
                    <Button
                      className="bg-brained textwhite fsize16 w-full sm-fsize14 cursor-pointer"
                      py={4}
                      px={8}
                      onClick={() => {
                        this.onEdit();
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          open={this.props.isShow}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ sidebar: { background: "white" } }}
        ></Sidebar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (!!state.cms.selectedData) {
    return {
      selectedData: state.cms.selectedData,
      files: state.cms.files,
      menuData: state.menuState.menu,
      allPages: state.pages.Pages,
    };
  } else {
    return {
      selectedData: {},
    };
  }
};

export default connect(mapStateToProps)(ContentUpdate);
