import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Card, Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';

const FeatureCard24 = () => {
  return (
    <div className="">
      <div className="lg:py-12 md:py-12 py-6">
        <div className="">
          <Swiper
            spaceBetween={0}
            slidesPerView={4}
            observer={true}
            observeParents={true}
            className="mySwiper pad-none"
            autoplay={{
              delay: '1000',
            }}
            loop
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              991: {
                slidesPerView: 2.3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2.3,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
          >
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                  />
                  <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 text-white font-semibold text-center">
                        Lorem Ipsum Lorem
                      </h3>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle clr-fff cust-textColor fsize16 md-fsize14 sm-fsize14 text-center mt-3 lineClamp3"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text publishing and graphic design, Lorem
                          ipsum is a placeholder text
                        </p>
                        <div className="lg:w-10 mx-auto mt-4 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                          <FeatherIcon
                            icon="arrow-right"
                            className="clr-fff flex"
                            size="18"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                  />
                  <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 text-white font-semibold text-center">
                        Lorem Ipsum Lorem
                      </h3>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle clr-fff cust-textColor fsize16 md-fsize14 sm-fsize14 text-center mt-3 lineClamp3"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text publishing and graphic design, Lorem
                          ipsum is a placeholder text
                        </p>
                        <div className="lg:w-10 mx-auto mt-4 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                          <FeatherIcon
                            icon="arrow-right"
                            className="clr-fff flex"
                            size="18"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                  />
                  <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 text-white font-semibold text-center">
                        Lorem Ipsum Lorem
                      </h3>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle clr-fff cust-textColor fsize16 md-fsize14 sm-fsize14 text-center mt-3 lineClamp3"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text publishing and graphic design, Lorem
                          ipsum is a placeholder text
                        </p>
                        <div className="lg:w-10 mx-auto mt-4 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                          <FeatherIcon
                            icon="arrow-right"
                            className="clr-fff flex"
                            size="18"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                  />
                  <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 text-white font-semibold text-center">
                        Lorem Ipsum Lorem
                      </h3>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle clr-fff cust-textColor fsize16 md-fsize14 sm-fsize14 text-center mt-3 lineClamp3"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text publishing and graphic design, Lorem
                          ipsum is a placeholder text
                        </p>
                        <div className="lg:w-10 mx-auto mt-4 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                          <FeatherIcon
                            icon="arrow-right"
                            className="clr-fff flex"
                            size="18"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                  />
                  <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 text-white font-semibold text-center">
                        Lorem Ipsum Lorem
                      </h3>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle clr-fff cust-textColor fsize16 md-fsize14 sm-fsize14 text-center mt-3 lineClamp3"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text publishing and graphic design, Lorem
                          ipsum is a placeholder text
                        </p>
                        <div className="lg:w-10 mx-auto mt-4 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                          <FeatherIcon
                            icon="arrow-right"
                            className="clr-fff flex"
                            size="18"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                  />
                  <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 text-white font-semibold text-center">
                        Lorem Ipsum Lorem
                      </h3>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle clr-fff cust-textColor fsize16 md-fsize14 sm-fsize14 text-center mt-3 lineClamp3"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text publishing and graphic design, Lorem
                          ipsum is a placeholder text
                        </p>
                        <div className="lg:w-10 mx-auto mt-4 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                          <FeatherIcon
                            icon="arrow-right"
                            className="clr-fff flex"
                            size="18"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard24;
