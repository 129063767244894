import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  Slide,
  extendTheme,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
//router
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
//import components pages
// import Chat from './pages/allSections/components/AiSubComp/Chat.jsx';
import Sections from "./pages/allSections/Sections";
import { otherLogin, getUserByToken } from "./redux/auth/auth-action.js";
import Master from "./pages/allSections/Master";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { getProject } from "./redux/userDetails/user-actions.js";
// import ForgotPassword from './pages/forgotPassword/ForgotPassword.jsx';

//apis
import { getAllMenu } from "./redux/menu/menu-actions.js";
import { getBrandProfile } from "./redux/brandDetails/bd-actions.js";
import { getCms, getSections } from "./redux/cms/cms-actions.js";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllPages } from "./redux/pages/pages-actions";
// import BookAppointment from './pages/allSections/components/BookAppointment.jsx';
import { getGateway } from "./redux/payment/gateway-actions.js";
import UnSubscribe from "./pages/unsubscribe/UnSubscribe.jsx";
import Sports from "./pages/allSections/extension/sports/Sport.jsx";

import ScrollToTop from "./components/ScrollToTop.jsx";
import { getAllForms } from "./redux/forms/forms-action.js";
import AboutSection16 from "./pages/allSections/components/AboutSection16.jsx";
import Testimonial7 from "./pages/allSections/components/Testimonial7.jsx";
// import Profile from './pages/allSections/mainProfile/Profile';
// import IciciHome from './pages/allSections/extension/dms/IciciHome.jsx';
import Bussiness from "./pages/allSections/extension/dms/business-dash/business/Bussiness.jsx";
// import IciciHeader from './pages/allSections/extension/dms/components/IciciHeader.jsx';
import Reviewer from "./pages/allSections/extension/dms/reviewer-dash/reviewer-home/Reviewer.jsx";
import HelpSupport from "./pages/allSections/extension/dms/business-dash/bussiness-helpsupport/Bussiness-Helpsupport.jsx";
import Assets from "./pages/allSections/extension/dms/business-dash/bussiness-assets/Bussiness-Assets.jsx";
import Author from "./pages/allSections/extension/dms/business-dash/bussiness-author/Bussiness-Author.jsx";
import Iciciadmin from "./pages/allSections/extension/dms/admin-dash/admin/Admin.jsx";
import FileManagement from "./pages/allSections/extension/dms/admin-dash/filemanagement/FileManagement.jsx";
import ApproveFile from "./pages/allSections/extension/dms/admin-dash/approvedfile/ApproveFile.jsx";
import AssetsFile from "./pages/allSections/extension/dms/admin-dash/assetsfile/AssetsFile.jsx";
import Leadership from "./pages/allSections/extension/dms/leadership-dash/LeaderShip.jsx";
import Helpsupport2 from "./pages/allSections/extension/dms/leadership-dash/helpsupport/HelpSupport.jsx";
import AssetsFile2 from "./pages/allSections/extension/dms/leadership-dash/assetsfile/AssetsFile.jsx";
import ApproveFile2 from "./pages/allSections/extension/dms/leadership-dash/approvedfile/ApproveFile.jsx";
import Dashboard from "./pages/allSections/extension/dms/author-dash/authordashboard/Dashboard.jsx";
import Authorassets from "./pages/allSections/extension/dms/author-dash/assetsfile/Authorassets.jsx";
import Folderauthor from "./pages/allSections/extension/dms/author-dash/folder/Folderauthor.jsx";
import Helpsupport3 from "./pages/allSections/extension/dms/author-dash/helpsupport/HelpSupport.jsx";
import AuthorTabs from "./pages/allSections/extension/dms/author-dash/authortabs/AuthorTabs.jsx";
import Dashboard2 from "./pages/allSections/extension/dms/approval-dash/dashboard/Dashboard.jsx";
import Helpsupport4 from "./pages/allSections/extension/dms/approval-dash/helpsupport/HelpSupport.jsx";
import Approvalassets from "./pages/allSections/extension/dms/approval-dash/assetsfile/Approvalassets.jsx";
import Folderapproval from "./pages/allSections/extension/dms/approval-dash/folder/Folderapproval.jsx";
import ApprovalTabs from "./pages/allSections/extension/dms/approval-dash/approvaltabs/ApprovalTabs.jsx";
import ClgBusiness from "./pages/allSections/extension/dms/userLogin/ClgBusiness.jsx";
import ReviewAssets from "./pages/allSections/extension/dms/reviewer-dash/reviewer-assets/ReviewAssets.jsx";
import Approval from "./pages/allSections/extension/dms/reviewer-dash/reviewer-approval/Approval.jsx";
import ReviewTab from "./pages/allSections/extension/dms/reviewer-dash/reviewer-tabs/ReviewTab.jsx";
import AllCourses from "./pages/allSections/extension/elearning/AllCourses.jsx";
import SubscriptionPlan4 from "./pages/allSections/components/SubscriptionPlan4.jsx";
import Loading1 from "./pages/allSections/components/Loading1.jsx";
import { startLoading, stopLoading } from "./redux/Loader/loader-action.js";
import { CloseIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
import NoPageFound1 from "./pages/allSections/components/noPageFound1.jsx";
import NoPageFound2 from "./pages/allSections/components/NoPageFound2.jsx";
import CountdownTimer1 from "./pages/allSections/components/CountdownTimer1.jsx";
import ComingSoon from "./pages/allSections/components/ComingSoon1.jsx";
import ComingSoon1 from "./pages/allSections/components/ComingSoon1.jsx";
import Summary1 from "./pages/allSections/components/Summary1.jsx";
import HeroSection33 from "./pages/allSections/components/HeroSection33.jsx";

const App = () => {
  const loading = useSelector((state) => state.Loader.loading);
  console.log(loading, "LoadingReducer");

  // const wrapperRef = useRef(null);
  // const ease = 0.05; // 20%
  // const scroll = {
  //   current: 0,
  //   target: 0,
  //   limit: 0,
  // };
  // const updateTarget = e => {
  //   scroll.target += e.deltaY;
  // };
  // const lerp = (current, target) => {
  //   const distanceBetween = target - current;
  //   const distanceToTravel = distanceBetween * ease;
  //   return current + distanceToTravel;
  // };
  // const clamp = (min, max, value) => {
  //   return Math.min(Math.max(value, min), max);
  // };
  // const smoothScroll = () => {
  //   if (!wrapperRef.current) return;
  //   const maxScroll = wrapperRef.current.clientHeight - window.innerHeight;
  //   scroll.target = clamp(0, maxScroll, scroll.target);
  //   const { current, target } = scroll;
  //   const transition = lerp(current, target);
  //   scroll.current = transition;
  //   wrapperRef.current.style.transform = `translateY(-${scroll.current}px)`;
  //   window.requestAnimationFrame(smoothScroll);
  // };
  // useEffect(() => {
  //   dispatch(startLoading());
  //   document.addEventListener('mousewheel', updateTarget);
  //   return () => {
  //     document.removeEventListener('mousewheel', updateTarget);
  //   };
  // }, []);
  // useEffect(() => {
  //   smoothScroll();
  // }, [scroll.target]);

  const dispatch = useDispatch();
  const { isOpen, onToggle } = useDisclosure();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let getRoutesNames = useSelector((state) => state.cms.routeNames);
  let getOtherLogin = useSelector((state) => state.AuthDetails.otherLogin);
  let switchContrast = useSelector(
    (state) => state.menuState.selectedContrastData
  );
  // const [dynamicRoutes, setDynamicRoutes] = useState(['test', 'example']);
  // let menuData = useSelector(state => state.menu.menu);
  const [primaryColor, setPrimaryColor] = useState("#cd3197");
  const [secondaryColor, setSecondaryColor] = useState("#fff6fc");
  const [tertiaryColor, setTertiaryColor] = useState("#1d0cff");
  const [bodyColor, setbodyColor] = useState("#00000");
  const { colorMode, toggleColorMode } = useColorMode();

  const catchLogin = async () => {
    try {
      dispatch(otherLogin(""));
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      const decodedToken = token ? JSON.parse(decodeURIComponent(token)) : null;

      if (decodedToken) {
        const data = await dispatch(getUserByToken({ token: decodedToken }));

        if (data && data.code === 200) {
          Swal.fire({
            title: "Success",
            text: `Loggedin successfully`,
            icon: "success",
            timer: "2500",
          });
          window.history.replaceState(null, "", "/home");
        } else {
          if (data.message) {
            Swal.fire({
              title: "Error",
              text: data.message,
              icon: "error",
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    async function mount() {
      // dispatch(startLoading())
      await dispatch(getAllMenu());
      await dispatch(getProject());
      await dispatch(
        getBrandProfile({
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
      );
      await dispatch(getCms());
      await dispatch(getSections());
      await dispatch(getBrandProfile());
      await dispatch(getAllForms());
      await dispatch(getGateway());
      await dispatch(getAllPages());
      // dispatch(stopLoading());

      if (getOtherLogin) {
        catchLogin();
      }

      const fontToLoad = brandProfile ? brandProfile.primaryFont : "sans-serif";
      const fontLink = document.createElement("link");

      if (fontToLoad) {
        fontLink.rel = "stylesheet";
        fontLink.href = `https://fonts.googleapis.com/css?family=${fontToLoad.replace(
          / /g,
          "+"
        )}`;
      }
      if (brandProfile) {
        if (switchContrast) {
          setPrimaryColor(brandProfile.tertiaryColor);
        } else {
          setPrimaryColor(brandProfile.primaryColor);
        }
        const secondaryColor = brandProfile.secondaryColor;
        const tertiaryColor = brandProfile.tertiaryColor;
        const bodyColor = brandProfile.bodyColor;

        // setPrimaryColor(primaryColor);
        setSecondaryColor(secondaryColor);
        setTertiaryColor(tertiaryColor);
        setbodyColor(bodyColor);
        console.log(bodyColor, "brand");
      }

      document.head.appendChild(fontLink);

      fontLink.addEventListener("load", () => {
        const content = document.getElementById("root");
        content.style.fontFamily = `${fontToLoad},sans-serif`;
      });
    }

    mount();
    // eslint-disable-next-line
  }, [dispatch, switchContrast, primaryColor, secondaryColor, tertiaryColor]);

  const theme = extendTheme({
    styles: {
      global: (props) => ({
        ".cust-textColor": {
          color:
            props.colorMode === "dark" ? "#ffffffeb !important" : "#494949",
        },
        ".navbartextColor-light": {
          color: props.colorMode === "dark" ? "#a0aec0 !important" : "",
        },
        ".textColor-light": {
          color: props.colorMode === "dark" ? "#a0aec0 !important" : "#808080",
        },
        ".cust-bgColor": {
          backgroundColor:
            props.colorMode === "dark" ? "#2d3748 !important" : "",
        },
      }),
    },
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    colors: {
      light: {
        bg: "#ffffff",
        text: "#000000",
      },
      dark: {
        bg: "#1a202c",
        text: "#ffffff",
      },
      primary: {
        500: `${primaryColor}`,
      },
      primary: {
        500: `${primaryColor}`,
      },
      secondary: {
        500: `${secondaryColor}`,
      },
      tertiary: {
        500: `${tertiaryColor}`,
      },
    },
  });

  //   dispatch(getBrandProfile());
  // }, []);
  function applyThemeToDocument() {
    if (switchContrast) {
      if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
        document.body.style.setProperty(
          "--chakra-colors-primary",
          brandProfile.tertiaryColor
        );
    } else {
      if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
        document.body.style.setProperty(
          "--chakra-colors-primary",
          brandProfile.primaryColor
        );
    }

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-tertiary",
        brandProfile.tertiaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("bodyColor"))
      document.body.style.setProperty(
        "--chakra-colors-chakra-body-text",
        brandProfile.bodyColor
      );
  }
  applyThemeToDocument();

  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    onToggle();
  }, []);

  if (loading) {
    return <Loading1 />;
  }

  // const salesIQ = () => {
  // <script type="text/javascript" id="zsiqchat">var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq5ad9de5db8f3d658eb68595be0cdbea39100bba0140f605a35181f928364b766", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);</script>
  // }
  //
  return (
    <div>
      <ChakraProvider theme={theme}>
        <Router>
          <ScrollToTop />
          {/* <IciciHeader /> */}
          <Routes>
            {getRoutesNames &&
              !!getRoutesNames.length &&
              getRoutesNames.map((dynRoute, index) => {
                return (
                  <Fragment key={dynRoute}>
                    <Route
                      key={`${dynRoute}${index}`}
                      path={`/${dynRoute.toLocaleLowerCase()}`}
                      element={<Master routeName={dynRoute} />}
                    />
                    <Route
                      key={`${dynRoute}${index}${index}`}
                      path={`/${dynRoute.toLocaleLowerCase()}/cms/:token`}
                      element={<Master routeName={dynRoute} />}
                    />
                    <Route
                      key={`${dynRoute}${index}${index}`}
                      path={`/${dynRoute.toLocaleLowerCase()}/cera`}
                      element={<Master routeName={dynRoute} />}
                    />
                    <Route
                      key={`${dynRoute}${index}${index}`}
                      path={`/${dynRoute.toLocaleLowerCase()}/:blogId`}
                      element={<Master routeName={dynRoute} />}
                    />
                  </Fragment>
                );
              })}
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/section" element={<Sections />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/sg" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Navigate to="/home" />} />
            <Route path="/sports" element={<Sports />} />
            {/* <Route path="/aisectiont" element={<Chat />} /> */}
            {/* <Route path="/scv" element={<SCVHome />} /> */}
            {/* <Route path="*" element={<Login />} /> */}
            <Route path="/AboutSection16" element={<AboutSection16 />} />
            <Route path="/unsubcribe" element={<UnSubscribe />} />
            <Route path="/testimonial7" element={<Testimonial7 />} />
            <Route path="/test" element={<AllCourses />} />
            {/* <Route path="/detail" element={<Testimonial7 />} /> */}
            {/* ============== Login-icici ============== */}
            <Route path="/userlogin" element={<ClgBusiness />} />
            {/* ============== Login-icici ============== */}
            {/* ============== Business-icici ============== */}
            <Route path="/business-home" element={<Bussiness />} />
            <Route path="/business-assets" element={<Assets />} />
            <Route path="/business-author" element={<Author />} />
            <Route path="/business-helpsupport" element={<HelpSupport />} />
            {/* ============== Business-icici ============== */}
            {/* ============== Icici-Admin ============== */}
            <Route path="/admin" element={<Iciciadmin />} />
            <Route path="/admin-tabs" element={<FileManagement />} />
            <Route path="/admin-approved" element={<ApproveFile />} />
            <Route path="/admin-assets" element={<AssetsFile />} />
            {/* ============== Icici-Admin ============== */}
            {/* ============== Leadership ============== */}
            <Route path="/leadership" element={<Leadership />} />
            <Route path="/leadership-helpsupport" element={<Helpsupport2 />} />
            <Route path="/leadership-assets" element={<AssetsFile2 />} />
            <Route path="/leadership-approve" element={<ApproveFile2 />} />
            {/* ============== Leadership ============== */}
            {/* ============== Author ============== */}
            <Route path="/author-dash" element={<Dashboard />} />
            <Route path="/author-assets" element={<Authorassets />} />
            <Route path="/author-folder" element={<Folderauthor />} />
            <Route path="/author-helpsupport" element={<Helpsupport3 />} />
            <Route path="/author-tabs" element={<AuthorTabs />} />
            {/* ============== Author ============== */}
            {/* ============== Approval ============== */}
            <Route path="/approval-dash" element={<Dashboard2 />} />
            <Route path="/approval-helpsupport" element={<Helpsupport4 />} />
            <Route path="/approval-assets" element={<Approvalassets />} />
            <Route path="/approval-folder" element={<Folderapproval />} />
            <Route path="/approval-tabs" element={<ApprovalTabs />} />
            {/* ============== Approval ============== */}
            {/* ============== Reviwer ============== */}
            <Route path="/reviewer" element={<Reviewer />} />
            <Route path="/reviewer-assets" element={<ReviewAssets />} />
            <Route path="/reviewer-approval" element={<Approval />} />
            <Route path="/reviewer-tabs" element={<ReviewTab />} />
            {/* ============== Reviwer ============== */}
            <Route path="*" element={<NoPageFound2 />} />
            <Route path="/comingsoon" element={<ComingSoon1/> } />
            <Route path="/home1" element={<HeroSection33 />} />
          </Routes>
        </Router>
      </ChakraProvider>
      {/* {isVisible && (
        <div
        className="w-full fixed cust-bgColor z-index9999 bottom-0 right-0 box-shadow-outer bg-white  mobile"
        
        >
          <div className="flex justify-between items-center w-full lg:px-8 md:px-6 px-4 py-3 relative">
            <p className="p-2 fsize14 sm-fsize12 text-black cust-textColor">
              This website uses cookies to ensure you get the best experience on our website.
            </p>

            <div className="flex gap-2 mt-2">
              <Button className="bg-primary sm-fsize12 clr-fff py-1 px-4 fsize14 rounded-lg">
                Accept
              </Button>
            
              <Button className="bg-secondary sm-fsize12 py-1 px-4 fsize14 rounded-lg border">
                
                Privacy Policy
              
              </Button>
            
            </div>
            <div className="absolute top-2 right-3 cusrsor-pointer">
              <CloseIcon onClick={handleClose} className="cust-textColor" boxSize={10}/>
            </div>
          </div>
        </div>
      )} */}

      <Slide
        direction="bottom"
        in={isOpen}
        style={{ zIndex: 999 }}
        className="box-shadow-outer bg-white cust-bgColor"
      >
        <div className="lg:flex md:flex justify-between items-center w-full lg:pl-8 lg:pr-32 md:px-6 px-4 py-3 relative">
          <p className="p-2 fsize14 sm-fsize12 text-black cust-textColor">
            This website uses cookies to ensure you get the best experience on
            our website.
          </p>

          <div className="flex gap-2 lg:px-0 md:px-0 px-2 lg:pb-0 md:pb-0 pb-2">
            <Button
              onClick={onToggle}
              className="bg-primary sm-fsize12 clr-fff py-1 px-4 fsize14 rounded-lg"
            >
              Accept
            </Button>

            <a href="/privacy-policy">
              <Button
                variant="outline"
                className=" border-primary text-primary sm-fsize12 py-1 px-4 fsize14 rounded-lg"
              >
                Privacy Policy
              </Button>
            </a>
          </div>
          <div className="absolute top-0 lg:right-28 right-3 cusrsor-pointer">
            <CloseIcon
              onClick={onToggle}
              className="text-primary cust-textColor cursor-pointer"
              boxSize={10}
            />
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default App;
