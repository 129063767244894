import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useSelector } from "react-redux/es/exports";
import { Image, useColorMode } from "@chakra-ui/react";

const CounterSection2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const isCMSOn = useSelector((state) => state.cms.isCMSOn);
  console.log(isCMSOn, "cmss");
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();

  const [counterOn, setCounterOn] = useState(false);

  return (
    <section
      className={
        colorMode === "light"
          ? "bg-brainedlight lg:py-10 md:py-8 py-4"
          : "lg:py-10 md:py-8 py-4"
      }
    >
      <div className="container mx-auto grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-36 md:gap-18 gap-4 m-auto lg:px-12 md:px-8 px-5">
        <div>
          <div className="flex items-center justify-center cust-textColor text-primary">
            {isCMSOn ? (
              <p
                id="4891530357"
                className="font-bold  dynamicStyle fsize36 md-fsize30 sm-fsize24"
              >
                {" "}
                {data["4891530357"]}
              </p>
            ) : (
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <>
                    <CountUp
                      className="font-bold  dynamicStyle fsize36 md-fsize30 sm-fsize24"
                      id="4891530357"
                      start={0}
                      end={data["4891530357"]}
                      duration={4}
                      delay={0}
                    />
                  </>
                )}
              </ScrollTrigger>
            )}

            <p className="cust-textColor fsize36 md-fsize30 sm-fsize24 text-primary pb-1">
              +
            </p>
          </div>
          <p
            id="4372004291"
            className="dynamicStyle text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold textColor-light"
          >
            {data ? data["4372004291"] : "Lorem Ipsum"}
          </p>
        </div>
        <div>
          <div className="flex items-center justify-center cust-textColor text-primary">
            {isCMSOn ? (
              <p
                id="4636316338"
                className="font-bold  dynamicStyle fsize36 md-fsize30 sm-fsize24"
              >
                {" "}
                {data["4636316338"]}
              </p>
            ) : (
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className=" font-bold fsize36 md-fsize30 sm-fsize24"
                    start={0}
                    end={data["4636316338"]}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
            )}

            <p className=" fsize36 md-fsize30 sm-fsize24 text-primary cust-textColor">
              %
            </p>
          </div>
          <p
            id="3677823420"
            className="dynamicStyle  text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold textColor-light"
          >
            {data ? data["3677823420"] : "Lorem Ipsum"}
          </p>
        </div>
        <div className="thirdcounter">
          <div className="flex items-center justify-center cust-textColor text-primary">
            {isCMSOn ? (
              <p
                id="3029563140"
                className="font-bold  dynamicStyle fsize36 md-fsize30 sm-fsize24"
              >
                {" "}
                {data["3029563140"]}
              </p>
            ) : (
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className=" font-bold fsize36 md-fsize30 sm-fsize24"
                    start={0}
                    end={data["3029563140"]}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
            )}

            {/* <p className=" fsize36">+</p> */}
          </div>
          <p
            id="4655683231"
            className="dynamicStyle  text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold textColor-light"
          >
            {data ? data["4655683231"] : "Lorem Ipsum"}
          </p>
        </div>
        <div className="fourthcounter">
          <div className="flex items-center justify-center cust-textColor text-primary">
            {isCMSOn ? (
              <p
                id="9897884328"
                className="font-bold  dynamicStyle fsize36 md-fsize30 sm-fsize24"
              >
                {" "}
                {data["9897884328"]}
              </p>
            ) : (
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className=" font-bold fsize36 md-fsize30 sm-fsize24"
                    start={0}
                    end={data["9897884328"]}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
            )}

            <p className=" fsize36 md-fsize30 sm-fsize24 text-primary cust-textColor">
              %
            </p>
          </div>
          <p
            id="1269116367"
            className="dynamicStyle  text-center fsize18 md-fsize16 sm-fsize13 text-535353 font-semibold textColor-light"
          >
            {data ? data["1269116367"] : "Lorem Ipsum"}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CounterSection2;
