import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import Marquee from 'react-fast-marquee';

const Clients6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);


  return (
    <section>
      <div className="relative w-full flex justify-center lg:py-6 md:py-4 py-4">
        <div className="flex items-center theme-bg bg-fa lg:pb-5 lg:pt-6 lg:px-12 md:px-5 md:pt-3 md:pb-2 pt-2 pb-1 lg:w-10/12 md:w-11/12 w-full rounded-full">
          <div className="container mx-auto">
            <div
              ref={elementRef}
              className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
            >
              <div
                id="7550124558"
                className="dynamicStyle lg:px-10 md:px-10 px-8"
              >
                <Marquee loop={0} autoFill={true}>
                  {data && data['7550124558']
                    ? data['7550124558'].map(e => {
                        return (
                          <div className='mx-2'>
                            <Image
                              src={e ? getImage(e.field) : ''}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt=""
                              className="rounded-md img-ratio lg:h-32 md:h-24 h-20"
                            />
                          </div>
                        );
                      })
                    : 'no-data'}
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients6;
