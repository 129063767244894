import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Card, Image, useColorMode } from "@chakra-ui/react";
import UserEffect from "../../components/usereeffect/UserEffect";
import {handleScroll}  from "../../components/fadeeffect/FadeEffect";

const CaseStudyAboutSection = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="container mx-auto mx-auto md:px-8 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="pb-10">
        <h2
          id="5753914031"
          className={
            colorMode === "light"
              ? "dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-primary "
              : "dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-white"
          }
        >
          {data ? data["5753914031"] : "Lorem Ipsum"}
        </h2>
        <h1
          id="7436837251"
          className="dynamicStyle text-black cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold lg:leading-12 md:leading-9 lg:mb-2 md:mb-2 mb-2  sm-lh-32px"
        >
          {data ? data["7436837251"] : "Lorem Ipsum"}
        </h1>
      </div>
      <div className="w-full lg:flex  gap-10">
        <div className="lg:w-1/5  w-full">
          <div
            ref={elementRef}
            className={`fade-left w-full h-full ${
              isVisible ? "visibleleft" : ""
            } `}
          >
            <div id="5712266393" className="dynamicStyle2 h-500px md-h-350px">
              <Image
                src={data ? getImage(data["5712266393"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="about"
                className="w-full h-500px md-h-350px br-28px object-contain"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-4/5 lg:mb-0 md:mt-6 lg:mt-0 mt-4 w-full">
          {/* <p
            id="8330960685"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8330960685']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light fsize16 sm-fsize14 text-black lg:leading-7 md:leading-7 leading-6"
          ></p> */}

          <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-3">
            <div
              className={
                colorMode === "light"
                  ? " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
                  : " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
              }
            >
              <h2
                id="5909416769"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data["5909416769"] : "When We Started"}
              </h2>
              <p
                id="0582174971"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["0582174971"]
                    : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
                className="dynamicStyle cust-textColor fsize14 text-black cust-textColor textColor-light "
              ></p>
            </div>
            <div
              className={
                colorMode === "light"
                  ? " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
                  : " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
              }
            >
              <h2
                id="3512072679"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data["3512072679"] : "Lorem Ipsum"}
              </h2>
              <p
                id="0034501580"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["0034501580"]
                    : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
                className="dynamicStyle fsize14 text-black cust-textColor textColor-light"
              ></p>
            </div>
            <div
              className={
                colorMode === "light"
                  ? " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
                  : " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
              }
            >
              <h2
                id="7591030182"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data["7591030182"] : "Lorem Ipsum"}
              </h2>
              <p
                id="1432661534"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["1432661534"]
                    : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
                className="dynamicStyle fsize14 text-black textColor-light"
              ></p>
            </div>
            <div
              className={
                colorMode === "light"
                  ? " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
                  : " lg:py-6 md:py-6 py-4 lg:px-5 md:px-5 px-3 themeShadow theme-bg"
              }
            >
              <h2
                id="5646285287"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data["5646285287"] : "Lorem Ipsum"}
              </h2>
              <p
                id="5827450583"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["5827450583"]
                    : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                }}
                className="dynamicStyle fsize14 text-black textColor-light"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyAboutSection;
