import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GallerySection11 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:py-12 md:py-10 py-8">
      <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1">
        <div className="relative">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 className="fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                Lorem Ipsum
              </h3>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-2 md:col-span-2 col-span-1">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 className="fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                Lorem Ipsum
              </h3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 className="fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                Lorem Ipsum
              </h3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 className="fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                Lorem Ipsum
              </h3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 className="fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                Lorem Ipsum
              </h3>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-2 md:col-span-2 col-span-1">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 className="fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                Lorem Ipsum
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection11;
