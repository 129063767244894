import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
  useColorMode,
  Card,
  Image,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux/es/exports";
import axios from "axios";
import { getFormSchema } from "../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";

import MasterForm from "../components/Forms/MasterForm";

const ContactForm2 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getallField = useSelector((state) => state.Forms.Forms);
  let getSchemaForm = useSelector((state) => state.Forms);
  console.log(getallField, "filed");

  useEffect(() => {
    async function mount() {
      let payload = {
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        formId: getallField[0]._id,
      };
      await dispatch(getFormSchema(payload));
    }
    mount();
  }, [dispatch]);
  //  getallField[0].field.value,
  const handleSubmit = async () => {
    let payload = {
      _id: getallField[0]._id,
    };

    let output;
    if (getallField && getallField.lenght > 0 && getallField[0].isAuth) {
      output = await axios.post(
        `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm.FormSchema[0].schemaName}`,
        payload,
        {
          headers: {
            "community-name": process.env.REACT_APP_COMMUNITY_NAME,
            // authorization: `Bearer ${localStorage.getItem('cmsToken')}`,
          },
        }
      );
    } else {
      output = await axios.post(
        `${process.env.REACT_APP_BASE_URL}forms/formCreate/${getSchemaForm.FormSchema[0].schemaName}`,
        payload,
        {
          headers: {
            "community-name": process.env.REACT_APP_COMMUNITY_NAME,
            // authorization: `Bearer ${localStorage.getItem('cmsToken')}`,
          },
        }
      );
    }
  };

  const closeFunction = (item) => {
    console.log(item);
  };

  return (
    <section
      className={
        colorMode === "light"
          ? "lg:py-16 md:py-10 py-8"
          : "lg:py-16 md:py-10 py-8"
      }
      id="form"
    >
      <div className="container mx-auto lg:px-12 md:px-8 px-5 flex items-center gap-8">
        {/* <div className="lg:w-1/2 md:w-1/2 w-full">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="form-img"
            className="w-full object-contain"
          />
        </div> */}
        <div className="lg:w-2/3 md:w-2/3 w-full mx-auto">
          <div className="text-center w-full">
            <p
              id="3532025242"
              className="dynamicStyle cust-textColor fsize16 md-fsize16 font-medium sm-fsize14 text-primary"
            >
              {data ? data["3532025242"] : "Enquire"}
            </p>
            <p
              id="1801706606"
              className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold lg:pb-6 md:pb-6 pb-6 clr-191E22"
            >
              {data ? data["1801706606"] : "Enquire now and get best solution"}
            </p>
          </div>

          {/* <div className="lg:w-5/6 md:w-5/6 m-auto lg:grid md:grid grid-cols-1 gap-4 ">
            {getallField && getallField[0].field.length > 0
              ? getallField[0].field.map((item, index) => {
                  return (
                    <div className="lg:pb-0 md:pb-0 pb-3 ">
                      {item.inputType == "input" && (
                        <Input
                          borderRadius={10}
                          height="4rem"
                          border={0}
                          className="custShadow1 p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.label}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == "multiline" && (
                        <Textarea
                          className="p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == "phone" && (
                        <Input
                          borderRadius={10}
                          height="4rem"
                          border={0}
                          className="custShadow1 p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.label}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == "email" && (
                        <Input
                          borderRadius={10}
                          type="email"
                          height="4rem"
                          border={0}
                          className="custShadow1 p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.label}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}

                      {item.inputType == "url" && (
                        <Input
                          className="p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}
                      {item.inputType === "singleselect" && (
                        <Select
                          placeholder={item.placeholder}
                          v-model={item.value}
                          className="fsize14 p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                        >
                          {item.enum.map((i) => {
                            return <option value={i}>{i}</option>;
                          })}
                        </Select>
                      )}

                      {item.inputType === "radiobutton" && (
                        <RadioGroup defaultValue="Itachi">
                          <HStack spacing="24px">
                            {item.enum.map((e) => {
                              return <Radio value={e}>{e}</Radio>;
                            })}
                          </HStack>
                        </RadioGroup>
                      )}

                      {item.inputType === '"imagefile"' && (
                        <Input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          v-model={item.value}
                          maxlength={item.maxLength}
                          minlength={item.minLength}
                        />
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
          {getallField && getallField[0].field.length > 0
            ? getallField[0].field.map((item) => {
                return (
                  <div className="lg:w-5/6 mx-auto text-center">
                    {item.inputType == "button" && (
                      <Button
                        onClick={() => handleSubmit()}
                        colorScheme="primary"
                        px={12}
                        height="4rem"
                        borderRadius={10}
                        className="custShadow1 w-100per theme-btn2 font-semibold bg-da8f2c cust-bgColor clr-fff lg:py-3 py-2 lg:px-8 px-4 mt-6"
                      >
                        <span> {item.label}</span>
                      </Button>
                    )}
                  </div>
                );
              })
            : "no-data"} */}
          <MasterForm
            formId={getallField && getallField.length > 0 && getallField[0]._id}
            pageName={pageName}
            close={closeFunction}
            edit={{}}
          />
        </div>
      </div>
    </section>
  );
};

export default ContactForm2;
