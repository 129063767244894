import React from "react";
import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";

const SkeletonCareer = () => {
  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:mt-8 md:mt-5 mt-4 gap-6">
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mb="6" noOfLines={4} spacing="6" skeletonHeight="2" />
        <Skeleton height="40px" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mb="6" noOfLines={4} spacing="6" skeletonHeight="2" />
        <Skeleton height="40px" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mb="6" noOfLines={4} spacing="6" skeletonHeight="2" />
        <Skeleton height="40px" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mb="6" noOfLines={4} spacing="6" skeletonHeight="2" />
        <Skeleton height="40px" />
      </Box>
    </div>
  );
};

export default SkeletonCareer;
