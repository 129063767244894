import API from "../repositoryFactory";

const getcaseStudy = "get/case_study_new";

const pincaseStudty = "get/pinned_case_studies";
const caseStudtyIndustries = "get/case-study-industries";

const getcaseStudys = (route) => {
  return `forms/${route}`;
};

export default {
  getAllCaseStudy(payload) {
    return API.post(getcaseStudys(getcaseStudy), payload);
  },

  getAllPinCaseStudy(payload) {
    return API.post(getcaseStudys(pincaseStudty), payload);
  },

  getAllCaseStudyIndustries(payload) {
    return API.post(getcaseStudys(caseStudtyIndustries), payload);
  },
};
