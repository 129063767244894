import React, { useEffect, useRef, useState } from "react";
import { Button, Image } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ArrowRight } from "react-feather";
const Banner = ({ pageName }) => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const elementRef = useRef(null);
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div className="relative">
      <div id="3397871225" className="dynamicStyle2 ">
        <Image
          src={data ? getImage(data["3397871225"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="home-banner"
          className="h-250px w-full object-cover"
        />
      </div>
      <div className="w-full  absolute top-0 left-0 h-250px flex justify-start items-center">
        <div className="container mx-auto lg:px-12 md:px-8 px-6 lg:flex md:flex justify-between items-center lg:gap-0 md:gap-6 gap-0">
          <div className="text-start lg:w-1/2 md:w-1/2">
            <h3
              id="4935077773"
              className="dynamicStyle fsize36 sm-fsize21 font-semibold text-white"
            >
              {data ? data["4935077773"] : "Help & Support"}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: data ? data["7694513590"] : "no-data",
              }}
              id="7694513590"
              className=" dynamicStyle text-white"
            ></p>
          </div>
          <div className="flex justify-end lg:w-1/2 md:w-1/2 flex gap-4 lg:mt-0 md:mt-0 mt-4 ">
            <Button
              colorScheme="primary"
              id="4045493260"
              className="dynamicStyle cs-btn-border clr-white sm-fsize14 buttonAnimate"
            >
              <span onClick={() => handleClick("4045493260")}>
                {data ? data["4045493260"] : "Lorem ipsum"}
              </span>

              <ArrowRight
                className="textwhite ml-2 textColor-light sm-arrow"
                height="18px"
              />
            </Button>
            <Button
              className="dynamicStyle cs-btn-border rounded-full sm-fsize14 buttonAnimate"
              id="3809973485"
            >
              <span onClick={() => handleClick("3809973485")}>
                {data ? data["3809973485"] : "Button"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
