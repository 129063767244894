import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { Card, Image, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { useDispatch } from "react-redux";
import axios from "axios";
// import { getAllCaseStudy } from "../../../redux/caseStudy/cs-action";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllCaseStudy } from "../../../redux/caseStudy/cs-action";
import SkeletonCaseStudy from "../../../components/skeleton/SkeletonCaseStudy";
const GridCard2 = ({ pageName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const caseStudyData = useSelector((state) => state.caseStudy.CaseStudy);

  const [load, setLoad] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     dispatch(getAllCaseStudy());
  //     setLoad(false);
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    try {
      setLoad(true);
      dispatch(getAllCaseStudy());
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }, []);

  return (
    <section
      id="case-study"
      className="container mx-auto lg:py-16 md:py-8 py-4 lg:px-12 md:px-8 px-5"
    >
      <p
        id="5539013178"
        className="dynamicStyle font-semibold inline-block text-primary textColor-light"
      >
        {data ? data["5539013178"] : "Lorem Ipsum"}
      </p>
      <div className="lg:flex md:flex justify-between w-full">
        <p
          id="5941461009"
          className="dynamicStyle text-black fsize34 md-fsize26 sm-fsize22 font-semibold clr-191E22 cust-textColor"
        >
          {data ? data["5941461009"] : "Lorem Ipsum generated"}
        </p>
        {/* <div className="w-20per sm-w-50">
          <Select
            placeholder="Select Industry"
            className="sm-fsize14"
            onChange={(e) => handleSelectedIndustries(e)}
          >
            {getallCaseStudyIndustries?.result &&
              getallCaseStudyIndustries?.result?.length > 0 &&
              getallCaseStudyIndustries?.result.map((category, index) => (
                <option key={index} value={category._id}>
                  {category.industrytype}
                </option>
              ))}
          </Select>
        </div> */}
      </div>
      {load ? (
        <SkeletonCaseStudy />
      ) : (
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-8 gap-6 lg:mt-12 md:mt-12 mt-8">
          {caseStudyData &&
          caseStudyData.result &&
          caseStudyData.result.length > 0
            ? caseStudyData.result.map((e, i) => {
                return (
                  <Card
                    className="cursor-pointer p-4 cust-shadow"
                    key={i}
                    onClick={() => {
                      navigate(`/case study?cp=${encodeURI(e.projectname)}`);
                    }}
                  >
                    <div className="overflow-hidden">
                      <Image
                        className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover object-contain"
                        src={`${process.env.REACT_APP_STORAGE_URL}${e.image}`}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt=""
                      />
                    </div>
                    <p className="mb-0 lineClamp1 fsize16 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
                      {e.projectname}
                    </p>
                    {/* {e.industry && e.industry.length > 0
                    ? e.industry.map((item) => {
                        return (
                          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
                            {`• ${item}`}
                          </p>
                        );
                      })
                    : ""} */}
                    <div className="flex lineClamp1 mb-2">
                      <div className="inline-flex items-center gap-2 mr-3">
                        <span class="block h-1 w-1 bg-primary rounded-full cust-bgColor"></span>{" "}
                        <p className="inline-block fsize14 md-fsize12 sm-fsize12 clr-0E1317 textColor-light">
                          {e.roletitle1}
                        </p>
                      </div>
                      <div className="inline-flex items-center gap-2 mr-3">
                        <span class="block h-1 w-1 bg-primary rounded-full cust-bgColor"></span>{" "}
                        <p className="ml-1 inline-block fsize14 md-fsize12 sm-fsize12 clr-0E1317 textColor-light">
                          {e.roletitle2}
                        </p>
                      </div>
                      <div className="inline-flex items-center gap-2 mr-3">
                        <span class="block h-1 w-1 bg-primary rounded-full cust-bgColor"></span>{" "}
                        <p className="ml-1 inline-block fsize14 md-fsize12 sm-fsize12 clr-0E1317 textColor-light">
                          {e.roletitle3}
                        </p>
                      </div>
                    </div>

                    <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
                    <div className="flex lg:w-2/4 cursor-pointer">
                      <p className="text-primary sm-fsize14 textColor-light">
                        Customize
                      </p>
                      <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
                    </div>
                  </Card>
                );
              })
            : "no-data"}
        </div>
      )}
    </section>
  );
};

export default GridCard2;
