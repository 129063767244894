import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  Search2Icon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Image,
  Input,
  Switch,
  useDisclosure,
  Card,
  useColorMode,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaLongArrowAltRight, FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux/es/exports";
import { selectSwitchContrast } from "../../../redux/menu/menu-actions";
import GoogleTranslate from "../tarnslate/Googletranslate";
import { getAllCaseStudy } from "../../../redux/caseStudy/cs-action";
import { useNavigate } from "react-router-dom";

import Logo from "../../../assets/images/darkLogo.png";
const Header6 = () => {
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.menuState.menu);
  const { colorMode } = useColorMode();
  const [isModalOpen, setIsModalOpen] = useState(false);
  let switchContrast = useSelector(
    (state) => state.menuState.selectedContrastData
  );
  console.log(menuData, "menuData");

  const navigate = useNavigate();
  // console.log(switchContrast, "switchContrast");
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      // Adjust this value based on when you want the navbar to become fixed
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [megaMenuVisible, setmegaMenuVisible] = useState(false);
  // const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [searchCaseStudy, setSearchCaseStudy] = useState("");
  const [caseStudyData, setCaseStudyData] = useState([]);
  // const toggleDropdown = () => {
  //   setDropdownVisible(!dropdownVisible);
  // };

  // const toggleMegaMenuDropdown = (visible) => {
  //   setmegaMenuVisible(visible);
  // };

  const handleGetCaseStudy = async () => {
    try {
      console.log(searchCaseStudy, "searchCaseStudy");
      let payload = {
        // columnFilter: "projectname",
        searchBy: searchCaseStudy,
      };
      let data = await dispatch(getAllCaseStudy(payload));
      console.log(data, searchCaseStudy, "sj");
      if (data && data.code === 200) {
        setCaseStudyData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchCard = (projectName) => {
    navigate(`/case study?cp=${encodeURI(projectName)}`);

    handleCloseModal();
    // scrollToCaseStudy();
  };

  useEffect(() => {
    const data = setTimeout(() => {
      handleGetCaseStudy(searchCaseStudy);
    }, 500);

    return () => clearTimeout(data);
  }, [searchCaseStudy]);

  const handleSearch = async (e) => {
    // navigate("/home");
    // scrollToCaseStudy();
    let value = e.target.value;

    setSearchCaseStudy(value);

    // handleDebounceSearch(value);
  };

  // const scrollToCaseStudy = () => {
  //   const caseStudySection = document.getElementById("case-study");
  //   if (caseStudySection) {
  //     caseStudySection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const btnRef = React.useRef();

  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    let search = document.querySelector(".searchClass");
    if (isModalOpen && search) {
      search.focus();
    }
  }, [isModalOpen]);

  const handleContrast = () => {
    dispatch(selectSwitchContrast(!switchContrast));
    console.log(switchContrast, "switchContrast");
  };
  const handleClose = () => {
    setIsVisible(false);
  };

  const isMegaMenu = (subMenu) => {
    let isMega = subMenu.every((sub) => sub.subMenu.length > 0);
    return isMega;
  };

  // const [searchVisible, setSearchVisible] = useState(false);

  // const handleOpenModal = () => {
  //   console.log("object");
  //   setSearchVisible(true);
  //   console.log("object1");
  // };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSearchCaseStudy("");
  };

  const AllCaseStudyData = useSelector((state) => state.caseStudy.CaseStudy);
  const getallCaseStudyIndustries = useSelector(
    (state) => state.caseStudy.CaseStudyIndustries
  );

  console.log(getallCaseStudyIndustries, "getallCaseStudyIndustries");

  if (window.location.pathname === "/coming-soon") return null;
  if (window.location.pathname === "/user-registration") return null;
  return (
    <div>
      <div
        style={{ display: isModalOpen ? "block" : "none" }}
        className="search-overlay"
      >
        <div className="search-overlay-content relative">
          <div className="flex items-center lg:gap-12 md:gap-12 gap-6 lg:w-2/3 md:w-2/3 w-full mx-auto">
            <form action="" className="relative w-full">
              <input
                type="text"
                placeholder="Search..."
                name="search"
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     handleSearch();
                //   }
                // }}
                class="searchClass custinput_search"
                value={searchCaseStudy}
                onChange={handleSearch}
              />
              <Button type="submit" p={1} onClick={handleSearch}>
                <Search2Icon />
              </Button>
            </form>
            <span
              className="closebtn fsize18 sm-fsize14"
              onClick={handleCloseModal}
              title="close"
            >
              <CloseIcon />
            </span>
          </div>
          {searchCaseStudy ? (
            <div className="mt-8 searchOption_scroll">
              {caseStudyData &&
              caseStudyData?.result &&
              caseStudyData?.result?.length > 0
                ? caseStudyData?.result.map((e) => {
                    return (
                      <Card
                        onClick={() => handleSearchCard(e.projectname)}
                        className="mb-4"
                      >
                        <div
                          className={
                            colorMode === "light"
                              ? "cursor-pointer bg-fff text-left py-4 px-6 mb-4"
                              : "cursor-pointer  text-left py-4 px-6 "
                          }
                        >
                          <p className="fsize20 font-medium text-primary  pb-2">
                            {e.projectname}
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e.longdescription,
                            }}
                            className="fsize16 text-black textColor-light"
                          ></p>
                        </div>
                      </Card>
                    );
                  })
                : "no-dataa"}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card className="header6 relative">
        <div
          className={
            colorMode === "light"
              ? "header-6-sub-menu"
              : "header-6-sub-menu dark-bottom-border"
          }
        >
          <div className="container mx-auto">
            <div className="header-6-sub-menu-container lg:px-12 md:px-8 px-2 lg:py-0 md:py-6 py-2">
              <div className="lg:w-1/2 md:w-1/2 w-full mb-between flex items-center lg:mb-0 md:mb-0 mb-0">
                <GoogleTranslate />
                <p className="lg:px-2 md:px-2 px-0 cust-textColor">
                  <FormControl display="flex" alignItems="center" gap={2}>
                    <Switch
                      isChecked={switchContrast}
                      size="sm"
                      id="switchContrast"
                      onChange={handleContrast}
                    />
                    <FormLabel
                      htmlFor="email-alerts"
                      ml="4px"
                      mr="0px"
                      mb="0"
                      fontWeight={600}
                      className="fsize10"
                    >
                      High Contrast
                    </FormLabel>
                  </FormControl>
                </p>
                <div className="lg:px-2 md:px-2 px-0 flex items-center gap-2 cust-textColor">
                  <FaUser className="w-4 h-4 rounded-full" />
                  <NavLink to="/contact-us">
                    <p className="fsize10 font-semibold">Contact Sales</p>
                  </NavLink>
                </div>

                {/* <div className="px-2 flex items-center gap-2 cust-textColor">
                  <FaUser className="w-4 h-4 rounded-full" />
                  <NavLink to="/CRM">
                    <p className="fsize12 font-semibold">CRM</p>
                  </NavLink>
                </div> */}
              </div>
              <div className="lg:w-1/2  lg:flex md:flex hidden justify-end items-center gap-4">
                {/* <InputGroup size="md" width="12rem" className="cust-textColor">
                  <Input
                    pr="3rem"
                    pl="0.5rem"
                    className="fsize12"
                    placeholder="Search Here..."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    value={searchCaseStudy}
                    onChange={(e) => setSearchCaseStudy(e.target.value)}
                  />
                  <InputRightElement width="3rem" onClick={handleSearch}>
                    <Button h="1.75rem" size="sm">
                      <Search2Icon />
                    </Button>
                  </InputRightElement>
                </InputGroup> */}

                <div id="isSearch" className="search-overlay">
                  <div className="search-overlay-content relative">
                    <div className="flex items-center gap-12 w-2/3 mx-auto">
                      <form action="" className="relative w-full">
                        <input
                          type="text"
                          placeholder="Search..."
                          name="search"
                          // onKeyDown={(e) => {
                          //   if (e.key === "Enter") {
                          //     handleSearch();
                          //   }
                          // }}
                          value={searchCaseStudy}
                          onChange={handleSearch}
                        />
                        <Button type="submit" p={1}>
                          <Search2Icon />
                        </Button>
                      </form>
                      <span
                        className="closebtn"
                        onClick={handleCloseModal}
                        title="close"
                      >
                        <CloseIcon />
                      </span>
                    </div>

                    {searchCaseStudy ? (
                      <div className="mt-8 searchOption_scroll">
                        {caseStudyData &&
                        caseStudyData?.result &&
                        caseStudyData?.result?.length > 0
                          ? caseStudyData?.result.map((e) => {
                              return (
                                <Card
                                  onClick={() => handleSearchCard(e._id)}
                                  className="cursor-pointer bg-fff text-left py-4 px-6 mb-4"
                                >
                                  <p className="fsize20 font-medium text-primary pb-2">
                                    {e.projectname}
                                  </p>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: e.longdescription,
                                    }}
                                    className="fsize16"
                                  >
                                    {/* {e.longdescription} */}
                                  </p>
                                </Card>
                              );
                            })
                          : "no-dataa"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <Button type="submit" onClick={handleOpenModal}>
                  <SearchIcon />
                </Button>
                <ul className="flex items-center gap-2 list-none">
                  {/* <li className="px-2 fsize12 font-semibold cust-textColor">
                    <NavLink to="/coming-soon">Log in</NavLink>
                  </li> */}
                  {/* <li className="px-2 fsize12 font-semibold"><NavLink target="_blank" to="/app.brained.app/register">Register</NavLink></li> */}
                  <li className="px-2 fsize12 font-semibold cust-textColor">
                    <a
                      className=""
                      rel="noreferrer"
                      target="_blank"
                      href="https://help.brained.app"
                    >
                      {" "}
                      Customer Support
                    </a>
                  </li>
                  <li className="px-2 py-4 fsize12 font-semibold cust-textColor header6SubDropdown">
                    <div className="header6SubDropdownDiv cust-textColor">
                      About <ChevronDownIcon />
                    </div>
                    <div className="header6SubDropdown-content cust-bgColor">
                      <NavLink className="cust-textColor" to="/about-us">
                        About us
                      </NavLink>
                      <NavLink className="cust-textColor" to="/careers">
                        Careers
                      </NavLink>
                      <NavLink className="cust-textColor" to="/contact-us">
                        Contact us
                      </NavLink>
                      {/* <NavLink
                        className="cust-textColor"
                        to="/investor-relations"
                      >
                        Investor Relations
                      </NavLink>
                      <NavLink className="cust-textColor" to="/teams">
                        Management Team
                      </NavLink> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            colorMode === "light"
              ? `header-6-menu ${isFixed ? "header-6-Sticky" : ""}`
              : `header-6-menu cust-bgColor dark-bottom-border ${
                  isFixed ? "header-6-Sticky" : ""
                }`
          }
        >
          <div className="container mx-auto">
            <div className="header-6-menu-container lg:px-12 md:px-8 px-5 ">
              <div className="flex items-center header6Height sm-w-100">
                <div className="sm-w-50 ">
                  {colorMode === "light" ? (
                    <NavLink to="/">
                      <Image
                        src={getPrimaryImage(brandProfile)}
                        alt="logo"
                        className="w-24 h-16 object-contain clr-fff"
                      />
                    </NavLink>
                  ) : (
                    <NavLink to="/">
                      <Image
                        src={Logo}
                        alt="logo"
                        className="w-24 h-16 object-contain clr-fff"
                      />
                    </NavLink>
                  )}
                </div>

                <div className="lg:hidden md:hidden block sm-w-50">
                  <div className=" flex gap-2 justify-end">
                    <div
                      style={{ display: isModalOpen ? "block" : "none" }}
                      className="search-overlay"
                    >
                      <div className="search-overlay-content relative">
                        <div className="flex items-center gap-12 w-2/3 mx-auto">
                          <form action="" className="relative w-full">
                            <Input
                              type="text"
                              placeholder="Search..."
                              name="search"
                              value={searchCaseStudy}
                              onChange={handleSearch}
                            />
                            <Button type="submit" onClick={handleSearch}>
                              {" "}
                              p={1}
                              <Search2Icon />
                            </Button>
                          </form>
                          <span
                            className="closebtn"
                            onClick={handleCloseModal}
                            title="close"
                          >
                            <CloseIcon />
                          </span>
                        </div>
                        <div className="mt-6">
                          {searchCaseStudy ? (
                            <div className="mt-8 searchOption_scroll">
                              {caseStudyData &&
                              caseStudyData?.result &&
                              caseStudyData?.result?.length > 0
                                ? caseStudyData?.result.map((e) => {
                                    return (
                                      <Card
                                        onClick={() =>
                                          handleSearchCard(e.projectname)
                                        }
                                        className="mb-4"
                                      >
                                        <div
                                          className={
                                            colorMode === "light"
                                              ? "cursor-pointer bg-fff text-left py-4 px-6 mb-4"
                                              : "cursor-pointer  text-left py-4 px-6 "
                                          }
                                        >
                                          <p className="fsize20 font-medium text-primary  pb-2">
                                            {e.projectname}
                                          </p>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: e.longdescription,
                                            }}
                                            className="fsize16 text-black textColor-light"
                                          ></p>
                                        </div>
                                      </Card>
                                    );
                                  })
                                : "no-dataa"}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <Button
                      background="transparent"
                      type="submit"
                      onClick={handleOpenModal}
                    >
                      <SearchIcon />
                    </Button>

                    <Button onClick={onOpenMenu}>
                      <HamburgerIcon />
                    </Button>
                    <Drawer
                      isOpen={isOpenMenu}
                      placement="right"
                      onClose={onCloseMenu}
                      finalFocusRef={btnRef}
                    >
                      <DrawerContent maxWidth="80%">
                        <DrawerHeader>
                          <NavLink to="">
                            <h2 className="text-white fsize20 font-semibold">
                              Menu
                            </h2>
                          </NavLink>{" "}
                          <DrawerCloseButton
                            top="16px"
                            className="text-white"
                          />
                        </DrawerHeader>
                        <DrawerBody px={4}>
                          <ul className="list-none subMenu ">
                            {menuData
                              ? menuData.map((e, i) => (
                                  <>
                                    {e.menutype === "page" &&
                                    e.subMenu.length < 1 ? (
                                      <li
                                        key={i}
                                        className={
                                          colorMode === "light"
                                            ? "px-2 py-2 font-medium li-height bottom-border-e2e8f0 sm-fsize14"
                                            : "px-2 py-2 font-medium cust-textColor li-height bottom-border-4f5765 sm-fsize14"
                                        }
                                      >
                                        <NavLink
                                          to={`/${e.pageData[0].page}`}
                                          onClick={onCloseMenu}
                                        >
                                          {e.menuName}
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    )}

                                    <Accordion allowToggle>
                                      {e.subMenu.length > 0 ? (
                                        <div className="">
                                          {isMegaMenu(e.subMenu) ? (
                                            <AccordionItem borderTopWidth={0}>
                                              <h2>
                                                <AccordionButton
                                                  backgroundColor="transparent"
                                                  py={2}
                                                  px={2}
                                                >
                                                  <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                    className="cust-textColor font-semibold text-black sm-fsize14"
                                                  >
                                                    {e.menuName}
                                                  </Box>
                                                  <AccordionIcon className="text-primary" />
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel p={0}>
                                                <div className="cust-bgColor header-6-megaMenu grid grid-cols-1">
                                                  {e.subMenu &&
                                                  e.subMenu.length > 0
                                                    ? e.subMenu.map((item) => (
                                                        <div className="header-6-megaMenu-menu">
                                                          <div className="flex items-center mb-2">
                                                            <Image
                                                              src={`${process.env.REACT_APP_STORAGE_URL}${item.menuImage[0]}`}
                                                              alt=""
                                                              className="w-6 h-6 object-contain rounded-full"
                                                            />
                                                            <h3 className="ml-2 cust-textColor">
                                                              {item.menutype ===
                                                              "page" ? (
                                                                <NavLink
                                                                  onClick={
                                                                    onCloseMenu
                                                                  }
                                                                  className="fsize14 font-semibold text-black cust-textColor"
                                                                >
                                                                  {
                                                                    item.menuName
                                                                  }
                                                                </NavLink>
                                                              ) : (
                                                                <a
                                                                  target="_blank"
                                                                  rel="noreferrer"
                                                                  className="cust-textColor"
                                                                  href={
                                                                    item.menuData.startsWith(
                                                                      "http"
                                                                    )
                                                                      ? item.menuData
                                                                      : `http://${item.menuData}`
                                                                  }
                                                                >
                                                                  {
                                                                    item.menuName
                                                                  }
                                                                </a>
                                                              )}
                                                            </h3>
                                                          </div>
                                                          <ul className="">
                                                            {item.subMenu &&
                                                            item.subMenu
                                                              .length > 0
                                                              ? item.subMenu.map(
                                                                  (i) => (
                                                                    <ul className="list-none  pb-2">
                                                                      {i.menutype ===
                                                                      "page" ? (
                                                                        <li>
                                                                          <NavLink
                                                                            to={`/${i.pageData[0].page}`}
                                                                            className="textColor-light header-6-megaMenu-menu-link text-black fsize14"
                                                                            onClick={
                                                                              onCloseMenu
                                                                            }
                                                                          >
                                                                            {
                                                                              i.menuName
                                                                            }
                                                                          </NavLink>
                                                                        </li>
                                                                      ) : (
                                                                        <li>
                                                                          <NavLink
                                                                            onClick={
                                                                              onCloseMenu
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="textColor-light header-6-megaMenu-menu-link text-black fsize14"
                                                                            href={
                                                                              i.menuData.startsWith(
                                                                                "http"
                                                                              )
                                                                                ? i.menuData
                                                                                : `http://${i.menuData}`
                                                                            }
                                                                          >
                                                                            {
                                                                              i.menuName
                                                                            }
                                                                          </NavLink>
                                                                        </li>
                                                                      )}
                                                                    </ul>
                                                                  )
                                                                )
                                                              : ""}

                                                            {/* <li>
                                                  <NavLink
                                                    to=""
                                                    className="header-6-megaMenu-menu-link fsize14"
                                                  >
                                                    Link 2
                                                  </NavLink>
                                                </li>
                                                <li>
                                                  <NavLink
                                                    to=""
                                                    className="header-6-megaMenu-menu-link fsize14"
                                                  >
                                                    Link 3
                                                  </NavLink>
                                                </li> */}
                                                          </ul>
                                                        </div>
                                                      ))
                                                    : ""}
                                                </div>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          ) : (
                                            <AccordionItem borderTopWidth={0}>
                                              <h2>
                                                <AccordionButton
                                                  backgroundColor="transparent"
                                                  py={2}
                                                  px={2}
                                                >
                                                  <Box
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                    className="cust-textColor text-black font-semibold sm-fsize14"
                                                  >
                                                    {e.menuName}
                                                  </Box>
                                                  <AccordionIcon className="text-primary" />
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel p={0}>
                                                <div className="cust-bgColor header-6-megaMenu grid grid-cols-1">
                                                  {getallCaseStudyIndustries &&
                                                  getallCaseStudyIndustries.result &&
                                                  getallCaseStudyIndustries
                                                    .result.length > 0
                                                    ? getallCaseStudyIndustries.result.map(
                                                        (ind) => (
                                                          <div className="header-6-megaMenu-menu">
                                                            <div className="flex items-center mb-0">
                                                              <h3 className="ml-0 cust-textColor">
                                                                <NavLink
                                                                  to="/usecase-list"
                                                                  className="fsize14"
                                                                  onClick={
                                                                    onCloseMenu
                                                                  }
                                                                >
                                                                  {
                                                                    ind.industrytype
                                                                  }
                                                                </NavLink>
                                                              </h3>
                                                            </div>

                                                            {/* <li>
                                                  <NavLink
                                                    to=""
                                                    className="header-6-megaMenu-menu-link fsize14"
                                                  >
                                                    Link 2
                                                  </NavLink>
                                                </li>
                                                <li>
                                                  <NavLink
                                                    to=""
                                                    className="header-6-megaMenu-menu-link fsize14"
                                                  >
                                                    Link 3
                                                  </NavLink>
                                                </li> */}
                                                          </div>
                                                        )
                                                      )
                                                    : ""}
                                                </div>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Accordion>
                                  </>
                                ))
                              : ""}
                            <Accordion allowToggle>
                              <AccordionItem borderTopWidth={0}>
                                <h2>
                                  <AccordionButton py={2} px={2}>
                                    <Box
                                      as="span"
                                      flex="1"
                                      textAlign="left"
                                      className="cust-textColor text-black font-semibold sm-fsize14"
                                    >
                                      About
                                    </Box>
                                    <AccordionIcon className="text-primary" />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} pt={0}>
                                  <ul className="list-none pt-2">
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink
                                        to="/about-us"
                                        onClick={onCloseMenu}
                                      >
                                        About us
                                      </NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink
                                        to="/careers"
                                        onClick={onCloseMenu}
                                      >
                                        Careers
                                      </NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink
                                        to="/contact-us"
                                        onClick={onCloseMenu}
                                      >
                                        Contact us
                                      </NavLink>
                                    </li>
                                    {/* <li className="cust-textColor fsize14 pb-2">
                                      <NavLink
                                        to="/investor-relations"
                                        onClick={onCloseMenu}
                                      >
                                        Investor Relations
                                      </NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14">
                                      <NavLink
                                        to="/teams"
                                        onClick={onCloseMenu}
                                      >
                                        Management Team
                                      </NavLink>
                                    </li> */}
                                  </ul>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </ul>

                          <div className="w-full mt-8 gap-2 flex items-center">
                            <NavLink to="/register">
                              <div class="gradient-border">
                                <div class="flex items-center btn-circle-header">
                                  <img
                                    src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/reward.png"
                                    alt="reward"
                                    class="reward-img object-contain"
                                  />
                                  <p class="ml-1 textwhite font-semibold fsize12">
                                    Draft Requirements
                                  </p>
                                  {/* <FaLongArrowAltRight className="textwhite ml-1 textColor-light sm-arrow" /> */}
                                </div>
                              </div>
                            </NavLink>
                            <NavLink
                              to="https://meet.brained.app/#/customer/book-a-demo"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                variant="outline"
                                fontWeight={500}
                                className="fsize14  text-primary border-primary rounded-100px"
                              >
                                Book a Demo
                              </Button>
                            </NavLink>
                          </div>
                        </DrawerBody>
                        <DrawerFooter display="block">
                          <ul className="flex items-center gap-2 list-none mb-3">
                            {/* <li className="px-2 fsize12 font-semibold cust-textColor">
                              Log in
                            </li> */}
                            <li className="px-2 fsize12 font-semibold cust-textColor">
                              Customer Support
                            </li>
                            {/* <li className="px-2 fsize12 font-semibold">
                              About
                            </li> */}
                          </ul>
                        </DrawerFooter>
                      </DrawerContent>
                    </Drawer>
                  </div>
                </div>
                <ul className="pl-12 list-none subMenu flex items-center ">
                  {/* {menuData
                    ? menuData.map((e, i) => (
                        <>
                          {e.menutype === "page" && e.subMenu.length < 1 ? (
                            <li
                              key={i}
                              className="px-8 flex items-center li-height "
                            >
                              <NavLink to={`/${e.pageData[0].page}`}>
                                {e.menuName}
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )}

                        
                        </>
                      ))
                    : ""} */}

                  {menuData
                    ? menuData.map((e, i) => (
                        <>
                          {console.log(typeof e.subMenu, "array")}
                          {e.menutype === "page" ? (
                            <li className="px-8 flex items-center li-height hovermenu">
                              <>
                                {e.subMenu.length > 0 ? (
                                  <div className="hovermenu cust-textColor">
                                    {isMegaMenu(e.subMenu) ? (
                                      <>
                                        {e.menuName}
                                        <ChevronDownIcon className="ml-1" />
                                        <div className="showheader">
                                          <div className="cust-bgColor header-6-megaMenu grid lg:grid-cols-4 md:grid-cols-2 gap-6">
                                            {e.subMenu && e.subMenu.length > 0
                                              ? e.subMenu.map((item) => (
                                                  <div className="header-6-megaMenu-menu">
                                                    <div className="flex items-center mb-4">
                                                      <Image
                                                        src={`${process.env.REACT_APP_STORAGE_URL}${item.menuImage[0]}`}
                                                        alt=""
                                                        className="w-12 h-12 rounded-full object-contain"
                                                      />

                                                      <h3 className="ml-2 font-medium text-primary cust-textColor">
                                                        {item.menutype ===
                                                        "page" ? (
                                                          <NavLink
                                                            to={`/${item.pageData[0].page}`}
                                                          >
                                                            {item.menuName}
                                                          </NavLink>
                                                        ) : (
                                                          <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="textColor-light header-6-megaMenu-menu-link pb-4 text-black"
                                                            href={
                                                              item.menuData.startsWith(
                                                                "http"
                                                              )
                                                                ? item.menuData
                                                                : `http://${item.menuData}`
                                                            }
                                                          >
                                                            {i.menuName}
                                                          </a>
                                                        )}
                                                      </h3>
                                                    </div>
                                                    <ul className="list-none">
                                                      {item.subMenu &&
                                                      item.subMenu.length > 0
                                                        ? item.subMenu.map(
                                                            (i) => (
                                                              <>
                                                                {i.menutype ===
                                                                "page" ? (
                                                                  <NavLink
                                                                    to={`/${i.pageData[0].page}`}
                                                                    className="textColor-light header-6-megaMenu-menu-link pb-4 text-black"
                                                                  >
                                                                    {i.menuName}
                                                                  </NavLink>
                                                                ) : (
                                                                  <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="textColor-light header-6-megaMenu-menu-link pb-4 text-black"
                                                                    href={
                                                                      i.menuData.startsWith(
                                                                        "http"
                                                                      )
                                                                        ? i.menuData
                                                                        : `http://${i.menuData}`
                                                                    }
                                                                  >
                                                                    {i.menuName}
                                                                  </a>
                                                                )}
                                                              </>
                                                            )
                                                          )
                                                        : ""}
                                                    </ul>
                                                  </div>
                                                ))
                                              : ""}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* {
                                        e.subMenu.map((item) => ( */}
                                        <div className="flex items-center header6Height relative">
                                          <div className="cust-textColor sm-fsize14">
                                            {e.menuName}
                                            <ChevronDownIcon className="ml-1" />
                                          </div>

                                          <div className="showheader">
                                            {/* <ul className="header-6-dropdown  z-10">
                                              {e.subMenu.map((sub) => {
                                                return (
                                                  <li
                                                    className={
                                                      colorMode === "light"
                                                        ? "header-6-dropdown-item bg-white fsize14 text-black"
                                                        : "header-6-dropdown-item cust-bgColor textColor-light fsize14 text-black"
                                                    }
                                                  >
                                                    {sub.menutype === "page" ? (
                                                      <NavLink
                                                        className="cursor-pointer"
                                                        to={`/${sub?.pageData[0]?.page}`}
                                                      >
                                                        {sub.menuName}
                                                      </NavLink>
                                                    ) : (
                                                      <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className=""
                                                        href={
                                                          sub.menuData.startsWith(
                                                            "http"
                                                          )
                                                            ? sub.menuData
                                                            : `http://${sub.menuData}`
                                                        }
                                                      >
                                                        {sub.menuName}
                                                      </a>
                                                    )}
                                                  </li>
                                                );
                                              })}
                                            </ul> */}
                                            <ul className="header-6-dropdown  z-10">
                                              {getallCaseStudyIndustries &&
                                              getallCaseStudyIndustries.result &&
                                              getallCaseStudyIndustries.result
                                                .length > 0
                                                ? getallCaseStudyIndustries.result.map(
                                                    (ind) => {
                                                      return (
                                                        <li
                                                          className={
                                                            colorMode ===
                                                            "light"
                                                              ? "header-6-dropdown-item bg-white fsize14 text-black"
                                                              : "header-6-dropdown-item cust-bgColor textColor-light fsize14 "
                                                          }
                                                        >
                                                          <NavLink
                                                            className="cursor-pointer"
                                                            to="/usecase-list"
                                                          >
                                                            {ind.industrytype}
                                                          </NavLink>
                                                        </li>
                                                      );
                                                    }
                                                  )
                                                : ""}
                                            </ul>
                                          </div>
                                        </div>
                                        {/* ))} */}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  e.subMenu.length === 0 && (
                                    <NavLink
                                      to={`/${e.pageData[0].page}`}
                                      className="cust-textColor"
                                    >
                                      {e.menuName}
                                    </NavLink>
                                  )
                                )}
                              </>
                              {/* submenu */}
                              {/* {e.subMenu.length < 0
                                ? e.subMenu.map((item) => (
                                    <div className="px-4">
                                      <Menu>
                                        <MenuButton
                                          px={0}
                                          py={2}
                                          transition="all 0.2s"
                                          borderRadius="md"
                                          borderWidth="0px"
                                          className="font-medium navbartextColor-light"
                                          // _hover={{ bg: 'gray.400' }}
                                          // _expanded={{ bg: 'blue.400' }}
                                          // _focus={{ boxShadow: 'outline' }}
                                        >
                                          {item.menuName} <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                          {e.subMenu.map((sub, i) => {
                                            return (
                                              <NavLink
                                                to={`/${sub.pageData[0].page}`}
                                              >
                                                <MenuItem
                                                  className="navbartextColor-light"
                                                  key={i}
                                                >
                                                  {sub.menuName}
                                                </MenuItem>
                                              </NavLink>
                                            );
                                          })}
                                        </MenuList>
                                      </Menu>
                                    </div>
                                  ))
                                : ""} */}

                              {/* mega menu  */}
                            </li>
                          ) : (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="cust-textColor"
                              href={
                                e.menuData.startsWith("http")
                                  ? e.menuData
                                  : `http://${e.menuData}`
                              }
                            >
                              {e.menuName}
                            </a>
                          )}
                        </>
                      ))
                    : ""}
                </ul>
              </div>
              <ul className="flex items-center list-none">
                <li className="pl-2">
                  <NavLink to="/register">
                    <div class="gradient-border">
                      <div className="flex items-center btn-circle-header">
                        <img
                          src="https://nexuscluster.blob.core.windows.net/server01/brained-admin/reward.png"
                          alt="reward"
                          className="reward-img object-contain"
                        />
                        <p className="ml-1 textwhite font-semibold fsize12">
                          Draft Requirements
                        </p>
                        <FaLongArrowAltRight className="textwhite ml-1 textColor-light sm-arrow" />
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li className="pl-2">
                  <NavLink
                    to="https://meet.brained.app/#/customer/book-a-demo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="outline"
                      fontWeight={500}
                      className="fsize14  text-primary border-primary rounded-100px"
                    >
                      Book a Demo
                    </Button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Card>
      {isVisible && (
        <div className="bg-brainedSecond-color p-2">
          <div className="container mx-auto flex justify-center items-center lg:gap-6 md:gap-6 gap-3">
            <p className="fsize12 sm-fsize10">
              BRAINED 3.0 prototype any Enterprise Platform Requirement in 24
              hours.
            </p>
            <a href="/new-updates" rel="noreferrer">
              <Button
                height="fit-content"
                py={2}
                className="bg-fff text-black fsize12 sm-fsize10"
              >
                Know more
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/rocket.png"
                  className=" pl-2 w-6 h-4 obhect-contain"
                  alt="rocket"
                />
              </Button>
            </a>
            <CloseIcon
              className="cursor-pointer fsize12"
              svgClasses="w-4 h-4"
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header6;
