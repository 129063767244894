import React from "react";
import { useState } from "react";
import { useColorMode } from "@chakra-ui/react";
import { useStopwatch, useTime, useTimer } from "react-timer-hook";
import { useSelector } from "react-redux";

const CountdownTimer2 = () => {
  const { colorMode } = useColorMode();

  const expiryTimestamp = new Date(2024, 7, 9);
  //   expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 600);

  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp });

  return (
    <section
      className={
        colorMode === "light"
          ? " lg:pt-16 md:pt-16 pt-8 "
          : "lg:pt-10 md:pt-8 pt-4 "
      }
    >
      <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
        <h2 className="font-semibold text-primary cust-textColor fsize34 md-fsize24 sm-fsize20  relative inline-block">
          Offer ends on 9th August, 2024
        </h2>

        <p className=" fsize16 sm-fsize14 mt-2 textColor-light">
          Transform your business with our AI-powered Web & Mobile App
          Developemnt
        </p>
      </div>
      <div className=" w-full  flex items-center justify-center">
        <div className="flex items-center justify-center lg:gap-8 md:gap-8 gap-4">
          <div className="mx-auto">
            <div className="bg-brainedlight rounded-lg lg:w-28 lg:h-28 md:w-28 md:h-28 w-16 h-16 flex items-center justify-center">
              <p className=" font-bold text-center text-primary fsize60 md-fsize60 sm-fsize36">
                {days}
              </p>
            </div>
            <p className="text-primary lg:mt-3 md:mt-2 mt-1 fsize16 md-fsize16 sm-fsize12 text-center">
              DAY(S)
            </p>
          </div>
          <div className="mx-auto">
            <div className="bg-brainedlight rounded-lg lg:w-28 lg:h-28 md:w-28 md:h-28 w-16 h-16 flex items-center justify-center">
              <p className=" font-bold text-center text-primary fsize60 md-fsize60 sm-fsize36">
                {hours}
              </p>
            </div>
            <p className="text-primary lg:mt-3 md:mt-2 mt-1 fsize16 md-fsize16 sm-fsize12 text-center">
              HOUR(S)
            </p>
          </div>
          <div className="mx-auto">
            <div className="bg-brainedlight rounded-lg lg:w-28 lg:h-28 md:w-28 md:h-28 w-16 h-16 flex items-center justify-center">
              <p className=" font-bold text-center text-primary fsize60 md-fsize60 sm-fsize36">
                {minutes}
              </p>
            </div>
            <p className="text-primary lg:mt-3 md:mt-2 mt-1 fsize16 md-fsize16 sm-fsize12 text-center">
              MINUTE(S)
            </p>
          </div>
          <div className="mx-auto">
            <div className="bg-brainedlight rounded-lg lg:w-28 lg:h-28 md:w-28 md:h-28 w-16 h-16 flex items-center justify-center">
              <p className=" font-bold text-center text-primary fsize60 md-fsize60 sm-fsize36">
                {seconds}
              </p>
            </div>
            <p className="text-primary lg:mt-3 md:mt-2 mt-1 fsize16 md-fsize16 sm-fsize12 text-center">
              SECOND(S)
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountdownTimer2;
