import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux/es/exports";
import GetEmotion from "./GetEmotion";
import { debounce } from "lodash";
import { openAiClient } from "./OpenAiClient";
import {
  setConversationHistory,
  setRecognizedText,
  setuseGpt,
  setRecognizer,
  setInputText,
  setisGPTResponseReceived,
  setEmotion,
} from "../../../../../redux/Aiassist/Aiassist-actions";
import getCurrentTime from "./getCurrentTime";
const SendToOpenAi = debounce(async (dispatch, ConversationHistory) => {
  let TempConversationHistory = ConversationHistory.reduce((acc, ele) => {
    const { timestamp, ...rest } = ele;
    acc.push(rest);
    return acc;
  }, []);
  // console.log(TempConversationHistory);
  try {
    const completions = await openAiClient.getChatCompletions(
      process.env.REACT_APP_OPEN_AI_DEPLOYMENT_NAME,
      TempConversationHistory
    );

    const gptResponse = completions.choices[0].message.content;

    let emotion = await GetEmotion(gptResponse);
    dispatch(setEmotion(emotion));
    dispatch(setInputText(gptResponse));
    dispatch(setisGPTResponseReceived(true));
    dispatch(
      setConversationHistory({
        role: "assistant",
        content: gptResponse,
        timestamp: getCurrentTime(),
      })
    );
    dispatch(setuseGpt(false));
    // console.log(emotion, 'emotion');
  } catch (error) {
    console.error(error);
  }
}, 500);
export default SendToOpenAi;
