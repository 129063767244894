import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "@chakra-ui/react";
// import FeatherIcon from "feather-icons-react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
// import UserEffect from "../../components/usereeffect/UserEffect";
import { handleScroll } from "../../components/fadeeffect/FadeEffect";
import { PhotoProvider, PhotoView } from "react-photo-view";

const CaseStudyServices = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const selectedCaseStudyData = useSelector(
    (state) => state.caseStudy.SingleCaseStudy
  );

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto   lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className=" w-full mb-8">
        <h3
          id="8537780444"
          className="dynamicStyle inline-block text-primary fsize24 sm-fsize16 font-semibold clr-ddd9d8 lg:mb-2 textColor-light"
        >
          {data ? data["8537780444"] : "Lorem Ipsum"}
        </h3>
        <h1
          id="0554369848"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["0554369848"]
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          }}
          className="dynamicStyle text-black fsize32 md:fsize24 sm-fsize20 font-semibold  cust-textColor"
        ></h1>
        {/* <p
          id="6941527127"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["6941527127"]
              : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          }}
          className="dynamicStyle fsize18 sm-fsize14 lg:mb-0 md:mb-0 mb-4 textColor-light"
        ></p> */}
      </div>

      <div>
        <div className="mb-12">
          <p
            id="4471496426"
            className="dynamicStyle font-semibold fsize20 sm-fsize20"
          >
            {data ? data["4471496426"] : "Lorem Ipsum"}
          </p>

          <div className=" mt-6">
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              className="mySwiper casestudy"
              breakpoints={{
                1536: {
                  slidesPerView: 3.8,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3.8,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3.8,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
                325: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>

              {selectedCaseStudyData?.wireframeimages?.length > 0 &&
              selectedCaseStudyData?.wireframeimages
                ? selectedCaseStudyData?.wireframeimages.map((e, index) => {
                    return (
                      <SwiperSlide className="mx-2">
                        <PhotoProvider key={index}>
                          <PhotoView
                            src={`https://nexuscluster.blob.core.windows.net/server01/${e}`}
                            className=" lg:h-80 md:60 h-60"
                          >
                            <Image
                              className="lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                              src={`https://nexuscluster.blob.core.windows.net/server01/${e}`}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="img"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </SwiperSlide>
                    );
                  })
                : "no-data"}
              {/* <div
                  className="lg:h-80 md:60 h-60 dynamicStyle2"
                  id="7437421370"
                >
                  <Image
                    className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                    src={data ? getImage(data["7437421370"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                </div> */}
            </Swiper>
          </div>
        </div>

        {/* mobile */}

        <div className="">
          <div className="cust-textColor font-semibold ">
            <p className="dynamicStyle fsize20 sm-fsize20" id="3339214302">
              {data ? data["3339214302"] : "Lorem Ipsum"}
            </p>
          </div>
          <div className="mt-6">
            {/* <div className=" lg:h-80 md:60 h-60 ">
                <Image
                  className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                  src={data ? getImage(data["8836459571"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div className=" lg:h-80 md:60 h-60 ">
                <Image
                  className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                  src={data ? getImage(data["9303796134"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div className=" lg:h-80 md:60 h-60 ">
                <Image
                  className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                  src={data ? getImage(data["8956020627"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div className=" lg:h-80 md:60 h-60 ">
                <Image
                  className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                  src={data ? getImage(data["3227194009"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>

              <div className=" lg:h-80 md:60 h-60 ">
                <Image
                  className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                  src={data ? getImage(data["3777368327"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div> */}
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              className="mySwiper casestudy"
              breakpoints={{
                1536: {
                  slidesPerView: 3.8,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3.8,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3.8,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
                325: {
                  slidesPerView: 1.2,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {selectedCaseStudyData?.wireframeimages?.length > 0 &&
              selectedCaseStudyData?.wireframeimages
                ? selectedCaseStudyData?.wireframeimages.map((e, index) => {
                    return (
                      <SwiperSlide className="mx-2">
                        <PhotoProvider key={index}>
                          <PhotoView
                            src={`https://nexuscluster.blob.core.windows.net/server01/${e}`}
                            className=" lg:h-80 md:60 h-60"
                          >
                            <Image
                              className="lg:h-80 md:60 h-60 w-full rounded-lg object-cover mx-2"
                              src={`https://nexuscluster.blob.core.windows.net/server01/${e}`}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="img"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </SwiperSlide>
                    );
                  })
                : "no-data"}

              {/* <div
                  className="lg:h-80 md:60 h-60 dynamicStyle2"
                  id="7437421370"
                >
                  <Image
                    className=" lg:h-80 md:60 h-60 w-full rounded-lg object-cover"
                    src={data ? getImage(data["7437421370"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                </div> */}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudyServices;
