import React, { useEffect, useRef, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from '@chakra-ui/react';

const AboutSection32 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto  lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5">
      <div className="flex lg:gap-8 md:gap-8 gap-6 sm-reverse">
        <div className="lg:w-1/2 md:w-1/2 w-full flex items-center">
          <div>
            <h3 className="fsize32 md-fsize28 sm-fsize20 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor">
              Lorem ipsum dolor sit amet.
            </h3>

            <Tabs position="relative" isFitted variant="unstyled">
              <TabList>
                <Tab className="font-semibold fsize22 cust-textColor">
                  Our Promise
                </Tab>
                <Tab className="font-semibold fsize22 cust-textColor">
                  Our Mission
                </Tab>
                <Tab className="font-semibold fsize22 cust-textColor">
                  Our Vision
                </Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <p
                    className={
                      colorMode === 'light'
                        ? 'fsize18 md-fsize16 sm-fsize14'
                        : 'fsize18 md-fsize16 sm-fsize14 textColor-light'
                    }
                  >
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Veniam quisquam reprehenderit eius sit modi architecto
                    recusandae excepturi amet, quidem mollitia!
                  </p>
                </TabPanel>
                <TabPanel>
                  <p
                    className={
                      colorMode === 'light'
                        ? 'fsize18 md-fsize16 sm-fsize14'
                        : 'fsize18 md-fsize16 sm-fsize14 textColor-light'
                    }
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quis neque veniam vero itaque voluptatum dicta nisi
                    temporibus minus repellendus asperiores?
                  </p>
                </TabPanel>
                <TabPanel>
                  <p
                    className={
                      colorMode === 'light'
                        ? 'fsize18 md-fsize16 sm-fsize14'
                        : 'fsize18 md-fsize16 sm-fsize14 textColor-light'
                    }
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Nesciunt, facere!
                  </p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <div className="h-450px sm-h250px w-full">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
              alt="img"
              className="h-450px sm-h250px w-full rounded-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection32;
