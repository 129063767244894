import React from 'react';
import { Image } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay, Navigation } from 'swiper/modules';
const Testimonial12 = () => {
  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  return (
    <section className="lg:py-16 md:py-12 py-8">
      <div className="h-650px sm-h550px relative">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          alt="img"
          className="h-650px sm-h550px w-full"
        />

        <div className="absolute top-0 left-0 h-650px sm-h550px flex justify-center items-center w-full">
         <div className='container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5'>
         <div className="pb-6">
            <p className="text-primary fsize40 md-fsize32 sm-fsize20 cust-textColor font-semibold">
              What our client say's
            </p>
          </div>
          <div className="">
            {/* <div className="swiper-button custswiper image-swiper-button-next">
          <IoIosArrowForward />
        </div>
        <div className="swiper-button custswiper image-swiper-button-prev">
          <IoIosArrowBack />
        </div> */}
            <Swiper
              spaceBetween={20}
              navigation={true}
              modules={[Navigation]}
              className='mySwiper'
              breakpoints={{
                1536: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                820: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                425: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                325: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
         </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial12;
