import apis from '../../apis/client/client';

export const getClient = payload => {
  let record;
  return async dispatch => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      let { data } = await apis.getClient(payload2);
      
      if (data) {
        dispatch(setClient(data.data.data));
      }
      
    } catch (error) {
      console.log(error);
    }
    return;
  };
};

export const getAllClient = payload => {
  let record;
  return async dispatch => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        isDeleted: false,
        ...payload,
      };
      let { data } = await apis.getAllClients(payload2);
     
      if (data) {
        dispatch(setAllClient(data.data));
      }
      
    } catch (error) {
      console.log(error);
    }
    return;
  };
};

export const setClient = data => {
  return {
    type: 'setClient',
    payload: data,
  };
};

export const setAllClient = data => {
  return {
    type: 'setAllClient',
    payload: data,
  };
};
