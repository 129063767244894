import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import ScrollTrigger from "react-scroll-trigger";
import { Image, useColorMode } from "@chakra-ui/react";
import FeatherIcon from "feather-icons-react";

const CounterSection5 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [counterOn, setCounterOn] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="bg-primary lg:py-16 md:py-16 py-4 lg:px-16 md:px-4 px-4">
      <div className="lg:flex md:flex items-center justify-between w-full container mx-auto">
        <div className="">
          <h2
            id="32152900451"
            className="dynamicStyle fsize40 md-fsize36 sm-fsize24 font-semibold cust-textColor clr-fff"
          >
            {data ? data["32152900451"] : "Our Experience"}
          </h2>
          <p
            id="8227542373"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["8227542373"]
                : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            }}
            className="dynamicStyle fsize20 md-fsize18 sm-fsize16 font-medium text-secondary lg:py-4 md:py-4 py-2 textColor-light"
          ></p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-12 lg:mt-0 md:mt-0 mt-3">
          <div className=" flex justify-center items-center">
            <div>
              <div className="text-center items-center justify-center text-primary">
                <p className=" fsize20 md-fsize20 sm-fsize18 font-semibold pb-1 clr-fff cust-textColor">
                  Draft Requirements <br />
                </p>

                <div className="flex items-center">
                  <FeatherIcon
                    icon="chevron-left"
                    size={30}
                    className="text-white"
                  />
                  <div className="flex items-baseline justify-center">
                    <ScrollTrigger
                      onEnter={() => setCounterOn(true)}
                      onExit={() => setCounterOn(false)}
                    >
                      {counterOn && (
                        <CountUp
                          className="font-semibold fsize50 md-fsize30 sm-fsize24 clr-fff cust-textColor"
                          start={0}
                          end={60}
                          duration={4}
                          delay={0}
                        />
                      )}
                    </ScrollTrigger>
                    <p className="pl-2 fsize20 md-fsize20 sm-fsize18 font-semibold pb-1 clr-fff cust-textColor">
                      Mins
                    </p>
                  </div>
                </div>
              </div>
              {/* <p
                id=""
                className=" text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-secondary textColor-light"
              >
                mins
              </p> */}
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div>
              <p className=" fsize20 text-center md-fsize20 sm-fsize18 font-semibold pb-1 clr-fff cust-textColor">
                Prototype
                <br />
              </p>
              <div className="flex items-baseline justify-center text-primary">
                <FeatherIcon
                  icon="chevron-left"
                  size={30}
                  className="text-white"
                />

                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-semibold fsize50 md-fsize30 sm-fsize24 clr-fff cust-textColor"
                      start={0}
                      end={24}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className="pl-2 fsize20 text-center md-fsize20 sm-fsize18 font-semibold pb-1 clr-fff cust-textColor">
                  Hours
                </p>
              </div>
              {/* <p
                id=""
                className=" text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-secondary textColor-light"
              >
                Launch Prototype
              </p> */}
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div>
              <p className=" fsize20 md-fsize20 sm-fsize18 font-semibold pb-1 clr-fff cust-textColor">
                Customize and Launch <br />
              </p>
              <div className="flex items-baseline justify-center text-primary">
                <FeatherIcon
                  icon="chevron-left"
                  size={30}
                  className="text-white"
                />
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-semibold fsize50 md-fsize30 sm-fsize24 clr-fff cust-textColor"
                      start={0}
                      end={30}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className="pl-2 fsize20 md-fsize20 sm-fsize18 font-semibold pb-1 clr-fff cust-textColor">
                  Days
                </p>
              </div>
              {/* <p
                id=""
                className=" text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-secondary textColor-light"
              >
                Fully Customize the Platform{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection5;
