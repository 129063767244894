import React, { useEffect, useRef, useState } from "react";
import { Image, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { handleScroll } from "./fadeeffect/FadeEffect";

const HeroSection33 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const handleClick1 = () => {
    const element = document.getElementById("form");
    element.scrollIntoView();
  };
  return (
    <div className="herosection33-img lg:relative md:relative">
      <div id="9576662005" className="w-full dynamicStyle2 ">
        <Image
          src={data ? getImage(data["9576662005"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          className="herosection33-img object-fill  lg:block md:block block"
        />
      </div>
      <div className="lg:absolute md:absolute top-0 left-0 w-full flex items-center herosection33-img sm-mtop-minus13rem">
        <div className="lg:px-12 md:px-8 px-5 lg:py-0 md:py-0 py-10 lg:flex md:flex sm-reverse items-center container mx-auto">
          <div className="lg:w-1/2 md:w-1/2 w-full text-center">
            <div className="flex items-center justify-center lg:gap-6 gap-3">
              <div className="red_line"></div>
              <h1
                id="3929984808"
                className="dynamicStyle fsize40 sm-fsize16 font-semibold"
              >
                {data ? data["3929984808"] : "100%"}
              </h1>
              <div className="grey_line"></div>
            </div>
            <h1
              id="6790544244"
              className="dynamicStyle fsize60 sm-fsize40 font-bold"
            >
              {data ? data["6790544244"] : "CASHBACK"}
            </h1>
            <p
              id="7580447702"
              dangerouslySetInnerHTML={{
                __html: data ? data["7580447702"] : "Lorem Ipsum",
              }}
              className="dynamicStyle fsize16 sm-fsize14 font-medium"
            >
              {/* on your 1st Web and Mobile App Project */}
            </p>
            <Button
              id="0526494548"
              px={20}
              py={6}
              borderRadius={30}
              onClick={handleClick1}
              className="dynamicStyle bg-f13645 mt-4 clr-fff"
            >
              {data ? data["0526494548"] : "Get Offer"}
            </Button>

            <div className="lg:mt-6 mt-6 flex justify-center items-center gap-1">
              <h1
                id="4432750640"
                className="dynamicStyle fsize16 sm-fsize14 font-medium text-black"
              >
                {data ? data["4432750640"] : "Offer Valid till"}
              </h1>
              <h1
                id="8597205594"
                className="dynamicStyle fsize16 sm-fsize14 font-medium text-primary"
              >
                {data ? data["8597205594"] : "9th August"}
              </h1>
            </div>
            <div className="flex items-center justify-center gap-2">
              <div className="red_line"></div>
              <h1
                id="2772480981"
                className="dynamicStyle fsize44 sm-fsize24 font-bold"
              >
                {data ? data["2772480981"] : "+65 9867 6336"}
              </h1>
              <div className="grey_line"></div>
            </div>
            <p
              id="3481984474"
              className="dynamicStyle fsize16 sm-fsize14 font-medium"
            >
              {data ? data["3481984474"] : "brained.app/independence"}
            </p>
          </div>
          <div
            ref={elementRef}
            className={`fade-right lg:w-1/2 md:w-1/2 w-full lg:pl-10 md:pl-5 pl-0 lg:mb-0 md:mb-0 mb-6 ${
              isVisible ? "visibleright" : ""
            } `}
          >
            <div id="6517346751" className="w-full dynamicStyle2">
              <Image
                src={data ? getImage(data["6517346751"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="hero"
                className="heroside-img_cash object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection33;
