import React from "react";
import { Button, Image } from "@chakra-ui/react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import { NavLink } from "react-router-dom";

const NoPageFound2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="h-100vh relative flex items-center ">
      <div className="container mx-auto lg:px-12 px-4 lg:py-20 py-8">
        <div className="">
          <div id="" className="w-full">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/nhrdn/linkdocument/document-2QkhNQwH8alK7PjhiCLKI-1719991049"
              className="pagenotfound2-image mx-auto object-contain"
              alt="women"
              //   fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="text-center mt-4">
            <p className="fsize20 md-fsize18 sm-fsize16 text-primary text-center  font-semibold mx-auto w-7/12 ">
              This page doesn't exist.
            </p>
            <NavLink to="/">
              <Button
                variant="outline"
                colorScheme="primary"
                className="lg:mt-6 md:mt-6 mt-4 flex gap-3 text-primary fsize14"
              >
                Back to Home
                <FaLongArrowAltRight />
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoPageFound2;
