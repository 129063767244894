import apis from "../../apis/client/Forms/careers";

export const getAllJobPosting = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllJobPosting(payload1);

      if (data) {
        dispatch(setJobPosting(data.data));
        record = data.data;
      }
    } catch (error) {}
  };
};

export const getSingleJobPosting = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllJobPosting(payload1);

      if (data?.data?.result?.length > 0) {
        dispatch(setSingleJobPosting(data?.data?.result[0]));
        record = data.data;
      }
    } catch (error) {}
  };
};

export const getAllJobApplications = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload1 = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllJobApplications(payload1);
      if (data) {
        dispatch(setJobApplication(data.data));
        record = data.data;
      }
    } catch (error) {}
  };
};

export const setJobPosting = (data) => {
  return {
    type: "SET_ALL_JOB_POSTING",
    payload: data,
  };
};

export const setSingleJobPosting = (data) => {
  return {
    type: "SET_SINGLE_JOB_POSTING",
    payload: data,
  };
};

export const setJobApplication = (data) => {
  return {
    type: "SET_ALL_JOB_APPLICATION",
    payload: data,
  };
};
export const setSelectedCareer = (data) => {
  return {
    type: "SET_SELECTED_CAREER",
    payload: data,
  };
};
