import React from 'react';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AboutSection30 = ({ pageName }) => {

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-16 px-5 py-10">
      <div className="lg:grid md:grid lg:grid-rows-3 md:grid-rows-2 grid-flow-col lg:gap-16 md:gap-10">
        <div
          id="1814681852"
          className="dynamicStyle2 row-span-2-3 md-row-span-1-1 lg:mb-0 md:mb-0 mb-4"
        >
          <Image
            className="w-full"
            src={data ? getImage(data['1814681852']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
          />
        </div>
        <div className="lg:mb-0 md:mb-0 mb-6">
          <p
            id="3713759620"
            className="dynamicStyle fsize32 sm-fsize20 font-semibold lg:pb-4 md:pb-4  cust-textColor"
          >
            {data ? data['3713759620'] : 'Lorem Ipsum'}
          </p>
          <p
            id="5445938947"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['5445938947']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle lg:py-4 sm-fsize14 textColor-light"
          ></p>
          <Button
            colorScheme="transparent"
            px="0"
            id="0992184667"
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary text-primary border-0 text-dark textColor-light sm-fsize13 theme-btn cust-textColor'
                : 'dynamicStyle theme-btn cust-textColor border-0 text-dark textColor-light sm-fsize13'
            }
          >
            <span onClick={() => handleClick('0992184667')}>
              {data ? data['0992184667'] : 'Know More'}
            </span>

            <FeatherIcon
              icon="arrow-right"
              size="18"
              className="textColor-light text-dark ml-2"
            />
          </Button>
        </div>
        <div className="col-span-1 md-col-span-2-1 lg:mb-0 md:mb-0 mb-4">
          <p
            id="7916056085"
            className="dynamicStyle2 fsize32 sm-fsize20 font-semibold lg:pb-4 md:pb-4  cust-textColor"
          >
            {data ? data['7916056085'] : 'Lorem Ipsum'}
          </p>
          <p id="4411134104"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['4411134104']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle lg:py-4 sm-fsize14 textColor-light">
            
          </p>
          <Button
            colorScheme="transparent"
            px="0"
            id="9961921801"
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary text-primary border-0 text-dark textColor-light sm-fsize13 theme-btn cust-textColor'
                : 'dynamicStyle theme-btn cust-textColor border-0 text-dark textColor-light sm-fsize13'
            }
          >
           <span onClick={() => handleClick('9961921801')}>
              {data ? data['9961921801'] : 'Know More'}
            </span>
            <FeatherIcon
              icon="arrow-right"
              size="18"
              className="textColor-light text-dark ml-2"
            />
          </Button>
        </div>
        <div className="row-span-2 col-span-1 md-col-span-2-1 md-row-span-1-1">
          <Image
            className=""
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
          />
        </div>
      </div>
      {/* <div className='grid grid-cols-1'>
      <div className="flex gap-16">
        <div className='w-2/5'>
          <Image
            className="h-500px"
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
          />
        </div>
        <div className='w-3/5'>
          <p className="fsize32 font-semibold pb-4">Lorem Ipsum</p>
          <p className='py-4'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
            veritatis tempora temporibus repellendus voluptas et similique animi
            repudiandae fuga facilis distinctio iusto impedit exercitationem
            cumque, officia vitae dolorum laborum dignissimos?
          </p>
          <Button
            colorScheme="transparent"
            px="0"
            id="2707186619"
            className="border-0 text-dark textColor-light sm-fsize13"
          >
            Know More
            <FeatherIcon
              icon="arrow-right"
              size="18"
              className="textColor-light text-dark ml-2"
            />
          </Button>
        </div>
      </div>
      <div className="flex gap-16">
        
        <div className='w-3/5 flex items-end'>
          <div>
          <p className="fsize32 font-semibold pb-4">Lorem Ipsum</p>
          <p className='py-4'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
            veritatis tempora temporibus repellendus voluptas et similique animi
            repudiandae fuga facilis distinctio iusto impedit exercitationem
            cumque, officia vitae dolorum laborum dignissimos?
          </p>
          <Button
            colorScheme="transparent"
            px="0"
            id="2707186619"
            className="border-0 text-dark textColor-light sm-fsize13"
          >
            Know More
            <FeatherIcon
              icon="arrow-right"
              size="18"
              className="textColor-light text-dark ml-2"
            />
          </Button>
          </div>
        </div>
        <div className='w-2/5'>
          <Image
            className="h-500px"
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
          />
        </div>
      </div>
      </div> */}
    </div>
  );
};

export default AboutSection30;
