import React from "react";
import CaseStudyHeroSection from "./components/CaseStudyHeroSection";
import CaseStudyFeatureCard from "./components/CaseStudyFeatureCard";
import CaseStudyCard from "./components/CaseStudyCard";
import CaseStudyBanner from "./components/CaseStudyBanner";
// import CaseStudyServices from "./components/CaseStudyServices";
import { useSelector } from "react-redux";
import { getSingleCaseStudy } from "../../../redux/caseStudy/cs-action";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
// import CaseStudyAboutSection from "./components/CaseStudyAboutSection";

const CaseStudy = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  console.log(data, "idcheck");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    getQueryData();
  }, [searchParams]);

  const replaceHypenToSpace = (str) => {
    return str.replace(/-+/g, " ");
  };

  const getQueryData = async () => {
    let caseStudyProjectName = searchParams.get("cp");
    if (caseStudyProjectName) {
      console.log(
        replaceHypenToSpace(caseStudyProjectName),
        "caseStudyProjectName"
      );
      let payload = {
        columnFilter: "projectname",
        searchBy: replaceHypenToSpace(caseStudyProjectName),
      };
      await dispatch(getSingleCaseStudy(payload));
    }
  };
  return (
    <div>
      <CaseStudyHeroSection pageName={pageName} />
      <CaseStudyFeatureCard pageName={pageName} />
      {/* <CaseStudyCard /> */}
      {/* <CaseStudyServices pageName={pageName} /> */}
      <CaseStudyBanner pageName={pageName} />

      {/* <CaseStudyAboutSection pageName={pageName} /> */}
    </div>
  );
};

export default CaseStudy;
