import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection31 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();
  
    let selectedStyle = useSelector(
      state => state.brandDetails.brandProfile.businessThemeVariation
    );
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    const handleErorrImage = e => {
      e.target.src =
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg';
    };
  
    useEffect(() => {
      const cleanp1 = handleScroll(elementRef, setIsVisible);
  
      return () => {
        cleanp1();
      };
    }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="lg:flex md:flex sm-reverse">
        <div className="lg:w-1/2 md:w-1/2 w-full flex items-center lg:px-8 md:px-8 lg:mt-0 md:mt-0 mt-4">
          <div>
          <h2 className='fsize32 sm-fsize20 font-semibold lg:pb-4 md:pb-4 pb-3'>Lorem Ipsum</h2>
          <p className='pb-2'>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores
            fuga a, officia praesentium saepe sunt debitis blanditiis nobis
            enim. Nam. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores
            fuga
          </p>
          <Button
            className={
                colorMode === 'light'
                  ? 'theme-btn bg-primary btn-mdsize clr-fff bdr-2px mt-2 sm-fsize14'
                  : 'theme-btn cust-bgColor btn-mdsize bdr-2px mt-2 sm-fsize14'
              }
          >
            Resume
          </Button>
          </div>
        </div>
        <div className='lg:w-1/2 md:w-1/2 w-full'>
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
            className='lg:h-96 lg:w-96 md:h-80 md:w-80 h-56 w-56 rounded-full mx-auto'
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection31;
