import API from './repositoryFactory';

const getClinet = 'getForms';
const getAllClient = 'get/clients'

const client = route => {
  return `cmsforms/${route}`;
};

const clients = route => {
  return `forms/${route}`;
};

export default {
  getClinets(payload) {
    return API.post(client(getClinet), payload);
  },
  
  getAllClients(payload) {
    return API.post(clients(getAllClient), payload);
  },
};




