import { Button, Image } from '@chakra-ui/react';
import React from 'react';

const CardLayout2 = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="text-center lg:mb-14 md:mb-14 mb-8">
        <h3 className="fsize32 sm-fsize20 font-semibold pb-1 cust-textColor">Projects</h3>
        <hr className="hr-layout2" />
      </div>
      <div className="lg:flex md:flex sm-reverse cust-shadow rounded-3xl overflow-hidden lg:mb-14 md:mb-10 mb-8">
        <div className='flex items-center lg:w-1/2 md:w-1/2 w-full px-14 py-6 cust-bgColor theme-bg'>
         <div className=''>
         <h4 className="fsize28 sm-fsize18 font-semibold pb-4 cust-textColor">Projects</h4>
          <p className="sm-fsize14 pb-2 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
            accusamus tenetur animi voluptatem necessitatibus inventore.
          </p>
          
          <Button
            variant="outline"
            colorScheme="primary"
            className="theme-radiusfull mt-2 text-primary cust-textColor sm-fsize14 theme-btn bdr-2px"
          >
            View Project
          </Button>
         </div>
        </div>
        <div className='h-400px sm-h250px lg:w-1/2 md:w-1/2 w-full'>
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
            className='h-400px sm-h250px w-full'
          />
        </div>
      </div>
      <div className="lg:flex md:flex cust-shadow rounded-3xl overflow-hidden">
        
        <div className='h-400px sm-h250px lg:w-1/2 md:w-1/2 w-full'>
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
            className='h-400px sm-h250px w-full '
          />
        </div>
        <div className='flex items-center lg:w-1/2 md:w-1/2 w-full px-14 py-6 cust-bgColor theme-bg'>
         <div className=''>
         <h4 className="fsize28 sm-fsize18 font-semibold pb-4 cust-textColor">Projects</h4>
          <p className="sm-fsize14 pb-2 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
            accusamus tenetur animi voluptatem necessitatibus inventore.
          </p>
          
          <Button
            variant="outline"
            colorScheme="primary"
            className="theme-radiusfull mt-2 text-primary cust-textColor sm-fsize14 theme-btn bdr-2px"
          >
            View Project
          </Button>
         </div>
        </div>
      </div>
    </div>
  );
};

export default CardLayout2;
