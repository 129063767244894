import API from "../repositoryFactory";

const getjobposting = "get/job_postings"
const getjobapplication = "get/job_application"

const getjobpostings = (route) => {
    return `forms/${route}`;
}

const getjobapplications = (route)=>{
    return `forms/${route}`
}

export default {
    getAllJobPosting(payload){
        return API.post(getjobpostings(getjobposting), payload)
    },

    getAllJobApplications(payload){
        return API.post(getjobapplications(getjobapplication), payload)
    }
}